.admin-sat-test-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.hamburger {
    margin: 6px 0 0 0;
    padding: 0 10px;
    float: right;
}
.sel-topics-list{
    position: absolute;
    padding: 10px;
    background: #fff;
    z-index: 1;
    margin: 38px 7px;
    border: 2px solid #3aafa9;
}
