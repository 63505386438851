#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item .middle-indicator-text.moveNextCarousel:hover,
#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item .middle-indicator-text.movePrevCarousel:hover {
	color: #fff!important;
	background-color: #8e24aa
}

#intro .modal.open {
	height: 100%;
	max-height: 80%
}

#intro .modal.open .modal-content {
	overflow: hidden;
	height: 100%
}

#intro .modal .modal-close span i.material-icons {
	font-size: 16px;
	position: relative;
	top: 2px;
	margin-left: 5px
}

#intro .modal .modal-content .carousel.carousel-slider {
	height: 100%!important
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item .middle-indicator-text {
	position: absolute
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item .middle-indicator-text.moveNextCarousel {
	top: -5px;
	right: 0;
	border-radius: 30px
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item .middle-indicator-text.movePrevCarousel {
	top: -5px;
	left: 0;
	border-radius: 30px
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-fixed-item i.material-icons {
	line-height: 1;
	position: relative;
	top: 4px
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item .slide-1-img,
#intro .modal .modal-content .carousel.carousel-slider .carousel-item .slide3-img {
	width: 200px
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item .slide-2-img {
	width: 160px
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item .intro-step-title {
	color: #8e24aa
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item.slide-2 .select-wrapper input.select-dropdown {
	text-overflow: ellipsis
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item.slide-3 ul.feature-list li i.material-icons {
	position: relative;
	top: 7px;
	color: #8e24aa
}

#intro .modal .modal-content .carousel.carousel-slider .carousel-item.slide-3 .get-started {
	border-radius: 30px
}

#intro .modal .modal-content .carousel.carousel-slider ul.indicators {
	bottom: 20px
}

#intro .modal .modal-content .carousel.carousel-slider ul.indicators .indicator-item {
	background-color: #9e9e9e
}

#intro .modal .modal-content .carousel.carousel-slider ul.indicators .indicator-item.active {
	background-color: #8e24aa
}

#intro .modal .modal-content:after {
	/* background: url(../../images/gallery/wave.png); */
	background-size: cover
}

#intro .modal .bg-img-div {
	position: absolute;
	bottom: 0;
	overflow: hidden;
	width: 90%;
	height: 100px;
	opacity: 1;
	/* background: url(../../images/gallery/wave.png); */
	background-size: cover
}

@media (min-width:992px) {
	#intro .modal {
		width: 35%!important
	}
}

@media (min-width:1024px) and (max-width:1439px) {
	#intro .modal.open {
		width: 35%
	}
	#intro .modal.open .modal-content .carousel .slide-3 .get-started {
		margin-top: -5px!important
	}
}

@media (max-width:768px) {
	#intro .modal.open {
		height: 60%
	}
}

@media (max-width:540px) {
	#intro .modal {
		width: 80%;
		height: 100%!important;
		max-height: 70%
	}
	#intro .modal .modal-content .carousel.carousel-slider .carousel-item .intro-step-title {
		font-size: 1.25rem!important
	}
	#intro .modal .modal-content .carousel.carousel-slider .carousel-item.slide-3 ul.feature-list {
		margin: 0
	}
}

@media (width:375px) and (height:667px) {
	#intro .modal .modal-content .carousel.carousel-slider .carousel-item img.responsive-img {
		width: 100px
	}
}