.sortable-admin-topic-list {
    list-style: none;
    >li {
        display: flex;
        .admin-topic {
            margin-left: 7px;
            margin-bottom: 10px;
            >.sortable-admin-topic-list {
                >li {
                    display: flex;
                    .admin-subtopic {
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}