.question {
    font-weight: 600;
    > div {
        display: flex;
    }

    .toolbar {
        justify-content: flex-end;

        .help {
            width: 14px;
            height: 14px;
            font-size: 12px;
            border: 2px solid black;
            border-radius: 50%;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: #ff8884;
                border-color: #ff8884;
            }
        }
    }

    > img {
        width: 35px;
    }
}
