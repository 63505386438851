.add-content-button {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 1px;
    height: 100%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    cursor: pointer;
    margin: 0 5px;
    background-color: #fff;

    > .svg-container {
        display: flex;
        padding: 5px;

        &:hover {
            background-color: #e0dbdb;
        }
    }

    .popover-hide {
        display: flex;
        visibility: hidden;
        position: absolute;
        left: 5px;
        top: 40px;
        background-color: grey;
        padding: 5px;
        border-radius: 4px;
        opacity: 0;
        transition: visibility 0s, opacity 0.25s linear;
        z-index: 1000;

        .svg-container {
            margin-left: 5px;
            border-radius:4px;
            padding: 5px;
            display: flex;
            svg {
                width: 15px;
            }

            &:hover {
                background-color: #cfc4c4;
            }
        }

        &.show {
            visibility: visible;
            opacity: 1;
        }

        .arrow-up {
            position: absolute;
            top: -5px;
            left: 5px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid grey;
        }
    }
}
