@import '../scss/variables';

.section-questions{
    > ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
        > li {
            width: 30px;
            padding: 10px 0;
            margin: 10px 0;
            outline: 1px solid black;
            margin-left: 1px;

            > a {
                color: $black;
                text-decoration: none;
            }

            &.inactive {
                background-color: grey;
                &:hover {
                    background-color: grey;
                    cursor:default;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: $teal;
            }
        }
    }
}
