/* @import url(_select.scss); */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0
}

html {
	position: relative;
	min-height: 100%
}

body {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif
}

a,
button {
	outline: none;
	border: 0
}

a:active,
a:focus,
a:hover,
button:active,
button:focus,
button:hover {
	text-decoration: none;
	outline: none!important
}

img {
	max-width: 100%
}

li,
ul {
	list-style: none;
	margin: 0;
	padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #042D50;
	padding: 0;
	margin: 0
}

h1 {
	font-size: 36px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

h2 {
	font-size: 30px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

h3 {
	font-size: 24px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

h4 {
	font-size: 18px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

h5 {
	font-size: 14px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

h6 {
	font-size: 12px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1
}

hr {
	background: rgba(0, 0, 0, 0.27);
	border: medium none;
	display: block;
	height: 1px;
	margin: 0;
	padding: 0;
	width: 100%
}

.noPadd {
	padding: 0!important
}

.noMarg {
	margin: 0!important
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
	outline: 0;
	outline-offset: -2px
}

input:active,
input:focus {
	outline: 0
}

.modal-backdrop.in {
	opacity: 0.3
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 100%
}

bs-dropdown-container {
	z-index: 100
}

.or_text {
	position: relative;
	text-align: center;
	margin-bottom: 20px
}

.or_text span:before {
	content: "";
	position: absolute;
	height: 1px;
	background: #ccc;
	left: 0;
	top: 12px;
	width: 45%
}

.or_text span:after {
	content: "";
	position: absolute;
	height: 1px;
	background: #ccc;
	right: 0;
	top: 12px;
	width: 45%
}

.modal-terms {
	z-index: 99999
}

.btn-default {
	box-shadow: 0 7px 18px rgba(254, 117, 30, 0.25);
	font-size: 22px;
	font-size: 1.375rem;
	transition: all 0.25s ease;
	align-items: center;
	background-color: #FD6600;
	border-radius: 15px;
	border: none;
	color: #ffffff;
	cursor: pointer;
	display: inline-flex;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	justify-content: center;
	letter-spacing: 0.5px;
	line-height: 1.3;
	min-height: 60px;
	min-width: 190px;
	padding: 5px 30px;
	position: relative;
	text-transform: capitalize
}

.btn-default>* {
	position: relative;
	z-index: 2
}

.btn-default:after {
	background-color: #fff;
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transform: scale(0);
	transition: all 0.35s ease;
	width: 100%;
	z-index: 1
}

.btn-default i {
	font-size: 22px;
	margin: 0 0 0 10px;
	line-height: 22px;
	position: relative
}

.btn-default:focus {
	border-radius: 5px;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(253, 102, 0, 0.25)
}

.btn-default:focus,
.btn-default:hover {
	color: #ffffff;
	outline: none
}

.btn-default:focus:after,
.btn-default:hover:after {
	opacity: 0.25;
	transform: scale(1)
}

@media screen and (max-width:1440px) {
	.btn-default {
		font-size: 16px;
		font-size: 1rem;
		border-radius: 11px;
		min-height: 46px;
		min-width: 135px;
		padding: 5px 20px
	}
}

@media screen and (max-width:991px) {
	.btn-default {
		font-size: 16px;
		border-radius: 10px;
		min-height: 50px;
		min-width: 150px
	}
}

@media screen and (max-width:480px) {
	.btn-default {
		min-height: 46px
	}
}

.customSelect.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 100%
}

.customSelect.bootstrap-select .btn.dropdown-toggle {
	background: #ffffff;
	border: 1px solid #eee;
	border-radius: 5px;
	font-size: 20px;
	height: 40px;
	line-height: 1.3;
	padding: 5px 20px
}

.customSelect.bootstrap-select .btn.dropdown-toggle .filter-option {
	display: inline-flex;
	align-items: center
}

.customSelect.bootstrap-select .btn.dropdown-toggle:after {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/icon-select-arrow.png");
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100% auto;
	border: none;
	height: 36px;
	margin: 0;
	width: 36px
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled).active {
	color: #333333;
	background-color: #f5f5f5;
	border-color: #eee
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled).active:focus {
	box-shadow: none!important;
	outline: none!important
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled):active {
	color: #333333;
	background-color: #f5f5f5;
	border-color: #eee
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled):active:focus {
	box-shadow: none!important;
	outline: none!important
}

.customSelect.bootstrap-select .dropdown-toggle:focus {
	outline: none!important;
	outline: none!important;
	box-shadow: 0 0 2px rgba(38, 190, 85, 0.5)
}

.customSelect.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
	outline: none!important;
	outline: none!important;
	box-shadow: 0 0 2px rgba(38, 190, 85, 0.5)
}

.customSelect.bootstrap-select .dropdown-menu:not(.inner) {
	border-bottom: 1px solid #E7E7E7;
	border-radius: 10px;
	min-width: 150px;
	text-align: right
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item {
	border-bottom: 1px solid #E7E7E7;
	color: #9A9A9A;
	font-size: 14px;
	line-height: 1.3;
	padding: 7px 25px;
	position: relative
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item span.text {
	font-size: inherit;
	line-height: inherit;
	color: inherit
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item:hover {
	color: #333333
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item:active {
	color: #FD6600;
	background-color: rgba(253, 102, 0, 0.15)
}

.customSelect.bootstrap-select .dropdown-menu li:last-child .dropdown-item {
	border-bottom: none
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item.active {
	color: #FD6600;
	background-color: rgba(253, 102, 0, 0.15)
}

.customSelect.bootstrap-select.show>.btn.dropdown-toggle.btn-light.dropdown-toggle {
	color: #333333;
	background-color: #f5f5f5;
	border-color: #eee
}

.customSelect.bootstrap-select.show>.btn.dropdown-toggle.btn-light.dropdown-toggle:focus {
	box-shadow: none!important;
	outline: none!important
}

.datepicker.dropdown-menu table tr th {
	color: #042D50;
	font-weight: 600
}

.datepicker.dropdown-menu table tr td,
.datepicker.dropdown-menu table tr th {
	border-radius: 10px;
	height: 35px;
	width: 35px
}

.datepicker.dropdown-menu table tr td.active.active,
.datepicker.dropdown-menu table tr td.active.highlighted.active,
.datepicker.dropdown-menu table tr td.active.highlighted:active,
.datepicker.dropdown-menu table tr td.active:active {
	color: #fff;
	background-color: #FD6600;
	border-color: #FD6600
}

.custom-control {
	margin: 0;
	padding-left: 45px
}

.custom-control.custom-radiobox .custom-control-input-radio,
.custom-control.custom-radiobox .custom-control-label-radio:before {
	left: 0;
	height: 30px;
	position: absolute;
	top: 0;
	width: 30px
}

.custom-control.custom-radiobox .custom-control-label-radio {
	font-size: 20px;
	font-size: 1.25rem;
	letter-spacing: 0.2px;
	font-weight: normal;
	margin: 0!important;
	max-width: 100%;
	position: static;
	z-index: 1
}

.custom-control.custom-radiobox .custom-control-label-radio:before {
	background-color: #ffffff;
	border-radius: 100%;
	content: "";
	display: block;
	pointer-events: none;
	position: absolute
}

.custom-control.custom-radiobox .custom-control-label-radio:after {
	background: #ffffff;
	border: 1.5px solid #9AA7BC;
	border-radius: 100%;
	content: "";
	display: block;
	height: 20px;
	left: 5px;
	position: absolute;
	top: 5px;
	width: 20px
}

.custom-control.custom-radiobox .custom-control-input-radio {
	cursor: pointer;
	opacity: 0;
	z-index: 2
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio {
	color: #042D50
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio:before {
	background-color: rgba(253, 102, 0, 0.33);
	color: #FD6600
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio:after {
	background-color: #FD6600;
	border-color: #FD6600
}

.custom-control.custom-radiobox .custom-control-input-radio:not(:disabled):active~.custom-control-label-radio:before {
	color: #fff;
	background-color: #ffdfca;
	border-color: #ff6f0d
}

.custom-control.custom-radiobox .custom-control-input-radio:disabled {
	cursor: not-allowed
}

.custom-control.custom-radiobox .custom-control-input-radio:disabled~.custom-control-label-radio:before {
	background-color: #f9f9f9;
	opacity: 0.5
}

.app_form {
	display: block;
	padding: 0;
	position: relative
}

.app_form>.row:last-child {
	margin-bottom: -1rem
}

.app_form .form-group {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-bottom: 45px;
	padding: 0 15px
}

.app_form .form-group .input-label {
	color: #9AA7BC;
	line-height: 1.3;
	font-size: 18px;
	font-size: 1.125rem;
	font-weight: 400;
	margin: 0 0 10px;
	position: relative;
	text-transform: capitalize;
	width: 100%
}

.app_form .form-group .heading_lbl {
	color: #042D50;
	line-height: 1.3;
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: 500;
	margin: 0;
	position: relative;
	text-transform: uppercase;
	width: 100%
}

.app_form .form-group .heading_lbl span {
	color: #999999;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 500
}

.app_form .form-group p {
	font-size: 16px;
	font-size: 1rem;
	color: #999999;
	font-weight: 400;
	line-height: 1.3;
	margin: 0 0 15px;
	width: 100%
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]),
.app_form .form-group textarea {
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: none;
	border: 1px solid #cccccc;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 400;
	height: 60px;
	line-height: 1.3;
	padding: 10px 15px;
	position: relative;
	transform: all 0.25s ease;
	width: 100%
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file])::-webkit-input-placeholder,
.app_form .form-group textarea::-webkit-input-placeholder {
	font-weight: 400;
	opacity: 1;
	color: #999999
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file])::-moz-placeholder,
.app_form .form-group textarea::-moz-placeholder {
	font-weight: 400;
	opacity: 1;
	color: #999999
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):-ms-input-placeholder,
.app_form .form-group textarea:-ms-input-placeholder {
	font-weight: 400;
	opacity: 1;
	color: #999999
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):-moz-placeholder,
.app_form .form-group textarea:-moz-placeholder {
	font-weight: 400;
	opacity: 1;
	color: #999999
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):hover,
.app_form .form-group textarea:hover {
	border-color: #999999
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):focus,
.app_form .form-group textarea:focus {
	border-color: #042D50;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1)
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]).num_text,
.app_form .form-group textarea.num_text {
	font-weight: 400
}

.app_form .form-group textarea {
	min-height: 210px
}

.app_form .form-group .has-error {
	margin-top: 5px;
	position: relative;
	width: 100%
}

.app_form .form-group .input-group.date .input-group-addon {
	align-items: center;
	display: inline-flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 66px;
	z-index: 10
}

.app_form .form-group .input-group.date .input-group-addon .calIcon {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/ico-calender.png");
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% auto;
	display: inline-block;
	height: 30px;
	width: 28px
}

.app_form .form-group .customSelect.bootstrap-select .btn.dropdown-toggle {
	border-radius: 5px;
	box-shadow: none;
	border: 1px solid #cccccc;
	font-weight: 400;
	font-size: 16px;
	font-size: 1rem;
	height: 60px;
	line-height: 1.3;
	padding: 10px 15px;
	position: relative;
	transform: all 0.25s ease
}

.app_form .form-group .customSelect.bootstrap-select .btn.dropdown-toggle:hover {
	border-color: #999999
}

.app_form .form-group .customSelect.bootstrap-select.show .btn.dropdown-toggle {
	border-color: #042D50;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1)
}

.app_form .form-group .input-group {
	flex-wrap: nowrap;
	width: 100%
}

.app_form .form-group .input-group .input-group-text {
	font-size: 16px;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	color: #999999;
	padding: 0.9375rem 1.25rem
}

.app_form .form-group .radio_group {
	margin-top: 20px
}

.app_form .form-group .icon_before_input {
	display: inline-block;
	position: relative;
	width: 100%
}

.app_form .form-group .icon_before_input input:not([type=checkbox]):not([type=radio]):not([type=file]) {
	background-color: rgba(0, 0, 0, 0);
	position: relative;
	z-index: 2
}

.app_form .form-group .icon_before_input:before {
	background-color: rgba(0, 0, 0, 0);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100%;
	content: "";
	display: inline-block;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1
}

.app_form .form-group .icon_before_input.icon-loc:before {
	height: 30px;
	width: 30px
}

@media (-webkit-min-device-pixel-ratio:1.3),
(min-device-pixel-ratio:1.3),
(min-resolution:1.3dppx) {
	.app_form .form-group .icon_before_input.icon-loc:before {
		background-size: 100% auto
	}
}

@media screen and (max-width:991px) {
	.app_form .form-group {
		margin-bottom: 25px
	}
}

@media screen and (max-width:540px) {
	.app_form .form-group .input-label {
		font-size: 14px
	}
	.app_form .form-group .heading_lbl {
		font-size: 20px
	}
	.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]),
	.app_form .form-group textarea {
		font-size: 14px;
		height: 50px
	}
}

@keyframes burgerAnimationSlide {
	0% {
		transform: translateX(0);
		opacity: 1
	}
	25% {
		opacity: 0
	}
	49% {
		transform: translateX(-0.7em);
		opacity: 0
	}
	50% {
		transform: translateX(0.7em);
		opacity: 0
	}
	51% {
		opacity: 0
	}
	75% {
		opacity: 1
	}
	to {
		transform: translateX(0px);
		opacity: 1
	}
}

.burger {
	cursor: pointer;
	font-size: 12px!important;
	height: 30px;
	padding: 0!important;
	position: relative;
	transition: 0.2s all;
	width: 30px
}

.burger:after {
	content: "";
	display: block;
	position: absolute;
	height: 150%;
	width: 150%;
	top: -25%;
	left: -25%
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
	pointer-events: none;
	display: block;
	content: "";
	width: 100%;
	border-radius: 25em;
	background-color: #FD6600;
	height: 0.25em;
	height: 3px;
	position: absolute;
	transform: rotate(0)
}

.burger .burger-lines {
	top: 50%;
	margin-top: -0.125em;
	margin-top: -1.5px
}

.burger .burger-lines .burger-lines:after {
	left: 0;
	top: -0.8em
}

.burger .burger-lines .burger-lines:before {
	right: 0;
	top: 0.8em
}

.burger.burger-slide .burger-lines {
	transition: 0.1s all 0.15s
}

.burger.burger-slide .burger-lines:after,
.burger.burger-slide .burger-lines:before {
	width: 20px;
	transition: 0.1s all 0.15s
}

.burger.burger-slide .burger-lines:after {
	left: 0;
	top: -0.5rem
}

.burger.burger-slide .burger-lines:before {
	right: 0;
	top: 0.5rem
}

.burger.burger-slide:not([aria-expanded=true]) {
	animation-name: burgerAnimationSlide;
	animation-duration: 0.4s;
	background-color: transparent
}

.burger.burger-slide[aria-expanded=true] .burger-lines {
	animation-name: burgerAnimationSlide;
	animation-duration: 0.4s;
	background-color: transparent
}

.burger.burger-slide[aria-expanded=true] .burger-lines:after,
.burger.burger-slide[aria-expanded=true] .burger-lines:before {
	left: 5px;
	top: 0;
	right: auto
}

.burger.burger-slide[aria-expanded=true] .burger-lines:before {
	transform: rotate(-45deg)
}

.burger.burger-slide[aria-expanded=true] .burger-lines:after {
	transform: rotate(45deg)
}

.main-header {
	position: relative;
	padding: 0;
	z-index: 10
}

.main-header .navbarWrap {
	padding: 60px 75px 0;
	position: absolute
}

.main-header .navbar {
	background-color: rgba(255, 255, 255, 0.5);
	border: 1px solid #D9D9D9;
	border-radius: 10px;
	box-shadow: 0 15px 30px rgba(133, 133, 133, 0.15);
	padding: 20px
}

.main-header .navbar .navbar-brand {
	padding: 0 10px
}

.main-header .navbar .navbar-nav .nav-item {
	position: relative
}

.main-header .navbar .navbar-nav .nav-item .nav-link {
	color: #042D50;
	font-size: 18px;
	font-weight: 500;
	padding: 0 20px
}

.main-header .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #b14700
}

.main-header .navbar .navbar-nav .nav-item:before {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/nav_active-img.png");
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100% auto;
	bottom: 100%;
	content: "";
	display: inline-block;
	height: 7px;
	left: 50%;
	margin-bottom: 25px;
	margin-left: -24px;
	position: absolute;
	transform: scale(0);
	transition: all 0.25s ease;
	width: 48px
}

.main-header .navbar .navbar-nav .nav-item.active .nav-link {
	color: #FD6600;
	text-shadow: 0 2px 6px rgba(253, 102, 0, 0.45)
}

.main-header .navbar .navbar-nav .nav-item.active .nav-link:hover {
	color: #FD6600
}

.main-header .navbar .navbar-nav .nav-item.active:before {
	transform: scale(1)
}

.main-header .navbar .navbar-nav .dropdown-menu {
	border-color: #ececec;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
	border-radius: 15px;
	left: auto;
	margin-top: 30px;
	right: 0
}

.main-header .navbar .navbar-nav .dropdown-menu .dropdown-item {
	color: #999999;
	padding: 10px 40px
}

.main-header .navbar .navbar-nav .dropdown-menu .dropdown-item.active,
.main-header .navbar .navbar-nav .dropdown-menu .dropdown-item:active {
	color: #fff;
	background-color: #FD6600
}

.main-header .navbar.fixed-top {
	background-color: #ffffff;
	border-width: 0 0 1px;
	border-color: #f1f1f1;
	border-radius: 0;
	box-shadow: 0 15px 30px rgba(133, 133, 133, 0.055)
}

.main-header .navbarWrap {
	padding: 0
}

.main-header .navbar {
	box-shadow: 0 15px 30px rgba(107, 107, 107, 0.1);
	border-color: #ebebeb;
	border-radius: 0;
	border-style: solid;
	border-width: 0 0 1px
}

@media screen and (max-width:1440px) {
	.main-header .navbar {
		padding: 15px 15px
	}
	.main-header .navbar .navbar-nav .nav-item:before {
		margin-bottom: 18px
	}
	.main-header .navbar .navbar-nav .nav-item .nav-link {
		font-size: 16px
	}
	.main-header .navbar .navbar-nav .dropdown-menu {
		margin-top: 25px
	}
	.main-header .navbar .navbar-brand {
		width: 230px
	}
}

@media screen and (max-width:1199px) {
	.main-header .navbar .navbar-brand {
		width: 185px
	}
	.main-header .navbar .navbar-nav .nav-item .nav-link {
		font-size: 15px
	}
}

@media screen and (max-width:991px) {
	.main-header .navbarWrap {
		padding: 35px 70px 0
	}
	.main-header .navbar {
		background-color: white;
		padding: 15px 20px
	}
	.main-header .navbar .navbar-nav {
		margin-top: 20px
	}
	.main-header .navbar .navbar-nav .nav-item .nav-link {
		padding: 10px 20px
	}
	.main-header .navbar .navbar-nav .nav-item:before {
		content: none
	}
	.main-header .navbar .navbar-nav .dropdown-menu {
		margin-top: 0;
		border: none;
		box-shadow: none
	}
}

@media screen and (max-width:540px) {
	.main-header .navbarWrap {
		padding: 0
	}
	.main-header .navbar {
		background-color: #ffffff;
		border-width: 0 0 1px;
		border-color: #f1f1f1;
		border-radius: 0;
		box-shadow: 0 15px 30px rgba(133, 133, 133, 0.055)
	}
}

footer {
	background-color: #042D50;
	padding-top: 90px;
	color: #fff
}

footer .container {
	padding: 0
}

footer .companyInfo .footerlogo {
	margin-bottom: 15px
}

footer .companyInfo p {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.4;
	opacity: 0.75
}

footer .footer_colTitle {
	color: #ffffff;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.3;
	margin: 0 0 10px;
	text-transform: uppercase
}

footer .footerMenu {
	padding-left: 110px
}

footer .footerMenu .ftr_Menucell {
	margin-bottom: 30px;
	padding: 0 15px
}

footer .footerMenu ul li a {
	color: #ffffff;
	display: inline-block;
	font-size: 18px;
	font-weight: 300;
	opacity: 0.5;
	padding: 6px 0;
	position: relative;
	transition: all 0.25s ease;
	width: 100%
}

footer .footerMenu ul li a:focus,
footer .footerMenu ul li a:hover {
	opacity: 1
}

footer .footerMenu ul.contact li a {
	margin-left: -30px;
	padding-left: 30px;
	position: relative
}

footer .footerMenu ul.contact li a:before {
	transform: translateY(-50%);
	background-color: rgba(0, 0, 0, 0);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100%;
	content: "";
	display: inline-block;
	height: 16px;
	left: 0;
	position: absolute;
	top: 50%;
	width: 16px
}

footer .footerMenu ul.contact li a.mail:before {
	background-image: url("../../images/ico-footer-mail.png");
	height: 13px
}

@media (-webkit-min-device-pixel-ratio:1.3),
(min-device-pixel-ratio:1.3),
(min-resolution:1.3dppx) {
	footer .footerMenu ul.contact li a.mail:before {
		background-image: url("../../images/ico-footer-mail@2x.png");
		background-size: 16px 13px
	}
}

footer .footerMenu ul.contact li a.phone:before {
	background-image: url("../../images/ico-footer-phone.png")
}

@media (-webkit-min-device-pixel-ratio:1.3),
(min-device-pixel-ratio:1.3),
(min-resolution:1.3dppx) {
	footer .footerMenu ul.contact li a.phone:before {
		background-image: url("../../images/ico-footer-phone@2x.png");
		background-size: 16px 16px
	}
}

footer .footer_btm {
	border-top: 1px solid #D5D5D5;
	margin-top: 30px;
	padding: 10px 0
}

footer .footer_btm .copytxt {
	font-size: 14px;
	font-weight: 300
}

footer .footer_btm ul {
	margin-right: -30px
}

footer .footer_btm ul li {
	padding: 0 30px;
	position: relative
}

footer .footer_btm ul li:before {
	transform: translateY(-50%);
	background-color: #ffffff;
	content: "";
	display: inline-block;
	height: 15px;
	opacity: 0.7;
	right: -0.6px;
	position: absolute;
	top: 50%;
	width: 1.2px
}

footer .footer_btm ul li:last-child:before {
	content: none
}

footer .footer_btm ul li a {
	color: #ffffff;
	display: inline-block;
	font-size: 16px;
	font-weight: 300;
	opacity: 0.7;
	padding: 6px 0;
	position: relative;
	transition: all 0.25s ease
}

footer .footer_btm ul li a:focus,
footer .footer_btm ul li a:hover {
	opacity: 1
}

@media screen and (max-width:1199px) {
	footer .footer_btm {
		overflow: hidden
	}
}

@media screen and (max-width:767px) {
	footer {
		text-align: center;
		padding-top: 50px
	}
	footer .companyInfo {
		margin-bottom: 20px
	}
	footer .footerMenu {
		padding: 0 15px
	}
	footer .footerMenu .row {
		flex-direction: column;
		align-items: center
	}
	footer .footer_btm {
		flex-direction: column
	}
	footer .footer_btm .copytxt {
		order: 2
	}
	footer .footer_btm ul {
		margin-right: 0
	}
}

@media screen and (max-width:480px) {
	footer .footer_btm ul {
		flex-direction: column
	}
	footer .footer_btm ul li:before {
		content: none
	}
}

.section_title {
	display: inline-block;
	font-size: 50px;
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 60px;
	text-align: center;
	text-transform: uppercase;
	width: 100%
}

.section_title b {
	color: #FD6600;
	font-weight: inherit
}

.banner_sec {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/bannerBG.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat
}

.banner_sec .banner_Content {
	padding-bottom: 110px;
	padding-top: 255px
}

.bannerImg {
	margin-right: -125px
}

.bannerCaption {
	align-items: center;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-right: -75px;
	width: 742px;
	z-index: 2
}

.bannerCaption h2 {
	color: #042D50;
	font-size: 76px;
	font-weight: 700;
	margin-bottom: 20px
}

.bannerCaption p {
	color: #999999;
	font-size: 22px;
	font-weight: 300;
	max-width: 540px
}

.bannerCaption p b {
	color: #FD6600;
	font-weight: 500;
	text-transform: uppercase
}

.bannerCaption .bannerForm {
	padding-top: 45px;
	padding-bottom: 47px
}

.bannerCaption .bannerForm:after {
	content: "";
	background-image: url("../../images/box-shadow.png");
	display: block;
	width: 100%;
	height: 90%;
	position: absolute;
	bottom: 0;
	z-index: 1;
	left: 50%;
	transform: translateX(-50%);
	overflow: visible
}

.bannerCaption .bannerForm .input-group {
	position: relative;
	z-index: 2
}

.bannerCaption .bannerForm .input-group input {
	border-radius: 22px;
	border: 1px solid #F2F2F2;
	font-size: 18px;
	height: 90px;
	line-height: 1.3;
	margin: 0;
	padding: 15px 45px;
	transition: all 0.25s ease;
	width: 100%
}

.bannerCaption .bannerForm .input-group input::-webkit-input-placeholder {
	font-size: 24px;
	opacity: 1;
	color: #9C9B9F
}

.bannerCaption .bannerForm .input-group input:-moz-placeholder {
	font-size: 24px;
	opacity: 1;
	color: #9C9B9F
}

.bannerCaption .bannerForm .input-group input::-moz-placeholder {
	font-size: 24px;
	opacity: 1;
	color: #9C9B9F
}

.bannerCaption .bannerForm .input-group input:-ms-input-placeholder {
	font-size: 24px;
	opacity: 1;
	color: #9C9B9F
}

.bannerCaption .bannerForm .input-group input:hover {
	border-color: #cccccc
}

.bannerCaption .bannerForm .input-group input:focus {
	border-color: #042D50
}

.bannerCaption .bannerForm .input-group .btn.btn-default {
	align-items: center;
	background-color: #FD6600;
	border-radius: 15px;
	color: #ffffff;
	display: inline-flex;
	font-size: 22px;
	font-weight: 600;
	height: 60px;
	justify-content: center;
	padding: 5px 20px;
	position: absolute;
	right: 15px;
	top: 15px;
	transition: all 0.25s ease
}

.bannerCaption .bannerForm .input-group .btn.btn-default .ico {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/send-icon.png");
	background-position: center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	height: 24.67px;
	width: 22.91px;
	margin-left: 10px
}

.softHum_sec {
	padding-top: 110px;
	padding-bottom: 110px
}

.softHum_sec h3 {
	color: #042D50;
	font-size: 50px;
	font-weight: 600;
	margin-bottom: 20px;
	line-height: 1.4
}

.softHum_sec h3 b {
	color: #FD6600;
	font-weight: 600
}

.softHum_sec p {
	color: #999999;
	font-size: 22px;
	font-weight: 300;
	line-height: 1.7;
	margin-bottom: 85px
}

.softHum_sec p b {
	color: #042D50;
	font-weight: 500
}

.services_sec .container {
	border-top: 1px solid #D5D5D5;
	padding-bottom: 120px;
	padding-top: 120px
}

.services_sec .cell .cell_Card {
	border-radius: 30px;
	padding: 50px 15px 30px;
	transition: all 0.25s ease
}

.services_sec .cell .cell_Card:hover {
	background-color: #FD6600;
	box-shadow: 0 5px 20px rgba(254, 117, 30, 0.5)
}

.services_sec .cell .cell_Card:hover h4,
.services_sec .cell .cell_Card:hover p {
	color: #ffffff
}

.services_sec .cell h4 {
	color: #042D50;
	font-size: 24px;
	line-height: 1.3;
	margin-bottom: 5px;
	margin-top: 45px;
	padding: 0 5%;
	position: relative
}

.services_sec .cell p {
	color: #999999;
	font-size: 18px;
	list-style: 1.3;
	font-weight: 300
}

.sofrUniq_sec {
	background-color: #F8FAFC;
	padding-top: 110px;
	padding-bottom: 150px
}

.sofrUniq_sec .cell h2 {
	font-size: 74px;
	font-weight: 600;
	line-height: 1.3;
	margin: 0
}

.sofrUniq_sec .cell h2 b {
	color: #FD6600;
	font-weight: 600;
	text-transform: uppercase
}

.sofrUniq_sec .cell h3 {
	font-size: 34px;
	font-weight: 500;
	line-height: 1.3;
	margin-bottom: 5px;
	margin-top: 40px;
	text-transform: uppercase
}

.sofrUniq_sec .cell p {
	color: #999999;
	font-size: 20px;
	line-height: 1.8;
	font-weight: 300;
	margin: 0
}

.sofrUniq_sec .cell .btn-default {
	margin-top: 75px
}

.testimo_sec {
	padding: 80px 50px
}

.testimo_sec .container {
	max-width: 100%
}

.testimo_sec .carousel_wrap .owl-carousel {
	padding: 0 100px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-nav {
	margin-top: 0
}

.testimo_sec .carousel_wrap .owl-carousel .owl-nav button {
	align-items: center;
	background: #ffffff!important;
	display: flex;
	height: 70px;
	justify-content: center;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 70px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-nav button.owl-prev {
	left: 0
}

.testimo_sec .carousel_wrap .owl-carousel .owl-nav button.owl-next {
	right: 0
}

.testimo_sec .carousel_wrap .owl-carousel .owl-stage-outer {
	min-height: 620px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-dots {
	margin-top: 25px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-dots .owl-dot {
	align-items: center;
	background: #ffffff;
	border: 2px solid #D9D9D9;
	border-radius: 100px;
	display: inline-flex;
	height: 20px;
	justify-content: center;
	margin: 0 8px;
	width: 20px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-dots .owl-dot span {
	margin: 0;
	background: #FD6600;
	display: inline-block;
	opacity: 0
}

.testimo_sec .carousel_wrap .owl-carousel .owl-dots .owl-dot.active {
	border-color: #FD6600
}

.testimo_sec .carousel_wrap .owl-carousel .owl-dots .owl-dot.active span {
	opacity: 1
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item {
	margin-top: 40px;
	padding-bottom: 25px;
	transition: all 0.5s ease
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item {
	border-radius: 35px;
	border: 1px solid #E1E1E1;
	opacity: 0.3;
	padding: 60px 25px 40px;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05)
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner {
	align-items: center;
	display: inline-flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .quote_ico {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/ico-material-format-quote-grey.png");
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100%;
	display: inline-block;
	height: 51px;
	margin-bottom: 45px;
	overflow: hidden;
	text-indent: 1000px;
	width: 71px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner p {
	display: -webkit-box;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-align: center;
	font-size: 22px;
	margin-bottom: 45px;
	color: #333333;
	font-weight: 300;
	font-style: italic
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .client_img {
	border-radius: 100px;
	display: inline-block;
	height: 82px;
	position: relative;
	overflow: hidden;
	width: 82px
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .cell h6 {
	color: #042D50;
	font-size: 25px;
	font-weight: 500
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .cell strong {
	color: #999999;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.3
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active:not(.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active+.active) {
	margin-top: 0
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active:not(.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active+.active) .item {
	opacity: 1
}

.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active:not(.testimo_sec .carousel_wrap .owl-carousel .owl-item.active+.active+.active) .item .box_inner .quote_ico {
	background-image: url("../../images/ico-material-format-quote.png")
}

.pageheader {
	padding-top: 245px;
	text-align: center
}

.pageheader .container>.row {
	justify-content: center
}

.pageheader .pagetitle {
	display: inline-block;
	font-size: 55px;
	font-weight: 700;
	margin: 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%
}

.pageheader .subtitle {
	color: #999999;
	font-size: 24px;
	font-weight: 300;
	justify-content: center;
	display: flex;
	line-height: 1.6;
	width: 75%
}

.tutoringTypes_sec {
	padding-bottom: 115px;
	padding-top: 115px
}

.tutoringTypes_sec h2 {
	color: #FD6600;
	font-size: 34px;
	font-weight: 600;
	margin-bottom: 40px
}

.tutoringTypes_sec h2 b {
	color: #042D50;
	font-weight: inherit
}

.tutoringTypes_sec .infographic_contnt {
	align-items: center;
	flex-wrap: nowrap
}

.tutoringTypes_sec .infographic_contnt .cell {
	padding: 0 15px
}

.tutoringTypes_sec .infographic_contnt .saperator {
	background-color: #023B4E;
	display: inline-block;
	height: 125px;
	margin: 0 55px;
	opacity: 0.1;
	width: 2px
}

.tutoringTypes_sec p {
	color: #999;
	font-size: 20px;
	font-weight: 300;
	line-height: 2;
	margin: 45px 0 0 0;
	position: relative;
	text-align: center
}

.tutoringTypes_sec .btn-default {
	margin: 45px 0 0
}

.whosfor_sec {
	background-color: #F8FAFC;
	padding-top: 130px;
	padding-bottom: 130px
}

.whosfor_sec .section_title {
	margin-bottom: 10px;
	text-align: left
}

.whosfor_sec p {
	color: #999999;
	font-size: 23px;
	position: relative;
	line-height: 1.6;
	font-weight: 300
}

.whosfor_sec ul {
	padding-left: 20%
}

.whosfor_sec ul li {
	align-items: center;
	display: inline-flex;
	font-size: 18px;
	font-weight: 400;
	padding: 20px 0;
	position: relative
}

.whosfor_sec ul li .count {
	align-items: center;
	background-image: linear-gradient(315deg, #FE751E, #FFC49E);
	border-radius: 25px 100px 100px;
	color: #fff;
	display: inline-flex;
	font-size: 36px;
	font-weight: bold;
	height: 70px;
	flex: 0 0 70px;
	justify-content: center;
	line-height: 20px;
	margin-right: 30px;
	width: 70px
}

.howdiffer_sec {
	padding-top: 110px;
	padding-bottom: 110px
}

.howdiffer_sec ul li {
	align-items: flex-start;
	color: #9a9a9a;
	display: flex;
	font-size: 20px;
	font-weight: 300;
	line-height: 1.7;
	margin-bottom: 25px;
	position: relative;
	text-align: left
}

.howdiffer_sec ul li:before {
	content: "";
	display: inline-block;
	background-image: url(../../images/check.png);
	width: 43px;
	height: 43px;
	flex: 0 0 43px;
	margin-right: 30px
}

.howdiffer_sec .btn-default {
	margin-top: 50px
}

.upshot_sec .container {
	border-top: 1px solid #D5D5D5;
	padding-bottom: 120px;
	padding-top: 120px
}

.upshot_sec .cell {
	padding-top: 85px
}

.upshot_sec .cell .cell_Card {
	background-color: #F8FAFC;
	border-radius: 30px;
	padding: 0 15px 30px;
	transition: all 0.25s ease
}

.upshot_sec .cell .cell_Card .imgwrap {
	background-color: #ffffff;
	border-radius: 100px;
	box-shadow: 0 -4px 24px rgba(119, 119, 119, 0.07);
	margin-bottom: 55px;
	margin-top: -85px;
	width: 170px;
	height: 170px;
	flex: 0 0 170px
}

.upshot_sec .cell .cell_Card h4 {
	color: #333333;
	font-size: 25px;
	font-weight: 500;
	margin-bottom: 10px;
	width: 100%
}

.upshot_sec .cell .cell_Card p {
	color: #333333;
	font-weight: 300;
	font-size: 20px;
	line-height: 1.3;
	margin: 0
}

.calltoaction_sec {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/calltoactionbg.png");
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 110px;
	padding-top: 110px
}

.calltoaction_sec .row {
	align-items: center;
	flex-wrap: nowrap;
	justify-content: center
}

.calltoaction_sec h3 {
	font-size: 42px;
	font-weight: 600;
	display: inline-block;
	padding-right: 100px
}

.storyTree_sec {
	padding-top: 95px
}

.storyTree_sec .container>.row {
	flex-wrap: nowrap;
	align-items: center;
	position: relative
}

.storyTree_sec .container>.row:after,
.storyTree_sec .container>.row:before {
	background-color: transparent;
	background-position: 0 0;
	content: "";
	display: block;
	position: absolute;
	transform: translateX(-50%);
	bottom: 0
}

.storyTree_sec .container>.row:after {
	background-image: url("../../images/line_horizontal.png");
	background-repeat: repeat-x;
	background-size: auto 100%;
	height: 8px;
	left: 50%;
	width: 100%;
	width: 593px
}

.storyTree_sec .container>.row:before {
	background-image: url("../../images/line_vertical.png");
	background-repeat: repeat-y;
	background-size: 100% auto;
	height: 100%;
	height: 195px;
	left: 25%;
	width: 8px
}

.storyTree_sec .container>.row:first-child {
	padding-bottom: 75px
}

.storyTree_sec .container>.row:first-child .textbox:before {
	content: none
}

.storyTree_sec .container>.row:nth-child(2) {
	padding-bottom: 75px;
	padding-top: 75px
}

.storyTree_sec .container>.row:nth-child(2):before {
	transform: translateX(50%);
	bottom: auto;
	left: auto;
	right: 25%;
	top: -8px
}

.storyTree_sec .container>.row:nth-child(2):after {
	background-image: url("../../images/line_vertical.png");
	background-repeat: repeat-y;
	background-size: 100% auto;
	bottom: -9px;
	height: 195px;
	left: auto;
	width: 8px;
	right: 25%;
	z-index: -1;
	transform: translateX(50%)
}

.storyTree_sec .container>.row:last-child {
	padding-top: 115px
}

.storyTree_sec .container>.row:last-child .textbox:after {
	content: none
}

.storyTree_sec .container>.row:last-child:before {
	bottom: auto;
	left: 50%;
	top: 0
}

.storyTree_sec .container>.row:last-child:after {
	bottom: auto;
	top: 0;
	transform: none;
	width: 290px
}

.storyTree_sec .textbox {
	background: #042D50;
	color: #fff;
	border-radius: 20px;
	border: 5px solid #FE751E;
	padding: 40px 30px
}

.storyTree_sec .textbox:after,
.storyTree_sec .textbox:before {
	background: #FD6600;
	border-radius: 100px;
	border: 8px solid #ffffff;
	content: "";
	display: block;
	height: 52px;
	width: 52px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -26px
}

.storyTree_sec .textbox:before {
	bottom: auto;
	top: -26px
}

.storyTree_sec .textbox p {
	font-size: 20px;
	margin: 0;
	display: inline-block;
	position: relative;
	line-height: 2.2
}

.storyTree_sec .textbox p b {
	color: #FD6600;
	font-size: 30px;
	font-weight: 600
}

.enterduo_sec {
	padding-bottom: 100px;
	padding-top: 130px;
	text-align: center
}

.enterduo_sec p {
	color: #999999;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.8;
	margin-bottom: 30px
}

.enterduo_sec p:last-child {
	margin-bottom: 0
}

.contactInfo_sec {
	padding-bottom: 150px;
	padding-top: 65px
}

.contactInfo_sec .infoCard {
	border-radius: 15px;
	padding: 50px 15px;
	-webkit-user-select: none;
	user-select: none
}

.contactInfo_sec .infoCard.green {
	background-color: #CEE7DD
}

.contactInfo_sec .infoCard.orange {
	background-color: #FEE4BD
}

.contactInfo_sec .infoCard.blue {
	background-color: #BDDADF
}

.contactInfo_sec .infoCard .contact_icon {
	margin-bottom: 45px
}

.contactInfo_sec .infoCard h3 {
	color: rgba(0, 0, 0, 0.45);
	font-size: 20px;
	font-weight: 500;
	line-height: 1.3;
	margin: 0 0 7px;
	position: relative;
	width: 100%
}

.contactInfo_sec .infoCard a,
.contactInfo_sec .infoCard p {
	color: #042D50;
	display: inline-block;
	font-size: 22px;
	font-weight: 600;
	margin: 0;
	transition: all 0.25s ease
}

.contactInfo_sec .infoCard a:active,
.contactInfo_sec .infoCard a:focus,
.contactInfo_sec .infoCard a:hover {
	color: #FD6600
}

.contactForm_sec {
	padding-bottom: 125px
}

.contactForm_sec h3 {
	font-size: 42px;
	font-weight: 400;
	line-height: 1.3;
	position: relative;
	margin-bottom: 40px
}

.contactForm_sec .contact_form {
	border-radius: 10px;
	border: 1px solid #EBEBEB;
	box-shadow: 0 15px 45px rgba(155, 155, 155, 0.11);
	padding: 75px
}

.contactForm_sec .btn-default .ico {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/send-icon.png");
	background-position: center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	height: 24.67px;
	width: 22.91px;
	margin-left: 10px
}

.story_sec {
	padding-bottom: 100px;
	padding-top: 100px
}

.story_sec .img_wrp {
	display: inline-block;
	flex: 0 0 550px;
	margin: 0 0 30px 30px;
	width: 550px
}

.story_sec p {
	color: #333333;
	font-size: 22px;
	font-weight: 300;
	line-height: 2.5;
	margin-bottom: 60px
}

.story_sec p b {
	color: #FD6600;
	font-size: 220%;
	font-weight: 700;
	line-height: 1
}

.story_sec p:last-child {
	margin-bottom: 0
}

.story_sec .imgtxt_section p {
	margin-bottom: 0
}

.philosophy_sec {
	padding-top: 90px;
	padding-bottom: 90px
}

.philosophy_sec.philo_bgSec {
	background-color: #F8FAFC;
	background-image: url("../../images/bg-bottom.jpg");
	background-position: bottom center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	padding-top: 150px;
	padding-bottom: 150px;
	position: relative
}

.philosophy_sec.philo_bgSec:before {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/bg-top.jpg");
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1
}

.philosophy_sec.philo_bgSec>* {
	position: relative;
	z-index: 2
}

.philosophy_sec.philo_bgSec h3 {
	margin-bottom: 70px
}

.philosophy_sec.philo_bgSec p {
	opacity: 0.6
}

.philosophy_sec h3 {
	color: #042D50;
	font-size: 34px;
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 40px;
	text-align: center;
	max-width: 75%
}

.philosophy_sec h3 span {
	font-size: 30px;
	font-weight: 500
}

.philosophy_sec h3 b {
	color: #FD6600;
	font-weight: inherit
}

.philosophy_sec p {
	color: #333333;
	font-size: 22px;
	font-weight: 400;
	line-height: 2.5;
	margin-bottom: 50px;
	max-width: 95%
}

.philosophy_sec p:last-child {
	margin-bottom: 0
}

.philosophy_sec h4 {
	background-color: #FFF1E0;
	margin-top: 50px;
	padding: 25px 45px;
	border-radius: 0 45px 0 45px;
	font-size: 28px;
	font-weight: 600
}

.tutors_sec {
	padding-bottom: 135px;
	padding-top: 100px
}

.tutors_sec p {
	color: #999999;
	font-size: 22px;
	font-weight: 400;
	line-height: 1.8;
	margin-bottom: 50px;
	max-width: 95%
}

.tutors_sec p:last-child {
	margin-bottom: 0
}

.tutors_sec h3 {
	color: #042D50;
	font-size: 34px;
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 70px;
	text-align: center;
	max-width: 90%
}

.tutors_sec h3 span {
	font-size: 30px;
	font-weight: 500
}

.tutors_sec h3 b {
	color: #FD6600;
	font-weight: inherit
}

.tutors_sec .infoTree_sec .cell:not(:first-child) {
	margin-top: -50px
}

.tutors_sec .infoTree_sec .cell:not(.cell_empty):after,
.tutors_sec .infoTree_sec .cell:not(.cell_empty):before {
	content: "";
	z-index: 2
}

.tutors_sec .infoTree_sec .cell:after,
.tutors_sec .infoTree_sec .cell:before {
	display: inline-block;
	position: absolute;
	top: 0
}

.tutors_sec .infoTree_sec .cell:after {
	background-color: #ffffff;
	border-radius: 100px;
	border: 8px solid #FD6600;
	height: 50px;
	left: 100%;
	margin-left: -25px;
	width: 50px
}

.tutors_sec .infoTree_sec .cell:before {
	background-color: #E6E5EA;
	border-radius: 10px;
	height: 100%;
	left: 100%;
	margin-left: -4px;
	width: 8px
}

.tutors_sec .infoTree_sec .cell:nth-child(odd) {
	padding-right: 55px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n) {
	padding-left: 55px;
	padding-right: 15px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n):after {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: -25px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n):before {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: -4px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock {
	padding-left: 0;
	padding-right: 36px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header h3 {
	border-radius: 70px 0 0 70px;
	justify-content: flex-end;
	padding: 5px 60px 5px 30px
}

.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header h3:before {
	border-right-color: rgba(0, 0, 0, 0);
	border-left-color: #001426;
	border-top-color: #001426;
	left: auto;
	right: 0
}

.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header .icon_box {
	right: auto;
	left: 0
}

.tutors_sec .infoTree_sec .cell:last-child:before {
	content: none
}

.tutors_sec .infoTree_sec .treeBlock {
	padding-left: 36px;
	z-index: 1
}

.tutors_sec .infoTree_sec .treeBlock .cat_header {
	display: inline-flex;
	position: absolute;
	width: 100%;
	z-index: 2;
	left: 0;
	top: 0
}

.tutors_sec .infoTree_sec .treeBlock .cat_header h3 {
	align-items: center;
	background: #042D50;
	border-radius: 0 70px 70px 0;
	color: #FFDD42;
	display: flex;
	font-size: 30px;
	font-weight: 700;
	height: 70px;
	margin: 0;
	max-width: initial;
	padding: 5px 30px 5px 60px;
	position: relative;
	width: 100%
}

.tutors_sec .infoTree_sec .treeBlock .cat_header h3:before {
	content: "";
	position: absolute;
	top: 100%;
	left: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 18px;
	border-right-color: #001426;
	border-top-color: #001426
}

.tutors_sec .infoTree_sec .treeBlock .cat_header .icon_box {
	align-items: center;
	background-color: #042D50;
	border-radius: 30px;
	display: flex;
	height: 127px;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 127px;
	z-index: 3
}

.tutors_sec .infoTree_sec .treeBlock .text_block {
	display: inline-block;
	border: 1px solid #D8D7D7;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
	border-radius: 0 0 30px 30px;
	margin-top: 70px;
	padding: 70px 30px 25px 30px;
	text-align: left;
	position: relative;
	z-index: 1
}

.tutors_sec .infoTree_sec .treeBlock .text_block p {
	color: #333333;
	font-size: 18px;
	margin-bottom: 0
}

.tutors_sec.softwares_sec .infoTree_sec .cell:after {
	background-color: #FD6600;
	border: 8px solid #FFDCC6;
	height: 30px;
	margin-left: -15px;
	top: 75px;
	width: 30px
}

.tutors_sec.softwares_sec .infoTree_sec .cell:before {
	margin-left: -2px;
	width: 4px;
	top: 75px
}

.tutors_sec.softwares_sec .infoTree_sec .cell:not(:first-child) {
	margin-top: 0
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n):before {
	margin-right: -2px;
	top: 75px
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n):after {
	margin-right: -15px
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock {
	padding-left: 0;
	padding-right: 0
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .text_block:before {
	left: auto;
	right: 100%
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header h3 {
	border-radius: 70px 0 0 70px;
	justify-content: flex-end;
	padding: 5px 40px
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header .icon_box {
	order: 2
}

.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .text_block p {
	text-align: left
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock {
	padding-left: 0
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .text_block {
	box-shadow: none;
	background: #F8FAFC;
	border-radius: 30px;
	border: 1px solid #F5F5F5;
	margin-top: 35px;
	padding: 93px 40px 40px 40px
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .text_block p {
	font-size: 16px;
	text-align: right;
	max-width: 100%
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .text_block:before {
	background-color: #F5F5F5;
	border-radius: 10px;
	content: "";
	display: inline-block;
	height: 6px;
	left: 100%;
	position: absolute;
	top: 52px;
	width: 55px;
	z-index: 1
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header {
	justify-content: space-between;
	align-items: flex-end
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header h3 {
	background: rgba(0, 0, 0, 0);
	color: #042D50;
	font-size: 26px;
	font-weight: 600;
	height: auto;
	justify-content: flex-end;
	order: 2;
	padding: 0 40px;
	text-transform: uppercase;
	width: auto
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header h3:before {
	content: none
}

.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header .icon_box {
	height: 115px;
	left: auto;
	padding: 15px;
	position: relative;
	right: auto;
	width: 115px;
	flex: 0 0 115px
}

.results_sec {
	padding-top: 115px
}

.results_sec.imgConent_Sec {
	padding-top: 80px;
	padding-bottom: 150px
}

.results_sec p {
	font-size: 22px;
	line-height: 1.7;
	margin: 0
}

.results_sec h3 {
	font-size: 34px;
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 50px;
	text-transform: uppercase;
	width: 100%
}

.results_sec h3 b {
	color: #FD6600;
	font-weight: inherit
}

.results_sec h4 {
	font-size: 24px;
	font-weight: 500;
	line-height: 1.3;
	margin-bottom: 15px;
	width: 100%
}

.results_sec .cell p {
	color: #999999
}

.results_sec .cell .resp-container {
	background-color: #ffffff;
	height: 278px;
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
	width: 342px;
	z-index: 2
}

.results_sec .cell .resp-container .resp-iframe {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}

.results_sec .cell .img_wrap {
	display: inline-flex;
	height: auto;
	position: relative;
	width: 100%
}

.results_sec .cell .img_wrap:before {
	background: #FFF5EA;
	border-radius: 100%;
	bottom: -100px;
	content: "";
	display: inline-block;
	height: 200px;
	left: -100px;
	position: absolute;
	width: 200px;
	z-index: -1
}

.results_sec .cell .img_wrap iframe {
	height: 385px;
	width: 485px;
	position: relative
}

.results_sec .cell:last-child .img_wrap {
	justify-content: flex-end
}

.results_sec .cell:last-child .img_wrap:before {
	left: auto;
	right: -100px
}

.bootcampSec {
	padding-bottom: 60px;
	padding-top: 60px
}

.bootcampSec .subheading {
	font-size: 18px;
	line-height: 1.7;
	font-weight: 300;
	color: #9a9a9a;
	position: relative;
	width: 100%
}

.CourseBox {
	background: #fff;
	border-radius: 20px;
	border: 1px solid #EEEEEE;
	display: flex;
	margin-bottom: 30px;
	padding: 25px 20px 50px 20px;
	position: relative;
	overflow: hidden
}

.CourseBox .title {
	display: inline-block;
	font-size: 26px;
	font-weight: 500;
	line-height: 1;
	margin: 0 0 5px;
	position: relative;
	width: 100%
}

.CourseBox .subtitle {
	display: inline-block;
	position: relative;
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	color: #9f9f9f;
	line-height: 1;
	margin: 10px 0
}

.CourseBox p {
	color: #333;
	font-size: 18px;
	line-height: 1.3;
	margin: 0;
	position: relative
}

.CourseBox .btn-default {
	border-radius: 0;
	bottom: 0;
	font-size: 18px;
	font-weight: 400;
	left: 0;
	min-height: 42px;
	position: absolute;
	width: 100%
}

.CourseBox .tym_loc_wrap ul {
	display: inline-flex;
	flex-wrap: wrap;
	list-style: none;
	position: relative;
	width: auto
}

.CourseBox .tym_loc_wrap ul li {
	color: #999999;
	padding-left: 25px;
	text-overflow: ellipsis;
	overflow: hidden;
	position: relative
}

.CourseBox .tym_loc_wrap ul li:first-child {
	margin: 0
}

.CourseBox .tym_loc_wrap ul li:before {
	content: "";
	background-image: url(../../images/sprite1.png);
	height: 28px;
	width: 28px;
	background-position: -372px -72px;
	position: absolute;
	z-index: 9;
	top: 50%;
	transform: translateY(-50%);
	left: 0
}

.CourseBox .tym_loc_wrap ul li.tym:before {
	background-position: -372px -107px
}

.CourseBox .profile_detail {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 270px)
}

.CourseBox .profile_detail h5 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 5px;
	line-height: normal;
	color: #333
}

.CourseBox .profile_detail .cmp_in_name {
	color: #0088FF;
	line-height: normal;
	flex: 0 0 100%;
	margin-bottom: 15px
}

.main-header .navbar .navbar_btns .btn-login {
	border-radius: 16px;
	box-shadow: 0 3px 9px rgba(253, 102, 0, 0.5);
	min-height: 48px;
	min-width: initial;
	padding: 5px 20px;
	font-size: 16px;
	text-transform: uppercase
}

.main-header .navbar .navbar_btns .btn-login .icon {
	background-color: rgba(0, 0, 0, 0);
	background-image: url("../../images/ico-login.png");
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% auto;
	display: inline-block;
	height: 22px;
	width: 21px;
	margin-right: 15px
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
	display: block
}

.navbar-fixed {
	height: 64px
}

.navbar {
	position: relative;
	z-index: 997
}

.nav-collapsed.sidenav-main {
	width: 64px
}

.sidenav-main {
	position: fixed;
	z-index: 999;
	top: 0;
	width: 260px;
	height: 100%;
	transition: 0.3s ease all
}

@media screen and (max-width:1600px) {
	.bannerCaption {
		width: 600px
	}
	.bannerCaption h2 {
		font-size: 65px
	}
	.bannerCaption p {
		font-size: 22px
	}
	.bannerImg {
		width: 45%
	}
	.testimo_sec {
		padding: 80px 15px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner p {
		font-size: 18px
	}
}

@media screen and (max-width:1440px) {
	.banner_sec .banner_Content {
		padding-bottom: 50px;
		padding-top: 190px
	}
	.bannerCaption {
		padding-top: 60px;
		width: 520px
	}
	.bannerCaption h2,
	.bannerCaption p {
		width: 75%
	}
	.bannerCaption h2 {
		font-size: 50px
	}
	.bannerCaption p {
		font-size: 16px
	}
	.bannerCaption p b {
		font-size: 18px
	}
	.bannerCaption p b span {
		color: #042D50;
		display: inline-block;
		font-size: 20px;
		font-weight: bold
	}
	.bannerCaption .bannerForm:after {
		height: 100%
	}
	.bannerCaption .bannerForm .input-group input {
		border-radius: 15px;
		font-size: 16px;
		height: 70px;
		padding: 5px 175px 5px 25px
	}
	.bannerCaption .bannerForm .input-group input::-webkit-input-placeholder {
		font-size: 18px
	}
	.bannerCaption .bannerForm .input-group input:-moz-placeholder {
		font-size: 18px
	}
	.bannerCaption .bannerForm .input-group input::-moz-placeholder {
		font-size: 18px
	}
	.bannerCaption .bannerForm .input-group input:-ms-input-placeholder {
		font-size: 18px
	}
	.bannerCaption .bannerForm .input-group .btn.btn-default {
		border-radius: 10px;
		font-size: 18px;
		height: calc(100% - 20px);
		min-height: inherit;
		min-width: initial;
		padding: 5px 20px;
		right: 10px;
		top: 10px
	}
	.bannerImg {
		width: 55%
	}
	.softHum_sec .imgwrap {
		display: inline-block;
		padding: 5%
	}
	.softHum_sec h3 {
		font-size: 45px;
		line-height: 1.3
	}
	.softHum_sec p {
		font-size: 18px;
		line-height: 2
	}
	.section_title {
		font-size: 40px
	}
	.services_sec .cell .cell_Card .imgwrap {
		max-width: 165px
	}
	.sofrUniq_sec .cell h2 {
		font-size: 52px;
		line-height: 1.15;
		width: 390px
	}
	.sofrUniq_sec .cell h3 {
		font-size: 26px
	}
	.sofrUniq_sec .cell p {
		font-size: 18px
	}
	.sofrUniq_sec .cell .imgwrap {
		width: 430px;
		margin: 0 auto;
		display: flex
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-stage-outer {
		min-height: 555px
	}
}

@media screen and (max-width:1199px) {
	.banner_sec .banner_Content {
		padding-bottom: 20px;
		padding-top: 165px
	}
	.bannerImg {
		margin-right: 0;
		width: 50%
	}
	.bannerCaption {
		width: 50%
	}
	.bannerCaption h2 {
		font-size: 40px
	}
	.bannerCaption p {
		font-size: 14px
	}
	.bannerCaption p b {
		font-size: 16px
	}
	.bannerCaption p b span {
		font-size: 18px
	}
	.softHum_sec .imgwrap {
		display: inline-block;
		width: 95%
	}
	.softHum_sec h3 {
		font-size: 40px;
		margin-bottom: 15px;
		line-height: 1.3
	}
	.softHum_sec p {
		font-size: 16px;
		margin-bottom: 60px
	}
	.section_title {
		font-size: 40px;
		margin-bottom: 40px
	}
	.services_sec .container {
		padding-bottom: 90px;
		padding-top: 90px
	}
	.services_sec .cell h4 {
		font-size: 20px;
		margin-top: 30px
	}
	.services_sec .cell p {
		font-size: 15px
	}
	.services_sec .cell .cell_Card {
		border-radius: 20px;
		padding: 50px 15px 30px
	}
	.services_sec .cell .cell_Card .imgwrap {
		height: 150px
	}
	.services_sec .cell .cell_Card .imgwrap img {
		height: 100%
	}
	.sofrUniq_sec {
		padding-top: 95px;
		padding-bottom: 95px
	}
	.sofrUniq_sec .cell h2 {
		font-size: 46px;
		width: 80%
	}
	.sofrUniq_sec .cell h3 {
		font-size: 24px
	}
	.sofrUniq_sec .cell p {
		font-size: 16px
	}
	.sofrUniq_sec .cell .btn-default {
		margin-top: 55px
	}
	.sofrUniq_sec .cell .imgwrap {
		max-width: 100%
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-dots {
		margin-top: 0
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item {
		margin-top: 0;
		padding: 20px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item {
		opacity: 1
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .quote_ico {
		/* background-image: url("../images/ico-material-format-quote.png"); */
		width: 60px;
		height: 43px;
		margin-bottom: 30px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .client_img {
		height: 65px;
		width: 65px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .cell h6 {
		font-size: 20px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .cell strong {
		font-size: 14px
	}
	.storyTree_sec .container>.row:after {
		width: 503px
	}
	.storyTree_sec .container>.row:last-child:after {
		width: 250px
	}
	.story_sec .img_wrp {
		flex: 0 0 390px;
		width: 390px
	}
	.story_sec p {
		font-size: 18px
	}
	.results_sec .cell .img_wrap:before {
		left: -20px;
		bottom: -50px
	}
	.results_sec .cell:last-child .img_wrap:before {
		right: -15px
	}
}

@media screen and (max-width:991px) {
	.banner_sec .banner_Content {
		padding-bottom: 35px;
		padding-top: 135px;
		align-items: flex-start!important;
		position: relative
	}
	.bannerCaption {
		margin-right: -95px
	}
	.bannerCaption h2 {
		font-size: 28px
	}
	.bannerCaption .bannerForm {
		left: 0;
		margin-top: -35px;
		padding: 0px 50px;
		position: absolute!important;
		top: 100%
	}
	.bannerCaption .bannerForm .input-group input {
		box-shadow: 0 15px 30px -20px rgba(0, 0, 0, 0.5)
	}
	.bannerCaption .bannerForm:after {
		content: none
	}
	.softHum_sec h3 {
		font-size: 28px
	}
	.softHum_sec p {
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 40px
	}
	.pageheader {
		padding-top: 155px
	}
	.pageheader .pagetitle {
		font-size: 40px
	}
	.pageheader .subtitle {
		font-size: 20px
	}
	.tutoringTypes_sec {
		padding-bottom: 90px;
		padding-top: 75px
	}
	.tutoringTypes_sec h2 {
		font-size: 24px
	}
	.whosfor_sec .row {
		flex-direction: column
	}
	.whosfor_sec ul {
		padding-left: 0
	}
	.calltoaction_sec h3 {
		font-size: 30px
	}
	.upshot_sec .cell .cell_Card .imgwrap {
		margin-bottom: 35px
	}
	.upshot_sec .cell .cell_Card h4 {
		font-size: 22px
	}
	.upshot_sec .cell .cell_Card p {
		font-size: 16px
	}
	.storyTree_sec {
		padding-top: 70px
	}
	.storyTree_sec .textbox p {
		font-size: 14px
	}
	.storyTree_sec .textbox p b {
		font-size: 20px
	}
	.storyTree_sec .textbox:after,
	.storyTree_sec .textbox:before {
		height: 40px;
		width: 40px;
		bottom: -20px
	}
	.storyTree_sec .container>.row:after {
		width: 382px
	}
	.storyTree_sec .container>.row:last-child:after {
		width: 190px
	}
	.enterduo_sec {
		padding-top: 80px
	}
	.enterduo_sec p {
		font-size: 16px
	}
	.contactInfo_sec {
		padding-bottom: 100px
	}
	.contactInfo_sec .infoCard {
		padding: 35px 25px
	}
	.contactInfo_sec .infoCard .contact_icon {
		margin-bottom: 25px;
		width: 100px
	}
	.contactInfo_sec .infoCard h3 {
		font-size: 16px
	}
	.contactInfo_sec .infoCard a,
	.contactInfo_sec .infoCard p {
		font-size: 18px;
		word-break: break-all
	}
	.contactForm_sec h3 {
		font-size: 32px;
		margin-bottom: 30px
	}
	.contactForm_sec .contact_form {
		padding: 40px 30px
	}
	.contactForm_sec .pt-5,
	.contactForm_sec .py-5 {
		padding-top: 2rem!important
	}
	.story_sec p {
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 35px
	}
	.philosophy_sec h3 {
		font-size: 26px;
		max-width: 100%
	}
	.philosophy_sec h3 span {
		font-size: 88%
	}
	.philosophy_sec .img_wrp {
		display: inline-block;
		position: relative;
		width: 540px;
		max-width: 100%
	}
	.philosophy_sec p {
		font-size: 18px;
		max-width: 100%
	}
	.philosophy_sec h4 {
		font-size: 20px
	}
	.tutors_sec {
		padding-bottom: 70px;
		padding-top: 70px
	}
	.tutors_sec p {
		font-size: 20px
	}
	.tutors_sec h3 {
		font-size: 28px
	}
	.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header h3 {
		padding: 5px 35px 5px 30px
	}
	.tutors_sec .infoTree_sec .treeBlock .cat_header h3 {
		font-size: 25px;
		padding: 5px 30px 5px 35px
	}
	.tutors_sec .infoTree_sec .treeBlock .cat_header .icon_box {
		height: 90px;
		padding: 15px;
		width: 90px
	}
	.tutors_sec .infoTree_sec .treeBlock .text_block {
		padding: 25px 30px 25px 30px
	}
	.tutors_sec .infoTree_sec .treeBlock .text_block p {
		font-size: 16px
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell:not(.cell_empty):after,
	.tutors_sec.softwares_sec .infoTree_sec .cell:not(.cell_empty):before {
		content: none
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(odd) {
		padding-right: 15px
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) {
		padding-left: 15px;
		padding-right: 15px
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell:nth-child(2n) .treeBlock .cat_header h3 {
		padding: 0 25px
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell.cell_empty {
		display: none
	}
	.tutors_sec.softwares_sec .infoTree_sec .cell:not(:first-child) {
		margin-top: 50px
	}
	.tutors_sec.softwares_sec .infoTree_sec .treeBlock .text_block:before {
		content: none
	}
	.results_sec {
		padding-top: 80px
	}
	.results_sec.imgConent_Sec {
		padding-top: 60px;
		padding-bottom: 60px
	}
	.results_sec p {
		font-size: 18px
	}
	.results_sec h3 {
		font-size: 22px
	}
	.results_sec h4 {
		font-size: 18px
	}
	.results_sec .cell .img_wrap:before {
		content: none
	}
}

@media screen and (max-width:767px) {
	.banner_sec .banner_Content {
		flex-direction: column;
		align-items: center!important
	}
	.banner_sec .bannerImg {
		order: 1;
		padding: 0 10%;
		width: 100%
	}
	.banner_sec .bannerCaption {
		order: 2;
		margin: 50px 0 0;
		width: 100%;
		text-align: center
	}
	.banner_sec .bannerCaption h2,
	.banner_sec .bannerCaption p {
		width: 100%
	}
	.softHum_sec .imgwrap {
		width: 100%;
		padding: 0 10%
	}
	.softHum_sec .col-12 {
		text-align: center
	}
	.services_sec .cell .cell_Card:hover {
		background-color: transparent;
		box-shadow: none
	}
	.sofrUniq_sec {
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center
	}
	.sofrUniq_sec .cell h2 {
		width: 100%
	}
	.sofrUniq_sec .imgwrap {
		display: inline-block;
		padding: 50px 12% 0;
		width: 100%
	}
	.pageheader .pagetitle {
		font-size: 28px
	}
	.tutoringTypes_sec .infographic_contnt {
		flex-direction: column;
		flex-wrap: wrap
	}
	.tutoringTypes_sec .infographic_contnt .cell .imgWrap {
		display: inline-block;
		width: 380px
	}
	.tutoringTypes_sec .infographic_contnt .saperator {
		height: 2px;
		margin: 55px auto;
		width: 125px
	}
	.tutoringTypes_sec p {
		font-size: 16px
	}
	.whosfor_sec {
		padding-top: 80px;
		padding-bottom: 80px
	}
	.whosfor_sec .section_title {
		text-align: center
	}
	.whosfor_sec p {
		font-size: 18px;
		text-align: center;
		margin-bottom: 30px
	}
	.whosfor_sec ul li {
		padding: 15px 0
	}
	.whosfor_sec ul li .count {
		font-size: 30px;
		height: 60px;
		flex: 0 0 60px;
		width: 60px
	}
	.upshot_sec .cell {
		margin-top: 60px
	}
	.upshot_sec .cell:first-child {
		margin: 0
	}
	.upshot_sec .cell .cell_Card {
		padding: 0 30px 30px
	}
	.calltoaction_sec {
		padding-bottom: 45px;
		padding-top: 45px
	}
	.calltoaction_sec .row {
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center
	}
	.calltoaction_sec h3 {
		padding: 0 0 45px;
		text-align: center;
		font-size: 26px
	}
	.storyTree_sec .container>.row {
		flex-wrap: wrap
	}
	.storyTree_sec .container>.row .cell {
		text-align: center
	}
	.storyTree_sec .container>.row .cell .imgWrap {
		display: inline-block;
		max-width: 100%;
		width: 380px
	}
	.storyTree_sec .container>.row .cell:first-child {
		order: 2
	}
	.storyTree_sec .container>.row .cell:last-child {
		order: 1
	}
	.storyTree_sec .container>.row:after {
		width: 60%
	}
	.storyTree_sec .container>.row:before {
		left: 20%
	}
	.storyTree_sec .container>.row:first-child .cell:first-child {
		margin-top: 30px
	}
	.storyTree_sec .container>.row:nth-child(2) {
		padding-bottom: 20px
	}
	.storyTree_sec .container>.row:nth-child(2):before {
		right: 20%
	}
	.storyTree_sec .container>.row:nth-child(2):after {
		height: 77%;
		left: 20%;
		bottom: -8px;
		transform: translateX(-50%)
	}
	.storyTree_sec .container>.row:nth-child(2) .cell .imgWrap {
		margin-top: 30px
	}
	.storyTree_sec .container>.row:nth-child(2) .textbox:before {
		right: 20%;
		left: auto;
		transform: translateX(50%)
	}
	.storyTree_sec .container>.row:last-child {
		padding-top: 50px
	}
	.storyTree_sec .container>.row:last-child:after {
		width: 31%;
		left: 20%;
		margin-left: -4px
	}
	.storyTree_sec .container>.row:last-child .textbox:before {
		left: 50%
	}
	.storyTree_sec .textbox {
		position: static!important
	}
	.storyTree_sec .textbox:after,
	.storyTree_sec .textbox:before {
		left: 20%
	}
	.contactInfo_sec .col-md-4 {
		margin-bottom: 30px
	}
	.contactInfo_sec .col-md-4:last-child {
		margin-bottom: 0
	}
	.contactInfo_sec .infoCard {
		margin: 0 auto;
		display: flex!important;
		max-width: 280px
	}
	.story_sec {
		padding-bottom: 50px;
		padding-top: 50px
	}
	.story_sec .imgtxt_section {
		align-items: center;
		flex-direction: column
	}
	.story_sec .imgtxt_section p {
		order: 2
	}
	.tutors_sec .infoTree_sec .cell.cell_empty {
		display: none
	}
	.tutors_sec .infoTree_sec .cell:not(.cell_empty):after,
	.tutors_sec .infoTree_sec .cell:not(.cell_empty):before {
		content: none
	}
	.tutors_sec .infoTree_sec .cell:not(:first-child) {
		margin-top: 50px
	}
	.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header h3 {
		font-size: 22px;
		padding: 0 25px
	}
	.tutors_sec.softwares_sec .infoTree_sec .treeBlock .cat_header .icon_box {
		border-radius: 20px;
		flex: 0 0 85px;
		height: 85px;
		width: 85px
	}
	.tutors_sec.softwares_sec .infoTree_sec .treeBlock .text_block {
		padding: 70px 25px 30px 25px
	}
	.results_sec {
		padding-bottom: 60px
	}
	.results_sec.imgConent_Sec {
		padding-top: 0;
		padding-bottom: 60px
	}
	.results_sec h3 {
		margin-bottom: 15px
	}
	.results_sec .row {
		justify-content: center
	}
	.results_sec .cell .resp-container {
		height: auto;
		margin-bottom: 20px
	}
	.results_sec .cell .img_wrap {
		justify-content: center
	}
	.results_sec .cell.text-cell {
		order: 2;
		text-align: center
	}
	.results_sec .cell:last-child .img_wrap {
		justify-content: center
	}
}

@media screen and (max-width:540px) {
	.banner_sec .banner_Content {
		padding-top: 95px
	}
	.bannerCaption .bannerForm {
		padding: 0
	}
	.section_title {
		font-size: 32px
	}
	.softHum_sec {
		padding-bottom: 65px
	}
	.services_sec .container {
		padding-bottom: 30px;
		padding-top: 60px
	}
	.sofrUniq_sec .cell h2 {
		font-size: 42px
	}
	.sofrUniq_sec .cell h3 {
		font-size: 22px
	}
	.sofrUniq_sec .cell p {
		font-size: 16px
	}
	.testimo_sec .carousel_wrap .owl-carousel {
		padding: 0 35px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-stage-outer {
		min-height: initial
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-nav button {
		height: 30px;
		width: 30px;
		padding: 5px!important
	}
	.contactForm_sec h3 {
		font-size: 28px;
		margin-bottom: 30px
	}
	.contactForm_sec .contact_form {
		padding: 20px 15px 50px
	}
	.contactForm_sec .app_form .form-group .radio_group {
		flex-wrap: wrap;
		margin: 0
	}
	.contactForm_sec .app_form .form-group .custom-control {
		margin: 10px!important
	}
	.story_sec .img_wrp {
		margin: 0 0 30px;
		flex: 0 0 100%;
		width: 100%
	}
	.story_sec .imgtxt_section p {
		margin-bottom: 35px
	}
	.philosophy_sec {
		padding-top: 50px;
		padding-bottom: 50px
	}
	.philosophy_sec.philo_bgSec {
		padding-top: 70px;
		padding-bottom: 70px
	}
	.philosophy_sec h3 {
		font-size: 20px;
		margin-bottom: 35px
	}
	.philosophy_sec p {
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 30px
	}
	.philosophy_sec h4 {
		margin-top: 25px
	}
	.tutors_sec {
		padding-bottom: 50px;
		padding-top: 50px
	}
	.tutors_sec p {
		font-size: 16px
	}
	.tutors_sec h3 {
		font-size: 20px;
		margin-bottom: 50px
	}
	.tutors_sec .infoTree_sec .cell:nth-child(odd) {
		padding-right: 15px
	}
	.tutors_sec .infoTree_sec .cell:nth-child(2n) {
		padding-left: 15px
	}
	.tutors_sec .infoTree_sec .cell:nth-child(2n) .treeBlock {
		padding-left: 0;
		padding-right: 0
	}
	.tutors_sec .infoTree_sec .treeBlock {
		padding-left: 0
	}
	.tutors_sec .infoTree_sec .treeBlock .cat_header h3:before {
		content: none
	}
	.tutors_sec .infoTree_sec .treeBlock .text_block {
		padding: 25px 15px 25px 15px
	}
}

@media screen and (max-width:480px) {
	.banner_sec {
		background-position: right -45px bottom
	}
	.bannerCaption h2 {
		font-size: 22px
	}
	.bannerCaption .bannerForm {
		margin: 15px 0 0;
		position: relative!important
	}
	.bannerCaption .bannerForm .input-group input {
		border-radius: 10px;
		font-size: 14px;
		height: 55px;
		padding: 5px 25px
	}
	.bannerCaption .bannerForm .input-group .btn.btn-default {
		font-size: 16px;
		height: 46px;
		margin: 20px auto;
		position: relative;
		right: auto;
		top: auto
	}
	.softHum_sec {
		padding-top: 10px
	}
	.section_title {
		font-size: 26px
	}
	.testimo_sec .carousel_wrap .owl-carousel {
		padding: 0 20px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item {
		padding: 35px 15px 15px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .quote_ico {
		width: 46px;
		height: 33px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner p {
		font-size: 16px;
		margin-bottom: 25px
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info {
		flex-direction: column;
		text-align: center
	}
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .pr-4,
	.testimo_sec .carousel_wrap .owl-carousel .owl-item .item .box_inner .use_info .px-4 {
		padding-bottom: 10px!important;
		padding-right: 0!important
	}
	.calltoaction_sec h3 {
		padding: 0 0 30px
	}
	.results_sec {
		padding-top: 50px
	}
}