.preview-question--inner {
    padding: 20px 15px;
    width: 709px;
    .preview-question--ans {
        margin-top: 20px;
        ul {
            padding: 0;
            list-style: none;
            margin: 0;
            li {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding-top: 10px;
                border-top: solid 1px #eaeaea;
                > span {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    color: #21cec6;
                    border: solid 2px #21cec6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 30px;
                }
                .ans-content {
                    padding-left: 10px;
                    -ms-flex-preferred-size: 0;
                    flex-basis: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    max-width: calc(100% - 30px);
                }
            }
        }
    }
}