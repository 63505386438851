.admin-subtopic {
    display: flex;
    flex-direction: column;
    > p {
        margin: 0;
        cursor: pointer;
    }
    .options {
        display: flex;
        margin-left: 10px;

        .svg-container {
            display: flex;

            > svg {
                width: 30px;
                height: 30px;
                cursor: pointer;

                path {
                    fill: grey;
                }

                &:hover {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }
    
}
.sequenceBtn {
    display: inline-block;
    padding: 4px 8px 0 8px;
    button {
        padding: 8px 10px;
        background-color: #2B7A78;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
            opacity: .8;
        }
    }
}
