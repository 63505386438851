@import './scss/variables';

html {
  max-width: 1920px;
  max-height: 1080px;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Lato', 'Noto Sans', 'Helvetica', 'Corbel', sans-serif;
}

#root {
  height: 100%;

  main {
    height: 100%;
  }
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: $black;

  .svg-container {
      > svg {
          width: 25px;
          height: 25px;
      }
  }

  h1 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }
}
li.sortable-topic { z-index: 999999; list-style: none; display: inline-flex;}


.errorCls {
  color: #f12f2f;
  font-size: 14px;
}

.errCommonCls {
  font-size: 14px;
  padding: 3px 10px 3px 10px !important;
  background: rgba(237, 49, 54, .1);
  box-shadow: 0px 2px 3px #e2e2e2;
  border-radius: 3px;
  text-align: justify;
  text-justify: inter-word;
  width: max-content;
  margin: 0 auto;
  position: relative;
}

.errCommonCls::after {
  font-family: "Font Awesome 5 pro";
  top: 6px;
  position: absolute;
  left: 9px;
}

.serviceMessage {
  margin-top: 10px;
  margin-bottom: 10px;
}

.successCls {
  color: #155724;
  font-size: 14px;
}

.successCommonCls {
  font-size: 14px;
  padding: 3px 10px 3px 10px !important;
  background: rgba(212,237,218, 1);
  box-shadow: 0px 2px 3px #e2e2e2;
  border-radius: 3px;
  text-align: justify;
  text-justify: inter-word;
  width: max-content;
  margin: 0 auto;
  position: relative;
}

.successCommonCls::after {
  font-family: "Font Awesome 5 pro";
  top: 6px;
  position: absolute;
  left: 9px;
}

.successMessage {
  margin-top: 10px;
  margin-bottom: 10px;
}