@import "../../../../scss/variables";

.answer-editor {
    display: flex;
    flex-direction: column;
    background-color: white;

    .answer-display {
        display: flex;
        .svg-container {
            display: flex;
            width: 25px;

            &.checkmark {
                > svg > path {
                    fill: $dark-teal;
                }
            }
            > svg {
                width: 25px;
            }

        }
        &:hover {
            cursor: pointer;
            background-color: #d8d8d8;
        }
    }
}
