@import "../scss/mixins";
@import "../scss/variables";

.account-menu {
    position: relative;
    overflow: visible;
    .user-account {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > h2 {
            margin: 0;
            color: $grey;
        }

        .svg-container {
            margin-left: 5px;
            display: flex;
            > svg {
                width: 20px;
                height: 20px;
                fill: $grey;
            }
        }

        &:hover {
            > h2 {
                color: $white;
            }
            svg {
                fill: $white;
            }

            cursor: pointer;
        }
    }

}
