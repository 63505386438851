@import "../scss/variables";

.read-editor-container {
    width: $editor-width;
    max-width: 100%;
    .DraftEditor-root {
        pointer-events: none;

        // Targets images
        figure {
            margin: 5px 0;
        }
    }

    blockquote {
        text-align: center;
        padding: 0 0 0 40px;
        margin: 0;
    }
}

.student-section-editor-container .DraftEditor-editorContainer > div > div > *:not(figure) {
    pointer-events: none;
}

.student-section-editor-container figure img {
    max-width: 80%;
}

a.tooltip-tag {
    pointer-events: fill !important;
}