.question-rank {
    display: flex;
    margin-right: 20px;
    > ul {
        display: flex;
        list-style: none;
        padding: 0;

        > li {
            width: 20px;
            height: 20px;
            text-align: center;
            border: 1px solid black;
        }
    }
    .blue {
        background-color: blue;
    }

    .red {
        background-color: red;
    }

    .yellow {
        background-color: yellow;
    }

    .green {
        background-color: green;
    }

    .orange {
        background-color: orange;
    }

    .inactive {
        background-color: grey;
    }

}
