@import '../../scss/variables';

.home-sat-item {
    .hide-menu {
        cursor: pointer;
    }

    .options {
        display: flex;
        margin-left: 10px;

        .svg-container {
            display: flex;

            > svg {
                width: 30px;
                height: 30px;
                cursor: pointer;

                path {
                    fill: grey;
                }

                &:hover {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    .questions {
        & > div > ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            text-align: center;
            padding-top: 12px;
            & > li.question {
                width: 42px;
                padding: 13px 0;
                margin: 10px 0;
                outline: 1px solid black;
                margin-left: 1px;
                position: relative;
                background-color: #fff;
                &.active {
                    background-color: $teal;
                    color: $white;
                    font-weight: bold;

                    &:hover {
                        background-color: $teal;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

        }
    }

}

.questions .satQuesIdTitleCls {
    font-size: 20px;
    font-weight: 400;
    color: #2b2b2b;
}
.backDropShownCls {
    &::after {
        content: '';
        display: inline-block;
        width: 100%;
        // height: 100vh;
        height: -webkit-fill-available;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9;
    }
    .submitCancelCls {
        z-index: 10;
    }
    .selectSectionCls {
        background-color: #ffffff;
        border-radius: 5px;
        display: inline-block;
        padding: 15px;
        position: relative;
        z-index: 10;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
    }
}

.activeUncertainCls{
    color: #212529;
    background-color: #ffffff !important;
}

.activeUncertainCls::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.activeWrongCls {
    color: #fff;
    background-color: #dc3545  !important;
}

.activeUncertainCls.activeWrongCls {
    color: #fff;
    background-color: #dc3545;
}

.activeUncertainCls.activeWrongCls::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.activeUncertainLightCls{
    color: #212529;
    background-color: #ffffff !important;
}

.activeUncertainLightCls::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.activeWrongLightCls {
    color: #fff;
    background-color: #dc3545  !important;
}

.activeUncertainLightCls.activeWrongLightCls {
    color: #fff;
    background-color: #dc3545;
}

.activeUncertainLightCls.activeWrongLightCls::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.activeCorrectLightCls{
    color: #fff;
    background-color: #27a745  !important;
}

.activeUncertainLightCls.activeCorrectLightCls {
    color: #fff;
    background-color: #27a745;
}

.activeUncertainLightCls.activeCorrectLightCls::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.scoreOfHeadersCls {
    font-size: 16px;
    font-weight: bold;
}

.overAllScoreCls{
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

.overAllScoreCls > span:first-child {
    background-color: transparent;
    padding-left: 0;
}
.overAllScoreCls > span {
    background: #dedede;
    margin: 5px 15px;
    padding: 5px 15px;
    border-radius: 30px;
    color: #dc3545;
    font-size: 17px;
}

.switchIconCls {
    color: #fff;
    margin: 5px 5px 5px 8px;
    font-size: 15px;
}

.mockEnabledCls {
    opacity: 0.7;
    background: #ccc;
    padding: 7px;
    pointer-events: none;
    margin-bottom: 10px;
}

.percentAboveCls {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 1.3;
    color: #27a745;
}

.progressVerticalCls{
    background-color: #27a745;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.textShowIndCls{
    position: relative;
    z-index: 2;
    display: inline-block;
}

.satTypeProgressCls .progress span {
    position: absolute;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
    color: #185a1b;
}

.satTypeProgressCls .progress {
    height: 25px;
    width: 120px;
    position: relative;
}