.home-subtopic-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.hamburger {
    margin: 6px 0 0 0;
    padding: 0 10px;
    float: right;
}
.sel-topics-list{
    position: absolute;
    padding: 10px;
    background: #fff;
    z-index: 1;
    margin: 38px 7px;
    border: 2px solid #3aafa9;
}

.home-subtopic-main.testQuestionsCls {
    margin: 0 auto;
    max-width: 770px;
    h1.quesTitle {
        font-size: 30px;
        line-height: 1.1;
        margin: 0 0 10px;
        border-bottom: 2px solid #eaeaea;
        padding-bottom: 20px;
    }
    .home-questions {
        // overflow-y: hidden;
        flex-wrap: wrap;
    }
}

