@import "../scss/variables";

.editor-container {
    display: flex;
    flex-direction: column;
    width: 709px;
    position: relative;

    .editor-buttons {
        display: flex;
        justify-content: flex-start;

        > button {
            margin-right: 5px;
        }

    }

    .editor {
        width: 100%;
        max-width: 713px !important;
        border: 1px solid black;
        min-height: 150px;
        margin: 15px auto 5px auto;
        background-color: white;
        caret-color: black;
        max-height: 500px;
        overflow-y: scroll;

        // Targets images
        figure {
            margin: 5px 0;
        }

        // Targeted Draft JS classes

        // Toolbar plugin main component
        .draftJsToolbar__toolbar__dNtBH {
            // Current hack until buttons are moved outside of editor
            position: absolute;
            top: 0;
            right: 0;
        }

        // Buttons inside  button wrapper
        .draftJsToolbar__button__qi1gf {
            &:hover {
                cursor: pointer;
            }
        }

        iframe {
            width: 580px;
            height: 250px;
        }

        textarea {
            resize: auto;
        }

        blockquote {
            text-align: center;
            padding: 0px 0px 0px 40px;
            margin: 0;
        }
    }
}

.flex-item {
    margin-left: auto;
}

.katexInsertButton {
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    border-style: none;
    border-radius: 1px;

    &:hover {
        background-color: #e0dbdb;
    }
}

.taxKatex {
    //position: absolute;
    display: block;
    width: 50%;
    min-width: 10%;
    max-width: 100%;
}

.katexPanel {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.katexSaveButton {
    background-color: #00C789;
    color: white;
    padding: 4px 5px;
    border-radius: 5px;
    &:focus {
        background-color: #00C789;
        opacity: 0.7;
    }
}

.katexRemoveButton {
    margin-left: 10px;
    background-color: #F06150;
    color: white;
    padding: 4px 5px;
    border-radius: 5px;
    &:focus {
        background-color: #F06150;
        opacity: 0.7;
    }
}


.katexButtons {
    margin-top: 2px;
    background-color: transparent;
}

@-webkit-keyframes change-inherit-initial {
    from { display: inherit; }
    to { display: initial; }
}
@keyframes change-inherit-initial {
    from { display: inherit; }
    to { display: initial; }
}

.public-DraftStyleDefault-ltr
{
    span
    {
        > textarea
        {
            min-height: 20px !important;
            max-width: 99%;
            width: auto !important;
            height: auto !important;
        }

        > textarea + Span
        {
            top: 100% !important;
        }
    }
}