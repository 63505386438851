@import '../scss/mixins';

.admin {
    @include base-page();
}
.d-flex {
    display: flex;
}
.d-inline-flex {
    display: inline-flex;
}
