.loginPage_sec {
  display: inline-block;
  margin-bottom: 70px;
  margin-top: 161px
}

.loginPage_sec .login_form {
  border: 1px solid #ebebeb;
  border-radius: 27px;
  box-shadow: 0 15px 40px rgba(107, 107, 107, 0.1);
  margin: 0 auto;
  max-width: 420px;
  padding: 50px 50px 30px
}

.loginPage_sec .login_form h2 {
  color: #023B4E;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 5px
}

.loginPage_sec .login_form p {
  color: #808080;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 0;
  position: relative;
  width: 100%
}

.loginPage_sec .login_form .txt_btn {
  color: #0088FF;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: relative
}

.loginPage_sec .login_form .txt_btn:hover {
  color: #333333;
  text-decoration: underline
}

.loginPage_sec .login_form .txt_btn.forgot_pass {
  margin: 5px 0
}

.loginPage_sec .login_form .createacc {
  font-size: 14px
}

.loginPage_sec .login_form .createacc a {
  font-weight: 500
}

.loginPage_sec .login_form .app_form .form-group {
  margin-bottom: 30px
}

.loginPage_sec .login_form .app_form .form-group .input-label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  position: absolute;
  background: #fff;
  padding: 5px;
  z-index: 2;
  width: auto;
  display: inline-block;
  top: -14px;
  left: 30px
}

.loginPage_sec .login_form .app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]) {
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #AFAFAF;
  padding: 10px 15px
}

.loginPage_sec .login_form .app_form .form-group .input-group {
  z-index: 1
}

.loginPage_sec .login_form .app_form .form-group .input-group {
  & > .input-group-addon + input {
     padding-right: 55px !important;
  }
}

.loginPage_sec .login_form .app_form .form-group .input-group .input-group-addon {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 66px;
  z-index: 10;
}

.loginPage_sec .login_form .app_form .form-group .input-group .input-group-addon .inptIcon {
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  display: inline-block;
  height: 32px;
  width: 32px
}

.loginPage_sec .login_form .app_form .form-group .input-group .input-group-addon .inptIcon.email {
  background-image: url("../../images/ico-mail-square.png")
}

.loginPage_sec .login_form .app_form .form-group .input-group .input-group-addon .inptIcon.password {
  background-image: url("../../images/ico-lock-square.png")
}

.loginPage_sec .login_form .app_form .form-group .check_ele input[type=checkbox]:checked+span.label:after {
  border-color: #fff;
  opacity: 1
}

.loginPage_sec .login_form .app_form .form-group .form-check {
  padding-left: 34px;
  position: relative
}

.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label:before,
.loginPage_sec .login_form .app_form .form-group .form-check input[type=checkbox] {
  cursor: pointer;
  height: 24px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 24px
}

.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label {
  color: #8B8B8B;
  font-size: 14px;
  line-height: 24px;
  position: static;
  z-index: 5
}

.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label:after,
.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label:before {
  content: "";
  display: inline-block
}

.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label:before {
  border: 1.5px solid #AFAFAF;
  border-radius: 7px
}

.loginPage_sec .login_form .app_form .form-group .form-check .form-check-label:after {
  border-color: #2c3a81;
  border-style: solid;
  border-width: 0 0 2px 2px;
  height: 8px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg)!important;
  width: 14px
}

.loginPage_sec .login_form .app_form .form-group .form-check input[type=checkbox] {
  opacity: 0;
  z-index: 10
}

.loginPage_sec .login_form .app_form .form-group .form-check input[type=checkbox]:checked+.form-check-label:before {
  background-color: #FD6600;
  border-color: #FD6600;
  box-shadow: 0 3px 9px rgba(253, 102, 0, 0.5)
}

.loginPage_sec .login_form .app_form .form-group .form-check input[type=checkbox]:checked+.form-check-label:after {
  border-color: #fff;
  opacity: 1
}

.loginPage_sec .login_form .app_form .form-group .loginbtn {
  box-shadow: 0 7px 18px rgba(254, 117, 30, 0.25);
  font-size: 18px;
  border-radius: 16px;
  font-weight: 500;
  min-height: 50px;
  min-width: initial;
  padding: 5px 30px;
  width: 100%
}

.loginPage_sec .login_form .app_form .form-group .loginbtn .ico {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../images/ic-arrows-right.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  display: inline-block;
  height: 32px;
  width: 32px;
  position: absolute;
  right: 8px
}
