.sortable-questions {
    .sortable-question-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        justify-content: center;
        padding: 0;

        > li {
            padding: 10px;
        }

        .katex {
            position: relative;
        }
    }
    .sortable-question {
        display: flex;

        .sortable-handle {
            position: relative;
            top: 1px;
            display: block;
            width: 18px;
            height: 11px;
            opacity: .25;
            margin-right: 20px;
            cursor: row-resize;
            background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
            background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
        }
    }
}
