div.recycle-bin-dialog > {
  div.body {
    overflow-y: hidden !important;
  }

}

.card-body {
  div {
    .infinite-component {
      max-height: 50vh;
    }
  }
}


