@import "../scss/variables";

.answer-list {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    width: 100%;
    counter-reset: list;
    padding: 0;

    > li {

        &:first-child {
            border-top: 1px solid #c5c2c2;
        }
        list-style: none;
    }
}
