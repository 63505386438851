.admin-problemSet-popup {
    padding: 15px;
    h1 {
        font-size: 24px;
        margin: 3px 0 15px;
        border-bottom: solid 1px #ddd;
        padding-bottom: 10px;
    }
    // .sequenceBtn {
    //     margin: 10px 0 20px;
    //     button {
    //         padding: 6px 14px;
    //         background-color: #2B7A78;
    //         color: #fff;
    //         border: none;
    //         border-radius: 3px;
    //         cursor: pointer;
    //         font-size: 20px;
    //         &:hover {
    //             opacity: .8;
    //         }
    //     }
    // }

    // .sequenceHomeBtn {
    //     margin: 10px 0 20px;
    //     button {
    //         padding: 7px 25px;
    //         background-color: #2B7A78;
    //         color: #fff;
    //         border: none;
    //         border-radius: 3px;
    //         cursor: pointer;
    //         font-size: 23px;
    //         &:hover {
    //             opacity: .8;
    //         }
    //     }
    // }

    .sequenceHomeBtn button:disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    .sequenceBtn button:disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    .problemSet-list {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                padding-left: 14px;
                padding-bottom: 8px;
                position: relative;
                &:after {
                    content:"";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background-color: #2B7A78;
                    height: 5px;
                    width: 5px;
                }
            }
        }
    }
    .subtopic-form {
        display: flex;
        align-items: center;
        cursor: pointer;


        .svg-container {
            margin-right: 10px;
            > svg {
                width: 15px;
            }
        }
    }

    div.problem-set-div {
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        padding: 10px 20px 10px 20px;
        margin: 10px;
    }

    div.selected-problem-set {
        background-color: rgba(60,179,113, 0.15);
    }

    .header-title-row {
        display: flex;
        justify-content: space-between;
        .select-submit-button-div{
            display: flex;
            margin-left: auto;
            //margin-right: 10px;
            & > button {
                margin-right: 10px;
                display: inline;
            }
        }
    }

}