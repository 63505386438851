
// .pageMain_body {
//     // display: flex;
//     // flex-wrap: wrap;
//     display: inline-table;
//     height: 100%;
//     min-height: 100vh;
//     padding-bottom: 81px;
//     position: relative;
//     width: 100%;
// }

// footer.page-footer {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     width: 100%;
// }
.admin-question .top-section .editor-section .question-editor .read-editor-container, 
.read-editor-container, 
.question_sec .home-questions {
    width: 700px !important;
}
.contentCenter_wrap .vertical-modern-dashboard > div.col-12 {
    max-width: 1222.5px;
    margin: 0 auto;
}
.label-danger {
    color: #F06150 !important;
}
.sidenav-active-square .sidenav li > a {
   position: relative;
}

blockquote {
    // background: #efefef;
    border: none;
    border-radius: 10px;
    margin: 0 !important;
    padding: 5px 0px 5px 20px !important;
    position: relative;
    &::before {
        color: #000;
        // content: '"';
        display: inline-block;
        font-size: 200%;
        font-weight: 500;
        left: 0;
        left: 20px;
        opacity: .33;
        position: absolute;
        top: 10px;
    }
}

.errCommonCls {
    padding: 3px 10px 3px 30px;
    &::after {
        top: 2px;
    }
}
.dialog {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
.pageMain_body {
    // border: 1px solid red;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 81px;
    position: relative;
    footer {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
    }
    // &.modal-open{
    //     overflow: hidden !important;
    //     height: 100vh;
    // }
}

.table td, .table th {
    vertical-align: middle;
}

.table-responsive {
    margin: 0 !important;
    th {
        &:first-child, 
        &:last-child {
            &::before {
                content: none;
            }
        }
    }
}

// =========================
//      Buttons Style 
// =========================
.btn-default {
    border-radius: 5px;
    height: 34px;
    padding: 3px 11px;
    line-height: 1.3;
    .ico {
        border-radius: 5px;
    }
    &.btn-primary {
        .ico {
            border-radius: 5px;
        }
    }
}
.btn:not(.btn-default) {
    align-items: center;
    background-color: #F8F9FB;
    border-radius: 5px;
    border: 1px solid #E7EAEF;
    border: none;
    // box-shadow: 0 7px 18px rgba(254, 117, 30, 0.25);
    box-shadow: none;
    color: #333;
    cursor: pointer;
    display: inline-flex;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 1.3;
    min-height: initial;
    min-width: initial;
    overflow: hidden;
    padding: 3px 11px;
    position: relative;
    text-transform: capitalize;
    transition: all 0.25s ease;
    & >* {
        position: relative;
        z-index: 2;
    }
    &::after {
        background-color: #fff;
        content: "";
        display: inline-block;
        height: 110%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: scale(0);
        transition: all 0.35s ease;
        width: 110%;
        z-index: 1;
    }
    i {
        font-size: 22px;
        margin: 0 0 0 10px;
        line-height: 22px;
        position: relative;
    }
    &.btn-danger {
        background-color: #F06150;
        border-color: #F06150;
        color: #fff;
        // box-shadow: 0 6px 18px rgba(240, 97, 80, 0.45);
    }
    &.btn-success {
        background-color: #00C789;
        border-color: #00C789;
        color: #fff;
        // box-shadow: 0 6px 18px rgba(0, 199, 137, 0.45);
    }
    &.btn-primary {
        background-color: #FD6600;
        border-color: #FD6600;
        color: #fff;
        // box-shadow: 0 6px 18px rgba(253, 102, 0, 0.35);

        &:not(:disabled):not(.disabled).active, 
        &:not(:disabled):not(.disabled):active {
            background-color: #FD6600;
            border-radius: 5px;
            outline: 0;
            // box-shadow: 0 0 0 0.2rem rgba(253, 102, 0, 0.25);
        }
    }
    &.btn-warning {
        // background-color: #FEC001;
        // border-color: #FEC001;
        // color: #fff;
        // box-shadow: 0 6px 18px rgba(254, 192, 1, 0.45);
        background-color: #ffbf00;
        border-color: #ffbf00;
        &:hover,
        &:focus {
            color: #333;
        }
    }
    &.btn-info {
        background-color: #02A2B9;
        border-color: #02A2B9;
        color: #fff;
        // box-shadow: 0 6px 18px rgba(2, 162, 185, 0.45);
    }
    &[disabled] {
        box-shadow: none;
        cursor: not-allowed;
    }
    &:hover,
    &:focus {
        color: #ffffff;
        outline: none;
        &::after {
            opacity: 0.25;
            transform: scale(1);
        }
    }
    &:focus,
    &:active {
        border-radius: 5px;
        outline: 0;
        // box-shadow: 0 0 0 0.2rem rgba(253, 102, 0, 0.25);
    }
}

.btn-default {
    .ico {
        &.backicon {
            margin: 0 10px 0 -11px;
            &::before {
                background-image: url("../../images/ico-pageback.png");
                height: 18px;
                width: 17px;
            }
        }
    }
}

// =========================
//      Buttons Style Ends
// =========================

.css-2b097c-container {
    position: relative;
    z-index: 10;
}

.card {
    border-radius: 10px;
    .card-header {
        display: flex;
    }
    .card-content {
        .section_title {
            // text-align: left;
            width: auto;
            display: inline-block;
        }    
    }    
}

.question_sec {
    .dashtitle_sec {
        margin-bottom: 30px;
        margin-bottom: 0 !important;
        position: relative;
        z-index: 10;
        & > div {
            position: relative;
        }
        .btn.btn-default {
            position: absolute;
            top: 2px;
            left: 0;
        }
        & + .quesHeader {
            .quesTitle {
                min-height: 35px;
                padding: 0 100px;
            }
        }
    }
} 

.sidenav-active-square {
    .sidenav li:not(.open) > a.active, 
    .sidenav-active-square .sidenav li:not(.open) > a.active > i, 
    .sidenav-active-square .sidenav li > a.active > i {
        color: #FD6600;
    }
    .sidenav {
        li > a.active {
            background: rgba(253, 102, 0, 0.08);
            border-radius: 0;
            margin-right: 0;
        }
    }
}

.preload-transitions {
    .sidenav-main, #main {
        transition: .3s ease all !important;
    }
}

.progressVerticalCls {
    background-color: #00C789;
    // background-color: #57D100;
}

.activeWrongLightCls {
    background-color: #F06150 !important;
    color: #ffffff !important;
}

.activeCorrectLightCls {
    background-color: #00C789 !important;
    // background-color: #57D100 !important;
    color: #000000 !important;
}

.activeWrongCls {
    background-color: #F06150 !important;
    color: #FFFFFF !important;
}

li.sortable-sat {
    margin: 0;    
    .card {
        // border-radius: 16px;
        border-radius: 5px;
        padding: 0;
        width: 100%;
        .card-content {    
            width: 100%;  
            &.users_sec  {
                position: relative;
                width: 100%;  
                & > li {
                    margin: 0;
                }
                .home-sat-item {
                    width: 100%;
                }
                .sortable-handle {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 30px !important;
                    z-index: 10;
                    & + .home-sat-item {
                        .satView_header {
                            padding: 10px 70px;
                        }
                    }
                }
                .sat_title {
                    color: #333;
                    display: inline-block;
                    font-size: 20px;
                    line-height: 1.3;
                    margin: 10px 0;
                    width: 100%;
                }
            }            
            .satView_header {
                padding: 10px 70px 10px 30px;
                position: relative;
                width: 100%;
                .icon {
                    align-items: center;
                    background-color: rgba(2, 59, 78, 0.15);
                    // border-radius: 11px;
                    border-radius: 5px;
                    display: inline-flex;
                    height: 32px;
                    justify-content: center;
                    overflow: hidden;
                    position: absolute;
                    right: 15px;
                    text-indent: 200px;
                    white-space: nowrap;
                    width: 32px;

                    &::after {
                        background-color: rgba(0, 0, 0, 0);
                        background-image: url("../../images/ic-chevron-downx32.png");
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        // border-radius: 11px;
                    border-radius: 5px;
                        content: '';
                        display: inline-block;
                        height: 100%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                    }
                }
                &.open {                    
                    .icon {
                        transform: rotate(180deg);
                    }
                }
                .hide-menu {
                    margin-right: auto;
                }
                .hdr_real_test_switch {
                    display: flex;
                    margin: 0;
                    justify-content: center;

                    & > div {
                        .react-switch-handle {
                            background-color: #f1f1f1 !important;
                            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
                        }
                    }
                    .header-div {
                        display: block;
                        align-self: center;
                    }
                }
                .hdr_switch {
                    display: flex;
                    margin: 0;
                    & > div {
                        // .react-switch-bg {
                        //     border-radius: 100px !important;
                        //     height: 32px !important;
                        //     width: 85px !important;
                        // }
                        .react-switch-handle {
                            background-color: #f1f1f1 !important;
                            box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12) !important;
                            // width: 24px !important;
                            // height: 24px !important;
                            // top: 4px !important;
                        }
                        .swtch_txt {
                            align-items: center;
                            color: #fff;
                            display: inline-flex;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            height: 100%;
                            justify-content: flex-start;
                            line-height: 1.5;
                            padding: 0 0 2px;
                            width: 100%;
                            &.checked {
                                justify-content: flex-end;
                            }
                        }
                    }
                    & > div {
                        padding: 0 50px 0 40px;
                        // border: 1px solid red;
                        .react-switch-bg {
                            position: static !important;
                            & > div {
                                opacity: 1 !important;
                                position: absolute !important;
                                color: red !important;
                                left: 0;
                                right: auto !important;
                                width: auto !important;

                                & > .swtch_txt {
                                    &.checked {
                                        color: rgb(0, 199, 137);
                                    }
                                    &.unchecked {
                                        color: rgb(240, 97, 80);
                                    }
                                }
                                &:last-child {
                                    left: auto;
                                    right: 0 !important;
                                }
                            }
                        }
                    }

                    label {
                        color: #333;
                        display: none;
                        font-size: 14px;
                        font-weight: 300 !important;
                        line-height: 32px;
                        margin: 0 0 0 10px !important;
                    }
                }
                .switch_ele {
                    & > .switch_txt {
                        font-size: 14px;
                        font-weight: 300;
                        color: #333;
                        line-height: 32px;
                    }
                    label {
                        margin: 0 !important;
                        position: relative;
                        .lever {
                            background-color: #F3F3F3;
                            background-color: #F06150;
                            border-radius: 100px;
                            width: 85px;
                            height: 32px;
                            margin: 0;

                            &::after {
                                top: 4px;
                                left: 4px;
                                width: 24px;
                                height: 24px;
                            }
                            &::before {
                                top: 4px;
                                left: 4px;
                                width: 24px;
                                height: 24px;
                                background-color: rgba(92, 219, 149, .15);
                            }
                        }
                        input[type="checkbox"] {
                            &:checked+.lever {
                                background-color: #00C789;
                                // background-color: #57D100 !important;
                                
                                &::after {
                                    background-color: #ffffff;
                                    left: 42px;
                                    left: 57px;
                                }
                                &::before {
                                    left: 42px;
                                    left: 57px;
                                }
                            }
                            &:checked+.lever+.stch_lbl.yeslbl {
                                color: #fff;
                                left: 10px;
                                right: auto;
                                opacity: 1;
                                -webkit-transform: scale(1);
                                -ms-transform: scale(1);
                                transform: scale(1);
                            }
                            &:checked+.lever+.yeslbl+.stch_lbl.nolbl {
                                opacity: 0;
                                -webkit-transform: scale(0);
                                -ms-transform: scale(0);
                                transform: scale(0);
                            }
                        }
                        .stch_lbl {
                            color: #F06150;
                            color: #ffffff;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 1.3;
                            line-height: 32px;
                            margin: 0;
                            position: absolute;
                            right: 10px;
                            top: 0;
                            -webkit-transition: all 0.25s ease;
                            -moz-transition: all 0.25s ease;
                            -ms-transition: all 0.25s ease;
                            -o-transition: all 0.25s ease;
                            transition: all 0.25s ease;
                        }
                        .stch_lbl.yeslbl {
                            opacity: 0;
                        }
                    }
                    input[type="checkbox"] {
                        &:checked {
                            &:not(:disabled).tabbed {
                                &:focus~.lever {
                                    &::before {
                                        -webkit-transform: scale(2.4);
                                        -ms-transform: scale(2.4);
                                        transform: scale(2.4);
                                        background-color: rgba(136, 136, 136, .15);
                                    }
                                }
                            }
                            &:not(:disabled)~.lever {
                                &:active {
                                    &::before {
                                        -webkit-transform: scale(2.4);
                                        -ms-transform: scale(2.4);
                                        transform: scale(2.4);
                                        background-color: rgba(136, 136, 136, .15);
                                    }
                                }
                            }
                        }
                    }
                }     
            }
            .divider-bottom {
                border-bottom: 1px solid #AFAFAF;
            }
            .selectButtonsCls {
                border-top: 1px solid #AFAFAF;
                display: flex;
                margin: 0;
                padding: 20px 30px;
                width: 100%;
                .upload-document-span {
                    width: 100%;
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                }
                .upload-file-div {
                    margin-top: 10px;
                }
                .btn_grup {
                    display: inline-flex;
                    order: 2;
                    padding-left: 30px;
                    .btn  {
                        margin: 5px 20px 5px 0;
                        white-space: nowrap;
                        // &:first-child {
                        //     margin-left: 0;
                        // }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .overAllScoreCls  {
                    background: #F2F5FA;
                    // border-radius: 16px;
                    border-radius: 5px;
                    box-shadow: none;
                    // flex-wrap: wrap;
                    margin-left: 0 !important;
                    margin-right: auto;
                    order: 1;
                    padding: 6px 20px !important;
                    .scoresVal {
                        display: inline-flex;
                        flex-wrap: wrap;
                    }
                    & > span {                        
                        &.title {
                            flex: 0 0 80px;
                            color: #023B4E;
                            font-size: 16px;
                            line-height: 1.3;
                            margin-left: 0;
                            position: relative;
                            margin-right: 40px;
                        }
                    }
                    .scoresVal > span {
                        background: rgba(0,0,0,0);
                        color: #F06150;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: inherit;
                        margin: 5px 30px 5px 0;
                        padding: 0;
                        white-space: nowrap;

                        &:last-child {
                            margin-right: 0;
                        }
                        b {
                            color: #333333;
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .setTopBorder {
                border-top: 1px solid #AFAFAF;
                width: 100%;
            }

            .setBottomBorder {
                padding-top: 20px;
                border-bottom: 1px solid #AFAFAF;
            }

            .marginBottom20 {
                margin-bottom: 20px !important;
            }

            .selectButtonsClass {
                //display: inline-flex;
                margin: 0;
                width: auto;
                .btn_grup {
                    display: inline-flex;
                    order: 2;
                    padding-left: 10px;
                    .btn  {
                        margin: 5px 20px 5px 0;
                        white-space: nowrap;
                        // &:first-child {
                        //     margin-left: 0;
                        // }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .overAllScoreCls  {
                    //background: #F2F5FA;
                    // border-radius: 16px;
                    border-radius: 5px;
                    box-shadow: none;
                    // flex-wrap: wrap;
                    margin-left: 0 !important;
                    margin-right: auto;
                    order: 1;
                    padding: 6px 4px !important;
                    .scoresVal {
                        display: inline-flex;
                        flex-wrap: nowrap;
                    }
                    & > span {
                        &.title {
                            flex: 0 0 80px;
                            color: #023B4E;
                            font-size: 16px;
                            line-height: 1.3;
                            margin-left: 0;
                            position: relative;
                            margin-right: 40px;
                        }
                    }
                    .scoresVal > span {
                        background: rgba(0,0,0,0);
                        color: #F06150;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: inherit;
                        margin: 5px 5px 5px 0;
                        padding: 0;
                        white-space: nowrap;

                        &:last-child {
                            margin-right: 0;
                        }
                        b {
                            color: #333333;
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .questions {
                transition: .3s ease all !important;
                padding: 30px;
                padding-top: 20px;
                width: 100%;

                .ques_set {
                    margin-bottom: 40px;
                    width: 100%;
                    &:last-child {
                        margin-bottom: 0;

                        & > .mockEnabledCls {
                            margin-bottom: 0;
                        }
                    }
                    .satQuesIdTitleCls {
                        color: #333;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.3;
                        margin: 0;

                        .scoreOfHeadersCls {
                            color: #00C789;
                            // color: #57D100;
                            font-size: inherit;
                            font-weight: 500;
                            padding-left: 10px;
                            margin: 0 !important;
                        }
                        .btn {
                            align-items: center;
                            // border-radius: 11px;
                            border-radius: 5px;
                            display: inline-flex;
                            font-size: 14px;
                            font-weight: 600;
                            height: 38px;
                            justify-content: center;
                            line-height: 1.3;
                            margin-right: 15px;
                            padding: 5px 20px;

                            &:last-child{
                                margin-right: 0;
                            }

                            &.btn-success {
                                background-color: #00C789;                                
                                // background-color: #57D100 !important;
                                box-shadow: 0 6px 187px rgba(0, 199, 137, .45);
                            }

                            &.btn-danger {
                                background-color: #F06150;
                                // box-shadow: 0 6px 187px rgba(240, 97, 80, .45);
                            }
                        }
                    }
                    & > ul.bubble-sheet-ul {
                        display: block !important;
                        flex-wrap: nowrap !important;
                        -moz-column-gap: 5px;
                        -webkit-column-gap: 5px;
                        li.bubbleSheet {
                            -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
                            page-break-inside: avoid; /* Firefox */
                            break-inside: avoid;
                            align-items: center;
                            color: #333;
                            display: flex !important;
                            font-weight: 500;
                            justify-content: center;
                            outline: none;
                            padding: 0;
                            position: relative;
                            border: none !important;
                            font-size: 14px;
                            height: 40px;
                            margin: 0 5px 5px 0;
                            width: auto;
                            & > span.options {
                                cursor: pointer;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                color: #21cec6;
                                border: solid 1px #21cec6;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            & > span.selected-bubble {
                                color: #0a6aa1 !important;
                                border: solid 1px #0a6aa1 !important;
                                background-color: #21cec6;
                            }
                            & > div.free-response-input-span {
                                margin-left: 10px;
                                bottom: 0;
                                vertical-align: bottom;
                                input {
                                    width: 110px;
                                    background-color: transparent;
                                    border-bottom: 1px solid #0a0a0a !important;
                                    color: #0a0a0a !important;
                                    vertical-align: bottom;
                                    box-sizing: border-box;
                                    height: 30px;
                                    font-size: 14px;
                                }
                                input:focus {
                                    border-bottom: 1px solid #0a0a0a !important;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    & > ul {
                        padding: 0;
                        li {
                            align-items: center;
                            border: 1px solid #707070;
                            color: #333;
                            display: inline-flex;
                            font-size: 16px;
                            font-weight: 400;
                            height: 47px;
                            justify-content: center;
                            margin-left: -1px;
                            margin-bottom: 0;
                            margin-top: 20px;
                            outline: none;
                            padding: 0;
                            position: relative;
                            width: 40px;

                        }
                    }
                    & > .mockEnabledCls {
                        margin-top: 20px;
                        padding: 30px;
                    }
                }
                 ul.bubble-sheet-ul {
                     padding-top: 10px !important;
                     border-top: 1.5px solid #E7EAEF !important;
                }
                .mockEnabledCls {
                    background: #EFEFEF;
                    // border-radius: 11px;
                    border-radius: 5px;
                    margin-bottom: 30px;
                    padding: 30px;
                    width: 100%;
                    &:last-child {
                        & > .mockEnabledCls {
                            margin-bottom: 0;
                        }
                    }
                    .mockEnabledCls, ul {
                        margin: 25px 0 0;
                        padding: 0;
                    }
                }                
                .satTypeProgressCls {
                    
                    width: 25%;
                    .progress {
                        background: #DBE0E9;
                        border-radius: 100px;
                        height: 20px;
                        margin: 0;
                        // overflow: visible;
                        width: 100%;
                        span {
                            background-color: rgba(0,0,0,0);
                            color: #023B4E;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 20px;
                            z-index: 10;
                        }
                        .progress-bar {
                            background-color: #00C789;
                            // background-color: #57D100 !important;
                            border-radius: 100px;
                            // box-shadow: 0 6px 18px rgba(0, 199, 137, .45);
                            height: 100%;
                            left: 0;
                            position: absolute;
                            top: 0;
                            z-index: 9;
                        }
                    }
                }
            }
        }
    }
}

.satAct_content_sec {
    .add-sat {
        .newActForm  {
            h3 {
                display: inline-block;
                margin-right: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
    .home-sat-item {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    } 
    .sortable-home-sat-list, .sortable-admin-sat-list {
        & > li {
            margin: 0;
        }
    }  
    li.sortable-sat { 
        .card {
            // border-radius: 16px;
            border-radius: 10px;
            padding: 0;
            .card-content {        
                &.users_sec  {
                    position: relative;
                    & > li {
                        margin: 0;
                    }
                }            
                .satView_header {
                    &.open {                    
                        .icon {
                            transform: rotate(180deg);
                        }
                    }
                    .hide-menu {
                        margin-right: 30px;
                    }   
                }
            }
        }

    }
}

.assignment_sec {
    .manage_inn {
        // width: 100%;
        & > table {
            margin: 0 !important;
        }
        // &.ps {
        //     margin: 0 !important;
        //     padding: 0 !important;
        // }
    }
    table {
        th, td {
            padding: 10px;
        }
        th {
            background-color: #FCFCFC;
            border: none;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
            vertical-align: middle;
            white-space: nowrap;
            
            &:first-child, &:last-child {
                &::before {
                    content: none;
                }
            }
            &:last-child {
                text-align: right;
            }
        }
        tbody {
            td {
                font-weight: 300;
                a {
                    font-weight: 400;
                }                
                i.checkMarkTrue {
                    align-items: center;
                    background-color: rgba(0, 199, 137, 0.13);
                    border-radius: 5px;
                    color: #00C789;
                    // color: #57D100;
                    display: inline-flex;
                    height: 32px;
                    justify-content: center;
                    margin-left: 5px;
                    position: relative;
                    width: 32px;
                }
            }            
            td:last-child {
                padding-right: 0 !important;
                max-width: 150px;
                width: 150px;
                text-align: right;
            }
        }
    }
}

.dashGrapg_sec {
    max-width: 100%;
    width: 100%;
    .card-header {
        .section_title + div.d-flex {
            width: auto !important;
            & > * {
                width: auto !important;
                margin: 5px 10px;
                &:last-child {
                    margin-right: 0;
                }
            }            
            .graphArrowsControl {
                padding-left: 15px;
                margin: 0;
                align-items: center;

                .btn {
                    align-items: center;
                    background-color: rgba(129, 129, 129, 0.07);
                    // border-radius: 11px;
                    border-radius: 5px;
                    display: inline-flex;
                    height: 40px;
                    justify-content: center;
                    padding: 0;
                    position: relative;
                    width: 40px;

                    &.mr-1 {
                        margin-right: 10px !important;
                    }

                    &::after {
                        content: none;
                    }

                    i.fa {
                        background-color: rgba(0, 0, 0, 0);
                        background-image: url('../../images/ico-arrow-down-sm.png');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        display: inline-block;
                        height: 9px;
                        margin: 0 !important;
                        width: 15px;

                        &::before {
                            content: none;
                        }
                        
                        &.fa-arrow-right {
                            transform: rotate(-90deg);
                            right: -2px;
                        }                            
                        &.fa-arrow-left {
                            transform: rotate(90deg);
                            left: -2px;
                        }
                    }
                    
                    &:hover, &:focus {
                        i.fa {
                            background-image: url('../../images/collapse_arrow_active.png');

                            &.fa-arrow-right {
                                transform: rotate(90deg);
                                right: -1px;
                            }                            
                            &.fa-arrow-left {
                                transform: rotate(-90deg);
                                left: -1px;
                            }
                        }
                    }
                }
            }
        }
        .css-yk16xz-control, .css-1pahdxg-control {
            background-color: #F8F9FB;
            border-radius: 12px;
            border-radius: 5px;
            border: 1px solid #E7EAEF;
            box-shadow: none;
            min-height: 40px;
            height: 40px;

            .css-g1d714-ValueContainer {
                border: none;
                box-shadow: none;
                box-sizing: border-box;
                color: #333;
                font-size: 14px;
                font-weight: 500;
                height: 100%;
                letter-spacing: 0;
                margin: 0;
                min-height: initial;
                min-width: initial;
                padding: 2px 15px;
                width: 130px;
                width: 95px;
                .css-1uccc91-singleValue, .css-b8ldur-Input {
                    display: inline-flex;
                    align-items: center;
                    line-height: 100%;
                    height: 100%;
                }
            }
            .css-1hwfws3 {
                border: none;
                box-shadow: none;
                box-sizing: border-box;
                color: #333;
                font-size: 14px;
                font-weight: 500;
                height: 100%;
                letter-spacing: 0;
                margin: 0;
                min-height: initial;
                min-width: initial;
                padding: 2px 15px;
                width: 95px;
                .css-1uccc91-singleValue, .css-b8ldur-Input {
                    display: inline-flex;
                    align-items: center;
                    line-height: 100%;
                    height: 100%;
                }
            }
            .css-1hb7zxy-IndicatorsContainer {
                & > .css-1okebmr-indicatorSeparator + div {
                    background-color: rgba(2, 59, 78, 0.15);
                    background-image: url("../../images/ic-chevron-downx32.png");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    // border-radius: 11px;
                    border-radius: 5px;
                    display: inline-block;
                    fill: transparent;
                    height: 32px;
                    opacity: 1;
                    margin: 0 4px;
                    width: 32px;

                    & > svg {
                        display: none;
                    }
                }
            }
            .css-1wy0on6 {
                & > .css-1okebmr-indicatorSeparator + div {
                    background-color: rgba(2, 59, 78, 0.15);
                    background-image: url("../../images/ic-chevron-downx32.png");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    // border-radius: 11px;
                    border-radius: 5px;
                    display: inline-block;
                    fill: transparent;
                    height: 32px;
                    opacity: 1;
                    margin: 0 4px;
                    width: 32px;

                    & > svg {
                        display: none;
                    }
                }
            }
            & + div {
                border-radius: 15px;
                border: 1px solid #ECECEC;
                box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
                left: auto !important;
                min-width: 150px;
                overflow-x: hidden;
                // right: -2px !important;
                // top: -2px !important;
                li, a {
                    clear: both;
                    color: rgba(0,0,0,.87);
                    cursor: pointer;
                    line-height: 1.5rem;
                    min-height: 50px;
                    text-align: left;
                    width: 100%;
                    &.selected, &.active {
                        background-color: rgba(0,0,0,.03);
                    }
                }
            }
        }
    }
    .graph_container {
        // max-width: 100%;
        // width: 100%;
        .graph_wrap {
            display: flex;
            flex-wrap: wrap;
            & > canvas {
                order: 2;
                & + div {
                    order: 1;
                    justify-content: flex-end !important;
                    padding: 15px 0;

                    .btn {
                        align-items: center;
                        background-color: rgba(129, 129, 129, 0.07);
                        // border-radius: 11px;
                        border-radius: 5px;
                        display: inline-flex;
                        height: 32px;
                        justify-content: center;
                        padding: 0;
                        position: relative;
                        width: 32px;

                        &::after {
                            content: none;
                        }

                        i.fa {
                            background-color: rgba(0, 0, 0, 0);
                            background-image: url('../../images/ico-arrow-down-sm.png');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            display: inline-block;
                            height: 9px;
                            margin: 0 !important;
                            width: 15px;

                            &::before {
                                content: none;
                            }
                            
                            &.fa-arrow-right {
                                transform: rotate(-90deg);
                                right: -2px;
                            }                            
                            &.fa-arrow-left {
                                transform: rotate(90deg);
                                left: -2px;
                            }
                        }
                        
                        &:hover, &:focus {
                            i.fa {
                                background-image: url('../../images/collapse_arrow_active.png');

                                &.fa-arrow-right {
                                    transform: rotate(90deg);
                                    right: -1px;
                                }                            
                                &.fa-arrow-left {
                                    transform: rotate(-90deg);
                                    left: -1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mgr_sec {
    padding: 30px;
    & > .sortable-topics {
        display: flex;
        width: 100%;
        & > ul {
            width: 100%;
            & > li {
                padding: 10px 0 0 0;
                &:first-child {
                    padding-top: 0;
                }
            }
        }
        .sortable-home-topic-list {
            & > li {
                &:first-child {
                    .home-topic {
                        margin-top: 0;
                    }    
                    & > .home-topic {    
                        &.open {
                            margin-top: 0;
                        }
                    }                
                }
                &:last-child {
                    .home-topic {
                        margin-bottom: 0;
                    }       
                    & > .home-topic {    
                        &.open {
                            margin-bottom: 0;
                        }
                    }                  
                }
                & > .home-topic {
                    border-color: #CBCBCB;
                    border-style: solid;
                    border-width: 0 0 1px;
                    margin: 10px 0;
                    margin: 0;
                    // padding: 20px 30px 20px 92px;
                    padding: 0;
                    position: relative;
                    width: 100%;

                    &.open {
                        background-color: #F8F8F8;
                        border-radius: 19px;
                        border: none;
                        // margin-bottom: 20px;
                        // margin-top: 20px;
                        & > .topicTitle + div {    
                            padding: 0 30px 20px 92px;
                            &::before {
                                display: inline-block;
                            }
                        }
                        .open {
                            background-color: #fff;
                        }
                        .topicTitle {    
                            &::before {
                                transform: rotate(180deg);
                            }
                            .ico_arrow {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    
                    & > .topicTitle {
                        color: #023B4E;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 10px 30px 10px 92px;
                        margin: 0 0 10px;
                        position: relative;
                        width: 100%;
                        display: flex;
                        align-items: center;

                        // &::before {
                        //     background-color: rgba(0, 0, 0, 0);
                        //     background-image: url('../../images/ico-ios-add-circle.png');
                        //     background-image: url('../../images/icon-select-arrow.png');
                        //     background-position: center center;
                        //     background-repeat: no-repeat;
                        //     background-size: 100% auto;
                            // border-radius: 11px;
                            // border-radius: 5px;
                        //     content: '';
                        //     display: inline-block;
                        //     height: 26px;
                        //     left: 30px;
                        //     opacity: 1;
                        //     position: absolute;
                        //     top: 12px;
                        //     width: 26px;
                        // }
                        .ico_arrow {
                            background-color: rgba(0, 0, 0, 0);
                            background-image: url('../../images/icon-select-arrow.png');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            // border-radius: 11px;
                            border-radius: 5px;
                            content: '';
                            display: inline-block;
                            height: 26px;
                            left: 33px;
                            opacity: 1;
                            position: absolute;
                            top: 12px;
                            width: 26px;
                        }
                        .titleTxt {
                            margin-right: auto;
                            position: relative;
                        }
                    }
                    & > .topicTitle + div {
                        position: relative;

                        &::before {
                            background-color: #B8B8B8;
                            // border-radius: 11px;
                            border-radius: 5px;
                            content: '';
                            display: none;
                            height: calc(100% - 20px);
                            left: 45px;
                            opacity: 1;
                            position: absolute;
                            top: 0;
                            width: 1px;
                        }

                        & > .sortable-topics {
                            & > .sortable-home-topic-list {
                                li {
                                    .home-topic {
                                        font-size: 16px;
                                        border: none;
                                        border-radius: 22px;
                                        box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
                                        margin-bottom: 10px;
                                        padding: 15px 30px;
                                        .subtopicHeader {
                                            display: flex;
                                            align-items: center;
                                            padding-right: 40px;
                                            position: relative;
                                            width: 100%;
                                            .icon {
                                                align-items: center;
                                                background-color: rgba(129, 129, 129, 0.07);
                                                // border-radius: 11px;
                                                border-radius: 5px;
                                                display: inline-flex;
                                                height: 32px;
                                                justify-content: center;
                                                overflow: hidden;
                                                position: absolute;
                                                right: -15px;        
                                                // margin-left: auto;                                        
                                                // position: relative;
                                                text-indent: 200px;
                                                white-space: nowrap;
                                                width: 32px;

                                                &::after {
                                                    background-color: rgba(0, 0, 0, 0);
                                                    background-image: url('../../images/ico-arrow-down-sm.png');
                                                    background-position: center center;
                                                    background-repeat: no-repeat;
                                                    background-size: 15px 9px;
                                                    // border-radius: 11px;
                                                    border-radius: 5px;
                                                    content: '';
                                                    display: inline-block;
                                                    height: 32px;
                                                    opacity: 1;
                                                    position: absolute;
                                                    left: 50%;
                                                    width: 32px;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);
                                                }
                                            }
                                            // &::after {
                                            //     background-color: rgba(129, 129, 129, 0.07);
                                            //     background-image: url('../../images/ico-arrow-down-sm.png');
                                            //     background-position: center center;
                                            //     background-repeat: no-repeat;
                                            //     background-size: 15px 9px;
                                                // border-radius: 11px;
                                                // border-radius: 5px;
                                            //     content: '';
                                            //     display: inline-block;
                                            //     height: 32px;
                                            //     opacity: 1;
                                            //     position: absolute;
                                            //     right: -15px;
                                            //     width: 32px;
                                            //     top: 50%;
                                            //     transform: translateY(-50%);
                                            // }
                                            .sequenceBtn {
                                                margin: 0 15px;
                                                padding: 0;
                                            }
                                        }
                                        .subtopic-content {
                                            position: relative;
                                            &::before {
                                                background-color: #B8B8B8;
                                                // border-radius: 11px;
                    border-radius: 5px;
                                                content: '';
                                                display: none;
                                                height: 100%;
                                                left: 0;
                                                opacity: 1;
                                                position: absolute;
                                                top: 0;
                                                width: 1px;
                                            }
                                            .sortable-home-topic-list {
                                                li {
                                                    color: #515151;
                                                    display: inline-block;
                                                    font-size: 16px;
                                                    margin-bottom: 10px;
                                                    position: relative;
                                                    width: 100%;
                                                    &:last-child {
                                                        margin: 0;
                                                    }
                                                    &::before {
                                                        background-color: #B8B8B8;
                                                        // border-radius: 11px;
                    border-radius: 5px;
                                                        content: '';
                                                        display: inline-block;
                                                        height: 1px;
                                                        left: -30px;
                                                        opacity: 1;
                                                        position: absolute;
                                                        top: 12px;
                                                        width: 15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.open {                                            
                                            .subtopicHeader {
                                                .icon {
                                                    &::after {
                                                        background-image: url('../../images/collapse_arrow_active.png');
                                                    }
                                                }
                                                // &::after {
                                                //     background-image: url('../../images/collapse_arrow_active.png');
                                                //     // transform: rotate(180deg);
                                                // }
                                            }
                                            .subtopic-content {
                                                margin-top: 10px;
                                                padding: 0 0 0 30px;
                                                &::before {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


form.app_form {
    margin-top: 40px;
    & > .row, & > .form-row {
        margin: 0 -15px;
        &:last-child {
            margin-bottom: -30px;
        }
    }
    .form-group {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        margin-bottom: 30px;
        padding: 0 15px;

        label {
            color: #333333;
            font-size: 16px;
            line-height: 1.3;
            margin: 0 0 5px;
            position: relative;
            width: 100%;

            .rqrd_str {
                color: #FD6600;
            }
        }
        input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
            background-color: #ffffff;
            border: 1px solid #AFAFAF;
            // border-radius: 16px;
            border-radius: 5px;
            box-shadow: none;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            height: 50px;
            line-height: 1.3;
            margin: 0;
            max-width: 100%;
            padding: 5px 30px;
            position: relative;
            transition: all 0.25s ease;
            width: 100%;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-weight: 400;
                opacity: 1;
                color: #7f7f7f;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                font-weight: 400;
                opacity: 1;
                color: #7f7f7f;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                font-weight: 400;
                opacity: 1;
                color: #7f7f7f;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                font-weight: 400;
                opacity: 1;
                color: #7f7f7f;
            }

            &:hover {
                border-color: darken(#999999, 20%);
            }

            &:focus {
                border-color: #023b4e;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
            }

            &.num_text {
                font-weight: 400;
            }
        }
        .chips {
            flex-wrap: wrap;
        }
        &.btn-grup {
            justify-content: flex-end;
            
            .btn-default {
                margin-right: 30px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.dialog {
    border-radius: 10px;
    overflow: hidden;
    padding: 50px;

    .btn-default::after, .btn:not(.btn-default)::after {
        content: none;
    }

    .close.svg-container {
        align-items: center;
        display: inline-flex;
        height: 40px;
        justify-content: center;
        left: auto;
        opacity: .5;
        position: absolute;
        right: 50px;
        stroke: black;
        top: 50px;
        width: 40px;

        & > svg {
            width: 25px;
            height: 25px;
            stroke-width: 1.5px;
        }

        &:hover {
            opacity: .5;
        }
    }

    .header {
        background-color: #ffffff;
        color: #000;
        font-size: 24px;
        font-weight: 600;
        justify-content: flex-start;
        line-height: 1.3;
        margin: 0 0 30px;
        padding: 3px 0;
        padding: 3px 50px 3px 0;
        text-align: left;
        border-bottom: 2px solid #eaeaea;
        padding-bottom: 30px;
        text-align: center;
        justify-content: center;
    }

    .body {
        overflow-y: initial;
    }
    #perfectScroll {
        max-height: calc(100vh - 340px);
        position: relative;
        width: auto;
        margin: 0 -30px;
        padding: 0 30px;
    }

    &.problem-set-dialog {
        // max-width: 675px;
        max-width: 1009px;
        overflow-x: hidden;
        overflow-y: auto;
        .admin-problemSet-popup {
            padding: 0;
            & > .row {
                // flex-direction: column;
                //margin: 0 -30px;
                // & > div {
                //     flex: 0 0 100%;
                //     max-width: 100%;
                // }
                .leftSideOfPopupCls, .rightSideOfPopupCls  {
                    padding: 0 30px;
                }
            }
        }
        .leftSideOfPopupCls {
            // background: #F2F5FA;
            // padding: 30px;
            background: rgba(0, 0, 0, 0);
            position: relative;
            // border-radius: 22px;      
            .master_hdr {
                .titleWrap {
                    .masterTitleCls {
                        color: #333333;
                        font-size: 20px;
                        font-weight: 600;
                        // margin-bottom: 10px;
                        // margin-top: 10px;
                    }
                    & > span {
                        margin: .5rem 0;
                        button {
                            margin-right: 20px;
                        }

                    }
                    .btnGroup {
                        width: auto;
                        // & > .btn, & > span {
                        //     margin: 5px 10px !important;

                        //     &:last-child {
                        //         margin-right: 0 !important;
                        //     }
                        //     &:first-child {
                        //         margin-left: 0 !important;
                        //     }
                        //     &:empty {
                        //         padding:0;
                        //     }
                        // }
                        & > span  {
                            margin: 0 !important;
                        }
                        & > span > .btn, & > .btn {
                            margin: 5px 10px !important;
                        }
                        & > span:last-child > .btn, & > .btn:last-child {
                            margin-right: 0 !important;
                        }
                        & > span:first-child > .btn, & > .btn:first-child {
                            margin-left: 0 !important;
                        }
                    }
                }
                .progressAndResetDivCls {
                    margin-top: 45px;
                    min-height: initial;
                }
            }
            .videoExpCls {
                & > .title {
                    color: #333333;
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0 0 15px 0 !important;
                }
                .videoSectionCls {
                    .adjustVideoViewCls {
                        width: 100%;
                        .public-DraftStyleDefault-block {
                            font-size: 16px;
                            font-weight: 500;
                            // margin: 15px 0 !important;
                        }
                    }
                    figure {
                        margin: 0;
                        & > div {
                            width: 100% !important;
                            
                            & > div {
                                position: relative;
                                overflow: hidden;
                                width: 100%;
                                padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
                                & > iframe {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                        }
                    }
                }
            }      
            .progressAndResetDivCls {
                .progress {
                    background: #DBE0E9;
                    border-radius: 100px;
                    height: 30px;
                    margin: 0;
                    overflow: visible;
                    width: 100%;
                    span {
                        background-color: rgba(0,0,0,0);
                        color: #023B4E;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        z-index: 10;
                    }
                    .progress-bar {
                        background-color: #00C789;
                        // background-color: #57D100;
                        border-radius: 100px;
                        // box-shadow: 0 6px 18px rgba(0, 199, 137, .45);
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        z-index: 9;
                    }
                }
            }
        }
        .rightSideOfPopupCls  {
            margin-top: 0 !important;
            h5 {
                color: #333333;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.3;
                margin: 0 0 15px;
            }
            .sortable-admin-topic-list {
                & > li {
                    //background: #F8F8F8;
                    border-radius: 10px;
                    margin-bottom: 15px;
                    padding: 10px 15px;

                    .problemsetTitleCls {
                        color: #333;
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .text-secondary {
                        color: #333 !important;
                        display: inline-block;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        width: 100%;
                    }
                    .psIconCls {
                        border: 2px solid #00C789;
                        // border-color: #57D100;
                        display: inline-block;
                        height: 32px;
                        width: 16px;
                        position: relative;
                        margin: 5px;
                        &.active {
                            background-color: #00C789;
                            // background-color: #57D100;
                        }
                    }
                    .problemsetDataCls {
                        .problemsetRightDataCls {
                            border-color: #C8C8C8;
                            flex: 0 0 180px;
                            max-width: 180px;
                            width: 180px;
                            padding-left: 15px;

                            i.checkMarkTrue {
                                align-items: center;
                                background-color: rgba(0, 199, 137, 0.13);
                                border-radius: 5px;
                                color: #00C789;
                                // color: #57D100;
                                display: inline-flex;
                                height: 32px;
                                justify-content: center;
                                margin-left: auto !important;
                                position: relative;
                                width: 32px;
                            }
                        }
                    }
                    &.sortableModal_listele {
                        .admin-subtopic {
                            background-color: rgba(0, 0, 0, 0);
                            border-radius: 0;
                            border: none;
                            margin-bottom: 0;
                            padding: 0 !important;
                            .problemsetDataCls  {
                                width: 100%;
                                .problemsetLeftDataCls {
                                    padding-right: 15px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .videoExpCls {
        margin : 0 !important;
    }
    .view-question--inner {
        padding: 0;
        width: 100%;

        figure {
            margin: 0;
            & > div {
                width: 100% !important;
            }
        }
        .draftJsMentionPlugin__iframeContainer__21EVZ {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
            & > iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.question_sec {
    min-height: calc(100vh - 258px);
    
    .quesHeader {
        border-bottom: 2px solid #eaeaea;
        padding-bottom: 30px;
        margin-bottom: 30px;

        .quesTitle {
            font-size: 26px;
            font-weight: 600;
            line-height: 1.3;
            position: relative;
            text-align: center;
            // text-transform: capitalize;
            width: 100%;
        }
    }
    .home-questions {
        margin: 0 auto;
        padding: 0;
        max-width: 100%;
        width: 770px;
        width: 690px;

        .view-question--inner {
            padding: 0;
            figure {
                margin: 0;
            }
            
            figure {
                margin: 0;
                & > div {
                    width: 100% !important;
                    // draftJsMentionPlugin__iframeContainer__21EVZ draftJsFocusPlugin__unfocused__1Wvrs
                    & > .draftJsMentionPlugin__iframeContainer__21EVZ {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
                        & > iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
            .btn:not(.btn-default) {
                min-width: 150px;
            }
            .question-explanation {
                position: relative;
                & > .btn.btn-default {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 50px;
                    z-index: 5;
                }
                .explanationContent {
                    background-color: #ffffff;
                    border-radius: 19px;
                    border: 1px solid #E2E2E2;
                    flex-wrap: wrap;
                    padding-bottom: 15px;
                    position: relative;
                    z-index: 1;

                    .expl_title  {
                        font-size: 22px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid #E2E2E2;
                        padding-bottom: 15px;
                        color: #333;
                    }
                    & > .cell {
                        &:first-child, &.ico_cell {
                            display: none !important;
                        }
                        &:last-child {
                            max-width: 100%;
                            padding: 0 !important;
                            & > div {
                                width: 100%;
                            }
                        }
                    }
                    .cell {
                        &.ico_cell {
                            display: none !important;
                        }
                    }
                }
            }
            // .student-section-editor-container {
            //     .public-DraftStyleDefault-ltr {
            //         font-size: 16px;
            //     }
            // }
            .view-question--ans {
                ul {
                    li {
                        margin: 10px 0;
                        border-width: 1px !important;
                        border-color: #E2E2E2;
                        // border-radius: 16px;
                        border-radius: 5px;
                        &.wrongAns {
                            background-color: rgba(240, 97, 80, .2);
                            border-color: #F06150;
                        }
                        &.rightAns {
                            background-color: rgba(0, 199, 137, .2);
                            border-color: #00C789;
                            // border-color: #57D100;
                        }
                        &.selectedRightAns {
                            background-color: rgba(0, 199, 137, .2);
                            border-color: #00C789;
                        }
                        &.selectedWrongAns {
                            background-color: rgba(240, 97, 80, .2);
                            border-color: #F06150;
                        }
                    }
                }

                .free-response-answer-cls {
                    margin: 0 auto;
                    justify-content: center;
                    .freeAnsCls, .wrongFreeAns, .rightFreeAns {
                        max-width: 540px;
                        justify-content: center;

                        .answer-input-div {
                            //background-color: #0bbd84;
                            align-items: center;
                            display: flex;
                            justify-content: center;

                            input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
                                background-color: #ffffff;
                                border: 1px solid #AFAFAF;
                                border-radius: 16px 0 0 16px;
                                box-shadow: none;
                                box-sizing: border-box;
                                font-size: 16px;
                                font-weight: 400;
                                height: 50px;
                                line-height: 1.3;
                                margin: 0;
                                padding: 5px 30px;
                                transition: all 0.25s ease;

                                &::-webkit-input-placeholder {
                                    /* Chrome/Opera/Safari */
                                    font-weight: 400;
                                    opacity: 1;
                                    color: #999999;
                                }

                                &::-moz-placeholder {
                                    /* Firefox 19+ */
                                    font-weight: 400;
                                    opacity: 1;
                                    color: #999999;
                                }

                                &:-ms-input-placeholder {
                                    /* IE 10+ */
                                    font-weight: 400;
                                    opacity: 1;
                                    color: #999999;
                                }

                                &:-moz-placeholder {
                                    /* Firefox 18- */
                                    font-weight: 400;
                                    opacity: 1;
                                    color: #999999;
                                }

                                &:hover {
                                    border-color: darken(#C8C8C8, 20%);
                                }

                                &:focus {
                                    border-color: #023b4e;
                                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
                                }

                                &.num_text {
                                    // font-family: $secondary-font;
                                    font-weight: 400;
                                }
                            }
                            .symbolNotPresent {
                                border-radius: 16px !important;
                            }

                            .leftSideSymbol {
                                border-radius: 0 16px 16px 0 !important;
                            }

                            .rightSideSymbol {
                                border-radius: 16px 0 0 16px !important;
                            }
                        }


                        .answer-symbol-div {
                            .leftSideSymbol {
                                border-radius: 16px 0 0 16px;
                                margin: 0 !important;
                            }
                            .rightSideSymbol {
                                margin: 0 !important;
                                border-radius: 0 16px 16px 0;
                            }

                            .symbolCls {
                                align-items: center;
                                background: #d2d2d2;
                                display: flex;
                                height: 50px;
                                justify-content: center;

                                &:first-child {

                                    & + input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
                                        border-radius: 0 16px 16px 0;
                                    }
                                }
                            }
                        }
                    }

                    .symbol-icon {
                        padding-left: 5px;
                    }

                    .wrongFreeAns {
                        input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
                            border-color: #dc3545 !important;
                            background-color: rgba(0,0,0,0);
                        }
                        .symbolCls {
                            border: 1px solid #dc3545 !important;
                        }
                    }

                    .rightFreeAns {
                        input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
                            border-color: #4ea745 !important;
                            background-color: rgba(0,0,0,0);
                        }
                        .symbolCls {
                            border: 1px solid #4ea745 !important;
                        }
                    }
                }
            }
        }
    }
}

.appCus_pagination_limit {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    span.title {
        color: #000000;
        font-size: 16px;
    }

    div {
        margin-left: 5px;
        .limit-header {
            align-items: center;
            background-color: #f7f7f7;
            border-radius: 5px;
            color: #444444;
            border: 2px solid #AFAFAF;
            cursor: pointer;
            display: inline-flex;
            height: 35px;
            justify-content: center;
            padding: 0;
            text-align: center;
            text-decoration: none !important;
            width: 40px;
        }

        .limit-list-container {
            position: absolute;
            left: auto;
            right: 0;
            top: 100%;
            z-index: 999;
        }

        .limit-list {
            transition: opacity 1s ease-in;
            .limit-item {
                background-color: rgba(0, 0, 0, 0);
                height: auto;
                .limit-value {
                    align-items: center;
                    background-color: #ffffff;
                    border-left: 2px solid #AFAFAF;
                    border-right: 2px solid #AFAFAF;
                    border-bottom: 2px solid #AFAFAF;
                    cursor: pointer;
                    display: inline-flex;
                    height: 30px;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    text-decoration: none !important;
                    width: 40px;
                    &:hover {
                        background-color: #f7f7f7;
                        color: #444444;
                    }
                }
                &:first-child {
                    .limit-value {
                        border-top: 2px solid #AFAFAF;
                        border-radius: 5px 5px 0 0;
                    }
                }
                &:last-child {
                    .limit-value {
                        border-radius: 0 0 5px 5px;
                    }
                }
                &.active {
                    background-color: rgba(0, 0, 0, 0);
                    .limit-value {
                        color: #ffffff !important;
                        border: 1px solid #FD6600;
                        background: #FD6600;
                        font-weight: 500;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.appCus_pagination {
    width: 100%;
    & > div {
        margin: 15px 0 0 !important;
        width: 100%;
    }
    .visible-entries {
        color: #000000;
        font-size: 16px;
    }
    .pages {
        nav {
            background-color: rgba(0, 0, 0, 0);
            box-shadow: none;
            line-height: 1.3;
            height: initial;
        }
    }
    .pagination  {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        .page-item  {
            background-color: rgba(0, 0, 0, 0);
            height: auto;
            margin: 5px;

            .page-link {
                align-items: center;
                background-color: #ffffff;
                border-radius: 5px;
                border: 2px solid #AFAFAF;
                cursor: pointer;
                display: inline-flex;
                height: 35px;
                justify-content: center;
                margin: 0;
                padding: 0;
                text-align: center;
                text-decoration: none !important;
                // transition: all 0.45s ease;
                width: 35px;

                i.fa {
                    background-color: rgba(0, 0, 0, 0);
                    background-image: url('../../images/ico-pegination-left.png');
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    display: inline-block;
                    height: 24px;
                    position: relative;
                    width: 24px;
                    &.fa-angle-right {
                        background-image: url('../../images/ico-pegination-right.png');
                    }

                    &::before {
                        content: none;
                    }
                }
                &:hover {
                    border-color: #FD6600;
                    border-width: 2px;
                    i.fa {
                        background-position: 0 bottom;
                    }
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &.active {
                background-color: rgba(0, 0, 0, 0);
                .page-link {
                    color: #ffffff !important;
                    border: 1px solid #FD6600;
                    background: #FD6600;
                    font-weight: 500;
                    padding: 0;
                    // box-shadow: 0 7px 14px rgba(253, 102, 0, 0.3);
                }
            }
            &.disabled {
                opacity: 0.3;
                .page-link {
                    background-color: rgba(175, 175, 175, .3);
                }
            }
        }
    }
}

.loginPage_sec .login_form {
    background-color: #ffffff;
}

.loginPage_sec {
    margin-bottom: 50px;
    margin-top: 50px;
}

.popover-hide {
    input{
     width: auto !important;
    }
}

.question-editor .question-editor-show.hide {
    max-height: 100px;
    overflow: hidden;
    display: block !important;
}
.linked-list{
    list-style-type: disc !important;
    li{
        list-style-type: disc !important
    }
}

.sidenav-main {
    border-radius: 10px;
    width: 187px;
    // max-height: 550px !important;
    height: auto !important;
    max-height: calc(100vh - 136px) !important;
    ul#slide-out.sidenav {
        max-height: calc(100vh - 196px);
    }
    .sidenav {
        li {
            a {
                i.navico {
                    border-radius: 5px;
                }
            }
        }
    }
}

#main{
    padding-left: 187px;
    & > .row, & > div > .row {
        & > .s12 {
            & > .container { 
                max-width: 1064px !important;
            }
        }
    }
}

#tableScroll.ps{
    height: auto;
    max-height: 600px;
} 

.freeAnsCls{
    .symbolCls{
        padding: 6px !important;
        line-height: 21px !important;
        width: auto !important;
        font-size: 18px !important;
    }
}
// .satAct_content_sec .card .card-content .selectButtonsCls .overAllScoreCls {
//     padding-top: 12px !important;
// }

.view-question--inner .view-question--ans {
    margin-top: 20px;
    min-height: 100px;
}

.view-question--inner .view-question--ans ul li .ans-content {
    font-weight: 400;
}

// ========================================================================
//                    Admin Section Style Underneath
// ========================================================================
.read-editor-container {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
}
.adminPage_content {
    input[type="text"]:not(.browser-default) {
        background-color: #ffffff;
        border: 1px solid #AFAFAF;
        // border-radius: 16px;
        border-radius: 5px;
        box-shadow: none;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        height: 50px;
        line-height: 1.3;
        margin: 0;
        max-width: 100%;
        padding: 5px 30px;
        position: relative;
        transition: all 0.25s ease;
        width: 100%;
    
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &::-moz-placeholder {
            /* Firefox 19+ */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:-ms-input-placeholder {
            /* IE 10+ */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:-moz-placeholder {
            /* Firefox 18- */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:hover {
            border-color: darken(#999999, 20%);
        }
    
        &:focus {
            border-color: #023b4e;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
        }
    
        &.num_text {
            font-weight: 400;
        }
    }
    .dash_top_section {
        .dash_title {
            text-transform: capitalize;
        }
        .satAct_content_sec {
            .newActForm, .newtopicForm {
                form {
                    position: relative;
                    & > div {
                        position: relative;
                    }
                    input[type="text"]:not(.browser-default) {
                        padding-right: 165px;
                    }
                    .btn:not(.btn-default).btn-primary {
                        border-radius: 13px;
                        font-weight: 500;
                        height: calc(100% - 10px);
                        padding: 5px 20px;
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        margin: 0 !important;
                        white-space: nowrap;
                    }
                }
            }
        }
        .scoreConversionCls {
            display: flex;
            margin-left: auto !important;
            .input-group-prepend {
                & > .input-group-text {
                    background: none;
                    border: none;
                    color: #333;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.3;
                    padding: 0 20px 0 0;
                }
            }
            .custom-file {
                // width: auto;
                .custom-file-label {
                    border-radius: 5px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0;
                    height: 100%;
                    &::after {
                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-radius: 0 5px 5px 0;
                        height: 100%;
                    }
                }
            }
        }
    }
    .mgrSec_wrap {
        .add-topic {
            padding: 0;
            .newtopicForm {
                align-items: center;
                display: flex;
                width: 100%;
                form {                    
                    margin-right: 15px;
                }
                h3 {
                    display: inline-block;
                    margin-right: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
        .mgr_sec {
            & > .sortable-topics {
                .sortable-home-topic-list {
                    & > li {
                        & > .home-topic {
                            & > .topicTitle::before {
                                left: 45px;
                            }
                            .topicTitle .ico_arrow {
                               left: 45px;
                            }
                            &.open {
                                > .topicTitle {
                                    & + div {
                                        &::before {
                                            left: 58px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .sortable-topics {
            ul {
                li.sortable-topic {
                    position: relative;
                    padding: 10px 0 0 0;
                    // .sequenceBtn {
                    //     margin-right: auto;
                    // }
                    .sortable-handle {
                        align-items: center;
                        display: inline-flex;
                        height: 68px;
                        justify-content: center;
                        left: 5px;
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        top: 0;
                        width: 30px;
                        z-index: 10;
                    }
                    .home-topic {
                        & > .topicTitle {
                            padding: 10px 30px 10px 92px;
                            margin: 0 0 10px;
                            &::before {
                                background-size: 100% auto;
                                width: 26px;
                                height: 32px;
                                top: 10px;
                            }
                        }
                        .subtopicHeader {
                            padding-right: 30px;
                        }
                    }
                    .topic-content {
                        .topic-content-inner {
                            .subtopic-content {
                                .sortable-topics {
                                    padding-top: 15px;
                                    ul {
                                        li {
                                            .admin-subtopic {
                                                align-items: center;
                                                border-radius: 22px;
                                                border: 1px solid #c1c1c1;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: wrap;
                                                font-size: 16px;
                                                margin-bottom: 10px;
                                                padding: 10px 30px 10px 40px !important;
                                                position: relative;
                                                width: 100%;
                                                p {
                                                    display: inline-block;
                                                    margin-right: auto;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ul.sortable-home-topic-list {
                            li {
                                position: relative;
                                .home-topic {
                                    font-size: 16px;
                                    border: none;
                                    border-radius: 22px;
                                    box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
                                    margin-bottom: 10px;
                                    padding: 10px 30px 10px 40px !important;
                                    .subtopicHeader {
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        width: 100%;
                                        .icon {
                                            align-items: center;
                                            background-color: rgba(129, 129, 129, 0.07);
                                            // border-radius: 11px;
                    border-radius: 5px;
                                            display: inline-flex;
                                            height: 32px;
                                            justify-content: center;
                                            overflow: hidden;
                                            position: absolute;
                                            right: -15px;
                                            text-indent: 200px;
                                            white-space: nowrap;
                                            width: 32px;

                                            &::after {
                                                background-color: rgba(0, 0, 0, 0);
                                                background-image: url('../../images/ico-arrow-down-sm.png');
                                                background-position: center center;
                                                background-repeat: no-repeat;
                                                background-size: 15px 9px;
                                                // border-radius: 11px;
                    border-radius: 5px;
                                                content: '';
                                                display: inline-block;
                                                height: 32px;
                                                opacity: 1;
                                                position: absolute;
                                                left: 50%;
                                                width: 32px;
                                                top: 50%;
                                                transform: translate(-50%, -50%);
                                            }
                                        }
                                        &.open {                    
                                            .icon {
                                                transform: rotate(180deg);
                                            }
                                        }
                                        .hide-menu {
                                            margin-right: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .question_sec {
        .sortable-questions {
            margin-top: 30px;
            & + div {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 20px;
                
                .create_btn {
                    display: flex;
                }
            }
            .sortable-question-list, ul {
                li {
                    padding: 0;
                    position: relative;
                    .sortable-handle {
                        align-items: center;
                        background: none;
                        display: inline-flex;
                        height: 60px;
                        justify-content: center;
                        left: 5px;
                        margin: 0;
                        opacity: 1;
                        padding: 0;
                        position: absolute;
                        top: 0;
                        width: 30px;
                        z-index: 10;
                    }
                    .admin-question {
                        align-items: center;
                        border-radius: 22px;
                        border: 1px solid #c1c1c1;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        font-size: 1rem;
                        margin-bottom: 30px;
                        padding: 10px 30px 10px 40px !important;
                        position: relative;
                        width: 100%;

                        .top-section {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: center;
                            position: relative;
                            width: 100%;
                            .header {
                                width: 100%;
                                .left {
                                    display: flex;
                                    width: 100%;
                                }
                                .question-rank {
                                    margin-right: auto;
                                    ul {
                                        li {
                                            align-items: center;
                                            border: none;
                                            border-radius: 9px;
                                            border-radius: 5px;
                                            color: #ffffff;
                                            display: inline-flex;
                                            height: 32px;
                                            justify-content: center;
                                            margin-right: 5px;
                                            position: relative;
                                            width: 32px;
 
                                            &:last-child {
                                                margin-right: 0;
                                            }

                                            // &.inactive {
                                            //     background-color: grey;
                                            // }
                                        }
                                    }
                                }
                                i.fa {
                                    font-size: 32px;
                                }
                            }
                            .editor-section {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                position: relative;
                                margin: 0;
                            }
                        }
                    }
                    .admin-answers {
                        div:not([class]), div[class=""] {
                            // display: inline-block;
                            // max-width: 100%;
                            position: relative;
                        }
                        .admin-answer-list, ul {
                            li, li.sortable-answer {
                                .sortable-handle {
                                    top: 15px;
                                }
                                .admin-answer {
                                    padding-left: 45px;
                                    .answer-display {
                                        flex-wrap: wrap;                                        
                                        .text-danger {
                                            margin-left: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        .admin-question {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .dashtitle_sec {
            margin-bottom: 20px;
        }
        .quesHeader {
            position: relative;
            .selTopics_wrap {
                position: absolute;
                right: 0;
                bottom: 100%;
                margin-bottom: 21px;
                .hamburger {
                    background-color: rgba(253, 102, 0, 0.15);
                    // border-radius: 11px;
                    border-radius: 5px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 34px;
                    overflow: hidden;
                    position: relative;
                    text-indent: 100px;
                    width: 34px;
                    padding: 0;
                    &.svg-container > svg, & > svg {
                        width: 19px;
                        height: 19px;
                    }
                }
                .sel-topics-list {
                    border-radius: 14px;
                    border: 1px solid #e0e0e0;
                    box-shadow: 0 10px 25px rgba(0,0,0,0.09);
                    left: auto;
                    margin: 0;
                    min-width: 280px;
                    padding: 20px;
                    right: 0;
                    top: 100%;
                    z-index: 100;
                    h2, h3 {
                        font-size: 18px;
                    }
                    ul {
                        width: 100%;
                        li {
                            font-size: 14px;
                            line-height: 1.3;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}

.users_sec {
    table {
        &.dataTable {
            &.tutors_table {
                th.actions {
                    max-width: 281px !important;
                    width: 281px;
                }
            }
        }
    }
    .setting_contentWrap  {
        padding: 30px;
        .card_header  {
            .btn-grup {
                .btn:not(.btn-default).btn-primary {
                    padding: 3px 20px;
                }
            }
        }
        .videoExpCls {
            display: inline-flex;
            width: 100%;
            .view-question--inner {
                padding: 0;
            }
            figure {
                margin: 0;
                width: 100%;
            }
        }
    }
}

.create-question-form {
    background: #F2F5FA;
    border: none;
    margin: 0 0 20px;
}
.edit-question, .edit-answer, .create-question-form, .add-answer-form {
    padding: 15px;
    // border-radius: 16px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    & > div {
        display: inline-block;
        width: 100%;
        &:first-child {
            .editor-container {
                margin-top: 0;
            }
        }
    }
    .editor-container {
        background: #ffffff;
        border: 1px solid #c1c1c1;
        // border-radius: 16px;
        border-radius: 5px;
        //margin-top: 20px;
        padding: 15px;
        width: 100%;

        // .editor-buttons > * {
        //     height: 36px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     width: 36px;
        // }

        .editor {
            .draftJsToolbar__toolbar__dNtBH {
                top: 15px;
                right: 15px;
            }
        }
    }    
    .attributes {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .edit-question-rank {
            margin-right: auto;
            ul {
                display: inline-flex;
                li {
                    align-items: center;
                    border: none;
                    border-radius: 5px;
                    color: #ffffff;
                    display: inline-flex;
                    height: 32px;
                    justify-content: center;
                    margin-right: 5px;
                    position: relative;
                    width: 32px;
                }
            }
        }
    }
    .linkedques_container, .btn_grup {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: auto;
    }        
    .linkedques_container {
        label {
            margin: 5px 0;
            font-size: 12px;
            font-weight: 400;
            padding-right: 30px;
        }
        label + div, button {
            margin: 5px 15px 5px 0;
        }
    }
    .btn_grup {
        button {
            margin: 5px 15px 5px 0;
        }
    } 
    & > .ques_attributes {
        //padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        & > div, .linkedques_container, .btn_grup {
            margin: 5px 15px 5px 0;
            position: relative;
            padding-right: 15px;

            &::after {
                background-color: #8A8A8A;
                content: '';
                height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
            }
            &:last-child {
                &::after {
                    content: none;
                }
            }
        }
        .linkedques_container {
            flex-wrap: nowrap;
            label {
                white-space: nowrap;
                padding-right: 15px;
            }
        }
        
        .edit-question-rank {
            ul {
                li {
                    align-items: center;
                    border: none;
                    border-radius: 5px;
                    color: #ffffff;
                    display: inline-flex;
                    height: 32px;
                    justify-content: center;
                    margin-right: 5px;
                    position: relative;
                    width: 32px;

                    &:last-child {
                        margin-right: 0;
                    }

                    // &.inactive {
                    //     background-color: grey;
                    // }
                }
            }
        }
    }     
}

.admin-question {
    align-items: center;
    border-radius: 22px;
    border: 1px solid #c1c1c1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1rem;
    margin-bottom: 10px;
    padding: 10px 30px 10px 40px !important;
    position: relative;
    width: 100%;

    & > div {
        width: 100%;
    }

    .top-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        width: 100%;
        .header {
            width: 100%;
            .left {
                display: flex;
                width: 100%;
            }
            .question-rank {
                margin-right: auto;
                ul {
                    li {
                        align-items: center;
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        display: inline-flex;
                        height: 32px;
                        justify-content: center;
                        margin-right: 5px;
                        position: relative;
                        width: 32px;

                        &:last-child {
                            margin-right: 0;
                        }

                        // &.inactive {
                        //     background-color: grey;
                        // }
                    }
                }
            }
            i.fa {
                font-size: 32px;
            }
        }
        .editor-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin: 0;
            .question-editor {
                // border: 2px solid red;
                padding-top: 15px;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .question-editor-show {
                    border: 1px solid #c1c1c1;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    padding: 10px 20px;
                    position: relative;
                    width: 100%;
                    &:hover {
                        background-color: #fff;
                    }
                }
                .read-editor-container {
                    width: 100%;
                    display: flex;
                    margin: 0 auto;
                    max-width: 740px;
                    .DraftEditor-root {
                        width: 100%;
                    }
                }
                .editor-container {
                    .editor {
                        width: 100%;
                        max-width: 750px;
                        margin: 30px auto;
                    }
                }
                .btn-grup {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 5px 0 !important;
                    width: 100%;
                    position: relative;
                    button {
                        margin: 5px 20px 5px 0 !important;
                    }
                    .btn-default {
                        i {
                            &:first-child {
                                margin: 0 10px 0 0;
                                font-size: 18px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    .question-editor {
        width: 100%;
        .editQues_wrapper {
            width: 100%;
            .edit-question {
                background: #F2F5FA;
                margin: 0 0 20px;
                width: 100%;
            }
        }
    }

    .admin-answers {
        background: #EFEFEF;
        border-radius: 15px;
        margin: 30px 0 20px;
        padding: 30px;
        position: relative;
        width: 100%;
        .add-answer {
            margin-bottom: 30px;
            h2.ansTitle {
                font-size: 22px;
                font-weight: 600;
                line-height: 1.3;
                position: relative;
            }
        }
        .sortable-answers {
            ul {
                li {
                    margin: 0 0 30px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .admin-answer {
                        width: 100%;
                        padding: 25px;
                        // border-radius: 16px;
                        border-radius: 5px;
                        margin: 0;
                        border: 1px solid #e5e5e5;
                        .answer-display {
                            border: 1px solid #c1c1c1;
                            border-radius: 5px;
                            margin-bottom: 10px;
                            padding: 10px 20px;
                            position: relative;
                            width: 100%;
                            & > div:first-child, & > span:first-child {
                                display: inline-block;
                                margin-right: auto !important;
                                width: auto;
                            }
                            .text-info, .text-danger {
                                white-space: nowrap;
                            }
                        }
                        .answer-buttons {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 5px 0 !important;
                            width: 100%;
                            button {
                                margin: 5px 20px 5px 0 !important;
                            }
                        }
                        .admin-answer-content {
                            ul {
                                li {
                                    border-bottom: 1px solid #c1c1c1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin: 10px 0;
                                    & > label {
                                        margin: 5px 0;
                                        font-weight: 400;
                                        font-size: 12px;
                                        flex: 0 0 33.333%;
                                        & > b, & > strong {
                                            font-weight: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .flex-editor {
        background: #fff;
        border: 1px solid #ccc;
        padding: 25px;
        // border-radius: 16px;
        border-radius: 5px;
        width: 100%;
        position: relative;
        .header {
            height: auto;
            margin-bottom: 20px;
            h3, .title {
                flex-grow: 1;
                font-size: 22px;
                font-weight: 600;
                height: auto;
                line-height: 1.3;
                margin-right: auto !important;
                max-width: initial;
                position: relative;
                text-transform: capitalize;
                width: auto;
                & > span {
                    font-size: 14px;
                    margin-left: 10px !important;
                }
            }
            .btn_grup {
                display: inline-block;

                .btn:not(.btn-default) {
                    background-color: rgba(0,0,0,0.1);
                    color: #333;
                    padding: 3px 20px;
                }
                button {
                    margin: 5px 15px 5px 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .editor-container {
            .editor {
                border: 1px solid #d3d3d3;
                max-width: 715px;
                margin: 20px auto;
            }
        }
    }

    .edit-question, .edit-answer {
        background: #ffffff;
        background: rgba(0,0,0,.05);
        // padding: 15px;
        border-radius: 5px;
        // width: 100%;
        // position: relative;
        // & > div {
        //     display: inline-block;
        //     width: 100%;
        //     &:first-child {
        //         .editor-container {
        //             margin-top: 0;
        //         }
        //     }
        // }
        // .editor-container {
        //     background: #ffffff;
        //     border: 1px solid #c1c1c1;
            border-radius: 5px;
        //     margin-top: 20px;
        //     padding: 15px;
        //     width: 100%;

        //     // .editor-buttons > * {
        //     //     height: 36px;
        //     //     display: flex;
        //     //     align-items: center;
        //     //     justify-content: center;
        //     //     width: 36px;
        //     // }

        //     .editor {
        //         .draftJsToolbar__toolbar__dNtBH {
        //             top: 15px;
        //             right: 15px;
        //         }
        //     }
        // }
        // .linkedques_container, .btn_grup {
        //     margin-top: 20px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-start;
        //     flex-wrap: wrap;
        //     height: auto;
        // }        
        // .linkedques_container {
        //     label {
        //         margin: 5px 0;
        //         font-size: 12px;
        //         font-weight: 400;
        //         padding-right: 30px;
        //     }
        //     label + div, button {
        //         margin: 5px 15px 5px 0;
        //     }
        // }
        // .btn_grup {
        //     button {
        //         margin: 5px 15px 5px 0;
        //     }
        // }        
    }
}

.dialog.create-question-dialog, .dialog.create-answer-dialog, .dialog.existing-problem-set-dialog, .dialog.link-question-dialog {
    max-width: 100%;
    // width: 540px;
    .body {
        & > div {
            display: flex;
            padding: 15px 0;
            align-items: center;
            justify-content: center;
            .btn-default, button {
                background-color: #FD6600;
                border: none;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                height: 45px;
                margin: 10px 15px;
                padding: 5px 20px;
            }
            ul {
                li {
                    border: 1px solid #c7c7c7;
                    // border-radius: 16px;
                    border-radius: 5px;
                    padding: 15px;
                    margin: 0 0 15px;
                    list-style: none !important;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        div:not([class]), div[class=""] {
            display: inline-block;
            width: 100%;
            position: relative;
            padding: 0;
        }
    }
}

li.sortableModal_listele {
    position: relative;
    .sortable-handle {
        align-items: center;
        display: inline-flex;
        height: 68px;
        justify-content: center;
        left: 5px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 10;
    }
    .admin-subtopic {
        align-items: center;
        background-color: #ffffff;
        border-radius: 22px;
        border: 1px solid #c1c1c1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 16px;
        margin-bottom: 10px;
        padding: 10px 30px 10px 40px !important;
        position: relative;
        width: 100%;
        p {
            display: inline-block;
            margin-right: auto;
        }
        .options {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            white-space: nowrap;
            width: 100%;

            & > button, & > .svg-container {
                margin: 5px 20px 5px 0 !important;
            }
            .btn-default.btn-primary {
                border: none;
                padding: 5px 15px 5px 5px !important;
                .ico {
                    flex: 0 0 32px;
                }
            }
            .btn-notes {
                padding: 5px 10px;
                i {
                    margin: 0 10px 0 0 !important;
                    font-size: 18px;
                }
            }

            .svg-container {
                align-items: center;
                background-color: rgba(2, 59, 78, 0.15);
                border-radius: 5px;
                border: none;
                display: flex;
                height: 32px;
                justify-content: center;
                overflow: hidden;
                width: 32px;

                svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}

.admin-problemSet-popup {
    .secTitle {
        border-bottom: 1px solid #cfcfcf;
        display: inline-block;
        font-size: 22px;
        margin-bottom: 10px;
        padding: 0 0 15px;
        width: 100%;
    }
    .btnGroup {
        display: flex;
        margin-bottom: 15px;
        & > span, & > button, & > .btn {
            margin: 5px 15px 5px 0 !important;
        }
        & > span {
            padding: 0 !important;
        }
    }
    .linked_Sections {
        display: inline-block;
        width: 100%;
        .title {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .problemsetDataCls {
            .problemsetTitleAdminCls {
                p {
                    font-size: 18px;
                }
            }
        }
        // .admin-subtopic {
        //     .options {
        //         align-items: center;
        //         display: flex;
        //         flex-wrap: wrap;
        //         margin: 0;
        //         white-space: nowrap;
        //         width: 100%;

        //         & > button, & > .svg-container {
        //             margin: 5px 20px 5px 0 !important;
        //         }
        //         .btn-default.btn-primary {
        //             border: none;
        //             padding: 5px 15px 5px 5px !important;
        //             .ico {
        //                 flex: 0 0 32px;
        //             }
        //         }
        //         .btn-notes {
        //             padding: 5px 10px;
        //             i {
        //                 margin: 0 10px 0 0 !important;
        //                 font-size: 18px;
        //             }
        //         }

        //         .svg-container {
        //             align-items: center;
        //             background-color: rgba(2, 59, 78, 0.15);
        //             border-radius: 9px;
        border-radius: 5px;
        //             border: none;
        //             display: flex;
        //             height: 32px;
        //             justify-content: center;
        //             overflow: hidden;
        //             width: 32px;

        //             svg {
        //                 width: 26px;
        //                 height: 26px;
        //             }
        //         }
        //     }
        // }
    }
    .form-container {
        margin-bottom: 0 !important;        
        .subtopic-form {
            border: 1px solid #c1c1c1;
            margin: 15px 0;
            padding: 10px 20px;
            border-radius: 13px;
            position: relative;
            font-size: 18px;
            .svg-container > svg {
                width: 18px;
            }
        }
    }
    // .sortable-topics {
    //     ul {
    //         li {
    //             position: relative;
    //             .sortable-handle {
    //                 align-items: center;
    //                 display: inline-flex;
    //                 height: 68px;
    //                 justify-content: center;
    //                 left: 5px;
    //                 margin: 0;
    //                 padding: 0;
    //                 position: absolute;
    //                 top: 0;
    //                 width: 30px;
    //                 z-index: 10;
    //             }
    //             .admin-subtopic {
    //                 align-items: center;
    //                 border-radius: 22px;
    //                 border: 1px solid #c1c1c1;
    //                 display: flex;
    //                 flex-direction: row;
    //                 flex-wrap: wrap;
    //                 font-size: 16px;
    //                 margin-bottom: 10px;
    //                 padding: 10px 30px 10px 40px !important;
    //                 position: relative;
    //                 width: 100%;
    //                 p {
    //                     display: inline-block;
    //                     margin-right: auto;
    //                 }
    //             }
    //         }
    //     }
    // }
}

body > li.sortable-topic {
    position: relative;

    & > .home-topic {
        width: 100%;
        border: 1px solid #c1c1c1;
        & > .topicTitle::before {
            left: 45px;
        }
    }
    .sortable-handle {
        align-items: center;
        display: inline-flex;
        height: 68px;
        justify-content: center;
        left: 5px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 10;
    }
    .home-topic {
        background-color: #f5f5f5;
        padding: 0;
        .topicTitle {
            align-items: center;
            color: #023B4E;
            display: flex;
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            padding: 10px 30px 10px 92px;
            margin: 0 0 10px;
            position: relative;
            width: 100%;

            &::before {
                background-color: rgba(0, 0, 0, 0);
                // background-image: url('../../images/ico-ios-add-circle.png');
                background-image: url('../../images/icon-select-arrow.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                // border-radius: 11px;
                    border-radius: 5px;
                content: '';
                display: inline-block;
                height: 26px;
                // height: 36px;
                left: 45px;
                opacity: 1;
                position: absolute;
                top: 12px;
                width: 26px;
                // width: 36px;
            }
            .titleTxt {
                margin: 0 auto 0 0;
                position: relative;
            }
        }
        .subtopicHeader {
            padding-right: 30px;
        }
        &.open {
            .topicTitle {    
                &::before {
                    transform: rotate(180deg);
                }
            }
            & > .topicTitle + div {
                position: relative;
                padding: 0 30px 20px 92px;
                &::before {
                    background-color: #B8B8B8;
                    // border-radius: 11px;
                    border-radius: 5px;
                    content: '';
                    display: inline-block;
                    height: calc(100% - 20px);
                    left: 58px;
                    opacity: 1;
                    position: absolute;
                    top: 0;
                    width: 1px;
                }
            }
        }
        .topic-content {
            .sequenceBtn {
                margin-right: 0;
            }
        }
    }
    .topic-content {
        width: 100%;
        .topic-content-inner {
            width: 100%;
            .subtopic-content {
                width: 100%;
                .sortable-topics {
                    padding-top: 15px;
                    ul {
                        width: 100%;
                        li {
                            width: 100%;
                            .admin-subtopic {
                                align-items: center;
                                border-radius: 22px;
                                border: 1px solid #c1c1c1;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                font-size: 16px;
                                margin: 0 0 10px;
                                padding: 10px 30px 10px 40px !important;
                                position: relative;
                                width: 100%;
                                p {
                                    display: inline-block;
                                    margin-right: auto;
                                }
                            }
                        }
                    }
                }              
                .add-sat {
                    padding: 0;
                }
            }
        }
        ul.sortable-home-topic-list {
            width: 100%;
            li {
                width: 100%;
                position: relative;
                .home-topic {
                    background-color: #ffffff;
                    font-size: 16px;
                    border: none;
                    border-radius: 22px;
                    box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
                    margin: 0;
                    padding: 10px 30px 10px 40px !important;                    
                    width: 100%;
                    .subtopicHeader {
                        display: flex;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        .icon {
                            align-items: center;
                            background-color: rgba(129, 129, 129, 0.07);
                            // border-radius: 11px;
                    border-radius: 5px;
                            display: inline-flex;
                            height: 32px;
                            justify-content: center;
                            overflow: hidden;
                            position: absolute;
                            right: -15px;
                            text-indent: 200px;
                            white-space: nowrap;
                            width: 32px;

                            &::after {
                                background-color: rgba(0, 0, 0, 0);
                                background-image: url('../../images/ico-arrow-down-sm.png');
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: 15px 9px;
                                // border-radius: 11px;
                    border-radius: 5px;
                                content: '';
                                display: inline-block;
                                height: 32px;
                                opacity: 1;
                                position: absolute;
                                left: 50%;
                                width: 32px;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                        &.open {                    
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                        .hide-menu {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }        
        .sequenceBtn {
            margin-right: auto;
        }
    }
    .add-sat {
        padding: 10px 0;
    }
}

body > .home-topic {
    background-color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 22px;
    box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
    margin-bottom: 10px;
    padding: 10px 30px 10px 40px !important;                    
    width: 100%;
    .subtopicHeader {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        .icon {
            align-items: center;
            background-color: rgba(129, 129, 129, 0.07);
            // border-radius: 11px;
                    border-radius: 5px;
            display: inline-flex;
            height: 32px;
            justify-content: center;
            overflow: hidden;
            position: absolute;
            right: -15px;
            text-indent: 200px;
            white-space: nowrap;
            width: 32px;

            &::after {
                background-color: rgba(0, 0, 0, 0);
                background-image: url('../../images/ico-arrow-down-sm.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 15px 9px;
                // border-radius: 11px;
                    border-radius: 5px;
                content: '';
                display: inline-block;
                height: 32px;
                opacity: 1;
                position: absolute;
                left: 50%;
                width: 32px;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &.open {                    
            .icon {
                transform: rotate(180deg);
            }
        }
        .hide-menu {
            margin-right: 30px;
        }
    }
}

body > li.sub-topic-list-element {
    position: relative;
    .sortable-handle {
        align-items: center;
        display: inline-flex;
        height: 68px;
        justify-content: center;
        left: 5px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 10;
    }
    .home-topic {
        font-size: 16px;
        border: none;
        border-radius: 22px;
        box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
        margin-bottom: 10px;
        padding: 10px 30px 10px 40px !important;
        .subtopicHeader {
            padding-right: 30px;
        }
        .subtopicHeader {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            .icon {
                align-items: center;
                background-color: rgba(129, 129, 129, 0.07);
                // border-radius: 11px;
                    border-radius: 5px;
                display: inline-flex;
                height: 32px;
                justify-content: center;
                overflow: hidden;
                position: absolute;
                right: -15px;
                text-indent: 200px;
                white-space: nowrap;
                width: 32px;

                &::after {
                    background-color: rgba(0, 0, 0, 0);
                    background-image: url('../../images/ico-arrow-down-sm.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 15px 9px;
                    // border-radius: 11px;
                    border-radius: 5px;
                    content: '';
                    display: inline-block;
                    height: 32px;
                    opacity: 1;
                    position: absolute;
                    left: 50%;
                    width: 32px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &.open {                    
                .icon {
                    transform: rotate(180deg);
                }
            }
            .hide-menu {
                margin-right: 30px;
            }
        }        
        .subtopic-content {
            .sortable-topics {
                padding-top: 15px;
                ul {
                    li {
                        position: relative;
                        .sortable-handle {
                            align-items: center;
                            display: inline-flex;
                            height: 68px;
                            justify-content: center;
                            left: 5px;
                            margin: 0;
                            padding: 0;
                            position: absolute;
                            top: 0;
                            width: 30px;
                            z-index: 10;
                        }
                        .admin-subtopic {
                            align-items: center;
                            border-radius: 22px;
                            border: 1px solid #c1c1c1;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            font-size: 16px;
                            margin-bottom: 10px;
                            padding: 10px 30px 10px 40px !important;
                            position: relative;
                            width: 100%;
                            p {
                                display: inline-block;
                                margin-right: auto;
                            }
                        }
                    }
                } 
            }               
            .add-sat {
                padding: 0;
            }
        }
    }
}

body > li.sub-sub-topic-list-element {
    position: relative;
    .sortable-handle {
        align-items: center;
        display: inline-flex;
        height: 68px;
        justify-content: center;
        left: 5px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 10;
    }    
    .admin-subtopic {
        background-color: #ffffff;
        align-items: center;
        border-radius: 22px;
        border: 1px solid #c1c1c1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 16px;
        margin-bottom: 10px;
        padding: 10px 30px 10px 40px !important;
        position: relative;
        width: 100%;
        p {
            display: inline-block;
            margin-right: auto;
        }
    }

}

.dialog {
    .preview-question--inner {
        max-width: 100%;
        padding: 0;
        width: 100%;
        &.adjustUlInDialogExisting {   
            .sortable-sats {
                margin-top: 20px;
            }       
            ul {
                margin: 0;
            }
        }
    }
    .pr-2 {
        padding: 0 !important;
    }
    .sortable-topics {
        display: flex;
        width: 100%;     
        ul {
            width: 100%;
        }   
        .sortable-home-topic-list {
            & > li {
                &:first-child {
                    .home-topic {
                        margin-top: 0;
                    }    
                    & > .home-topic {    
                        &.open {
                            margin-top: 0;
                        }
                    }                
                }
                &:last-child {
                    .home-topic {
                        margin-bottom: 0;
                    }       
                    & > .home-topic {    
                        &.open {
                            margin-bottom: 0;
                        }
                    }                  
                }
                & > .home-topic {
                    border-color: #CBCBCB;
                    border-style: solid;
                    border-width: 0 0 1px;
                    margin: 10px 0;
                    margin: 0;
                    // padding: 20px 30px 20px 92px;
                    padding: 0;
                    position: relative;
                    width: 100%;

                    &.open {
                        background-color: #F8F8F8;
                        border-radius: 19px;
                        border: none;
                        margin-bottom: 20px;
                        margin-top: 20px;
                        & > .topicTitle + div {    
                            padding: 20px 30px 20px 92px;
                            &::before {
                                display: inline-block;
                            }
                        }
                        .open {
                            background-color: #fff;
                        }
                        .topicTitle {    
                            &::before {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    
                    & > .topicTitle {
                        color: #023B4E;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 20px 30px 20px 92px;
                        position: relative;
                        width: 100%;

                        &::before {
                            background-color: rgba(0, 0, 0, 0);
                            // background-image: url('../../images/ico-ios-add-circle.png');
                            background-image: url('../../images/icon-select-arrow.png');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            // border-radius: 11px;
                    border-radius: 5px;
                            content: '';
                            display: inline-block;
                            height: 32px;
                            left: 30px;
                            opacity: 1;
                            position: absolute;
                            top: 20px;
                            width: 32px;
                        }
                    }
                    .topic-content {
                        position: relative;

                        &::before {
                            background-color: #B8B8B8;
                            // border-radius: 11px;
                    border-radius: 5px;
                            content: '';
                            display: none;
                            height: calc(100% - 20px);
                            left: 45px;
                            opacity: 1;
                            position: absolute;
                            top: 0;
                            width: 1px;
                        }

                        .sortable-topics {
                            & > .sortable-home-topic-list {
                                width: 100%;
                                li {
                                    .home-topic {
                                        font-size: 16px;
                                        border: none;
                                        border-radius: 22px;
                                        box-shadow: 0 5px 10px rgba(220, 220, 220, 0.25);
                                        margin-bottom: 10px;
                                        padding: 15px 55px 15px 30px !important;
                                        .subtopicHeader {
                                            display: flex;
                                            align-items: center;
                                            position: relative;
                                            width: 100%;
                                            .icon {
                                                align-items: center;
                                                background-color: rgba(129, 129, 129, 0.07);
                                                // border-radius: 11px;
                    border-radius: 5px;
                                                display: inline-flex;
                                                height: 32px;
                                                justify-content: center;
                                                overflow: hidden;
                                                position: absolute;
                                                // right: -15px; 
                                                left: 100%;
                                                margin-left: 15px;                                        
                                                // position: relative;
                                                text-indent: 200px;
                                                white-space: nowrap;
                                                width: 32px;

                                                &::after {
                                                    background-color: rgba(0, 0, 0, 0);
                                                    background-image: url('../../images/ico-arrow-down-sm.png');
                                                    background-position: center center;
                                                    background-repeat: no-repeat;
                                                    background-size: 15px 9px;
                                                    // border-radius: 11px;
                    border-radius: 5px;
                                                    content: '';
                                                    display: inline-block;
                                                    height: 32px;
                                                    opacity: 1;
                                                    position: absolute;
                                                    left: 50%;
                                                    width: 32px;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);
                                                }
                                            }
                                            // &::after {
                                            //     background-color: rgba(129, 129, 129, 0.07);
                                            //     background-image: url('../../images/ico-arrow-down-sm.png');
                                            //     background-position: center center;
                                            //     background-repeat: no-repeat;
                                            //     background-size: 15px 9px;
                                                // border-radius: 11px;
                                                    // border-radius: 5px;
                                            //     content: '';
                                            //     display: inline-block;
                                            //     height: 32px;
                                            //     opacity: 1;
                                            //     position: absolute;
                                            //     right: -15px;
                                            //     width: 32px;
                                            //     top: 50%;
                                            //     transform: translateY(-50%);
                                            // }
                                            .sequenceBtn {
                                                margin: 0 15px;
                                                padding: 0;
                                            }
                                        }
                                        .subtopic-content {
                                            position: relative;
                                            &::before {
                                                background-color: #B8B8B8;
                                                // border-radius: 11px;
                                                border-radius: 5px;
                                                content: '';
                                                display: none;
                                                height: 100%;
                                                left: 0;
                                                opacity: 1;
                                                position: absolute;
                                                top: 0;
                                                width: 1px;
                                            }
                                            .sortable-home-topic-list {
                                                li {
                                                    color: #515151;
                                                    display: inline-block;
                                                    font-size: 16px;
                                                    margin-bottom: 10px;
                                                    position: relative;
                                                    width: 100%;
                                                    &:last-child {
                                                        margin: 0;
                                                    }
                                                    &::before {
                                                        background-color: #B8B8B8;
                                                        // border-radius: 11px;
                                                        border-radius: 5px;
                                                        content: '';
                                                        display: inline-block;
                                                        height: 1px;
                                                        left: -30px;
                                                        opacity: 1;
                                                        position: absolute;
                                                        top: 12px;
                                                        width: 15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.open {    
                                            margin-top: 0;                                        
                                            .subtopicHeader {
                                                .icon {
                                                    &::after {
                                                        background-image: url('../../images/collapse_arrow_active.png');
                                                    }
                                                }
                                                // &::after {
                                                //     background-image: url('../../images/collapse_arrow_active.png');
                                                //     // transform: rotate(180deg);
                                                // }
                                            }
                                            .subtopic-content {
                                                margin-top: 10px;
                                                padding: 0 0 0 30px;
                                                &::before {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    input[type="text"]:not(.browser-default) {
        background-color: #ffffff;
        border: 1px solid #AFAFAF;
        // border-radius: 16px;
        border-radius: 5px;
        box-shadow: none;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        height: 50px;
        line-height: 1.3;
        margin: 0;
        max-width: 100%;
        padding: 5px 30px;
        position: relative;
        transition: all 0.25s ease;
        width: 100%;
    
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &::-moz-placeholder {
            /* Firefox 19+ */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:-ms-input-placeholder {
            /* IE 10+ */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:-moz-placeholder {
            /* Firefox 18- */
            font-weight: 400;
            opacity: 1;
            color: #7f7f7f;
        }
    
        &:hover {
            border-color: darken(#999999, 20%);
        }
    
        &:focus {
            border-color: #023b4e;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
        }
    
        &.num_text {
            font-weight: 400;
        }
    }
    .add-topic {
        padding: 0;
        margin: 10px 0;
        background-color: #F2F5FA;
        padding: 25px;
        border-radius: 20px;
        .form_wrap  {
            form {
                flex-grow: 1;
                position: relative;
                margin-right: 20px;
                & > div {
                    position: relative;
                }
                input[type="text"]:not(.browser-default) {
                    padding-right: 125px;
                }
                .btn:not(.btn-default).btn-primary {
                    border-radius: 13px;
                    font-weight: 500;
                    height: calc(100% - 10px);
                    padding: 5px 20px;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    margin: 0 !important;
                    white-space: nowrap;
                }
            }
            .btn-danger {
                height: 50px;
            }
        }
    }
    .nav.nav-tabs {
        margin: 0;
        border-bottom: 1px solid #AFAFAF;
        height: initial;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        li.nav-item {
            height: 55px;
            line-height: 55px;
            padding: 0;
            width: auto;
            display: inline-flex;
            float: none;
            flex-grow: initial;
            position: static;
            a {
                color: #333;
                border-width: 0 0 3px;
                display: block;
                font-family: "Montserrat", sans-serif;
                font-size: 15px;
                height: 100%;
                margin: 0;
                overflow: hidden;
                padding: 0 15px;
                text-transform: capitalize;
                width: 100%;
                &:hover, &:focus {
                    border-color: transparent;
                    opacity: .5;
                }
                &.active {
                    border-color: #FD6600;
                    color: #000000;
                    font-weight: 700;
                    opacity: 1;
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            padding: 0;
            .card-body {
                padding: 0;
            }
        }
    }
    .bucket-header {
        .links {
            border-bottom: 1px solid #AFAFAF;
            height: initial;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            margin-right: 30px;
            width: 100%;

            & > div {
                border: 1px solid transparent;
                border-width: 0 0 3px;
                color: #333;
                display: block;
                font-family: "Montserrat", sans-serif;
                font-size: 15px;
                height: 100%;
                height: 55px;
                line-height: 55px;
                margin: 0 0 -1px;
                overflow: hidden;
                padding: 0 15px;
                text-align: center;
                text-transform: capitalize;
                width: 100%;
                &:hover, &:focus {
                    color: #FD6600;
                    font-weight: normal;
                }
                &.active {
                    border-color: #FD6600;
                    color: #000000;
                    font-weight: 700;
                    opacity: 1;
                    text-decoration: none;
                }
            }
        }
        .clear-bucket {
            height: 45px;
            width: 45px;
            .svg-container {
                align-items: center;
                border-radius: 9px;
                border-radius: 5px;
                border: 1px solid #cfcfcf;
                display: inline-flex;
                height: 45px;
                justify-content: center;
                width: 45px;
                & > svg {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }
    .bucket-header {
        padding: 0;
    }
    .bucket-content {
        padding: 0;
        ul {
            list-style: none !important;
            padding-top: 30px;
            margin: 0;
             li {
                 border: 1px solid #cfcfcf;                 
                list-style: none !important;
                margin: 0 0 20px;
                // border-radius: 11px;
                    border-radius: 5px;
                position: relative;
                padding: 15px;
                &:last-child {
                    margin: 0;
                }
             }
        }
    }
}

.dialog.bucket-dialog {
    .preview-question--inner {
        padding: 0;
        
        .tab-content {
            .tab-pane {                
                .management-list {
                    .list-row {
                        padding: 15px 0 !important;
                        border-bottom: 1px solid #c1c1c1;
                        align-items: center;
                        .btn_grup {
                            display: flex;
                            align-items: center;
                            button {
                                margin: 5px 0 5px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dialog.recycle-bin-dialog {
    .preview-question--inner {
        padding: 0;

        .tab-content {
            .tab-pane {
                .management-list {
                    .list-row {
                        padding: 15px 0 !important;
                        border-bottom: 1px solid #c1c1c1;
                        align-items: center;
                        .btn_grup {
                            display: flex;
                            align-items: center;
                            button {
                                margin: 5px 0 5px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sortableHelper {
    max-width: calc(100vw - 265px);
    min-height: 66px;
}

figure {
    & > div {
        display: inline-block;
        width: 100% !important;
                
        .draftJsMentionPlugin__iframeContainer__21EVZ, 
        .draftJsFocusPlugin__unfocused__1Wvrs {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        }
        
        /* Then style the iframe to fit in the container div with full height and width */
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }

    }
}

.edit-title {
    form {
        flex-grow: 1;
        position: relative;
        margin-right: 20px;

        .btn:not(.btn-default).btn-primary {
            border-radius: 13px;
            font-weight: 500;
            height: calc(100% - 10px);
            padding: 5px 20px;
            position: absolute;
            right: 5px;
            top: 5px;
            margin: 0 !important;
            white-space: nowrap;
        }

        & + button {
            margin: 5px 0;
            height: 40px;
        }
    }
}

.edit-topic {
    form {
        flex-grow: 1;
        position: relative;
        margin-right: 20px;

        .btn:not(.btn-default).btn-primary {
            border-radius: 13px;
            font-weight: 500;
            height: calc(100% - 10px);
            padding: 5px 20px;
            position: absolute;
            right: 5px;
            top: 5px;
            margin: 0 !important;
            white-space: nowrap;
        }

        & + button {
            margin: 5px 0;
            height: 40px;
        }
    }
}

span.videoSectionCls {
    border: none;
    .adjustVideoViewCls {
        padding: 0;
    }
}









// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================


@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1199px) {
    .editor-container {
        .editor {
            //padding-top: 70px;
            position: relative;
            .draftJsToolbar__toolbar__dNtBH {
                box-shadow: none;
                right: initial;
                left: 15px;
                width: calc(100% - 30px);
            }
        }
    }
    .satAct_content_sec {
        .card {
            .card-content {
                .selectButtonsCls {
                    flex-wrap: wrap;
                    .btn_grup {
                        padding-left: 0;
                        padding-top: 15px;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
    .mgr_sec {
        & > .sortable-topics {
            .sortable-home-topic-list {
                & > li {
                    & > .home-topic {
                        & > .topicTitle + div {
                            & > .sortable-topics {
                                & > .sortable-home-topic-list {
                                    li {
                                        .home-topic {
                                            .subtopicHeader {
                                                flex-wrap: wrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .adminPage_content {
        .dash_top_section {
            .scoreConversionCls {
                display: inline-flex;
                width: 100%;
                flex-wrap: wrap;
                & > * {
                    margin: 5px 20px 5px 0 !important;
                }
                &.input-group > .custom-file {
                    width: 100%;
                }
            }
        }
    }
    li.sortable-sat {
        .card {
            .card-content {
                .selectButtonsCls {
                    .btn_grup {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    #main{
        padding-left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .satAct_content_sec {
        .card {
            .card-content {
                .selectButtonsCls {
                    padding: 15px;
                    .overAllScoreCls {
                        flex-wrap: wrap;
                        width: 100%;
                    }
                }
                .questions {
                    padding: 15px;
                    .ques_set {
                        .satQuesIdTitleCls {
                            flex-wrap: wrap;                            
                        }
                        & > .mockEnabledCls {
                            padding: 15px;
                        }
                    }
                    .satTypeProgressCls {
                        width: 100%;
                        margin: 15px 0;
                    }
                    .mockEnabledCls {
                        padding: 15px;
                    }
                    .satQuesIdTitleCls {
                        flex-wrap: wrap;  
                    }
                }
            }
        }
    }
    .mgr_sec {
        & > .sortable-topics {
            .sortable-home-topic-list {
                & > li {
                    & > .home-topic {
                        & > .topicTitle {
                            // font-size: 16px;
                            padding: 15px 15px 15px 50px;
                            &::before {
                                background-size: 100% auto;
                                height: 24px;
                                left: 15px;
                                top: 18px;
                                width: 24px;
                            }
                        }
                        &.open {
                            & > .topicTitle + div {
                                padding: 15px 15px 15px 50px;
                                &::before {
                                    left: 27px;
                                }
                            }
                        }
                        .topicTitle + div {
                            & > .sortable-topics {
                                & > .sortable-home-topic-list {
                                    li {
                                        .home-topic {
                                            padding: 15px;
                                            .subtopicHeader {
                                                .icon {
                                                    right: 0;
                                                }
                                            }
                                            &.open {
                                                .subtopic-content {
                                                    padding: 0 0 0 30px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    li.sortable-sat {
        .card {
            .card-content {
                .selectButtonsCls, .questions {
                    padding: 20px 15px;
                }
                .questions {
                    .satTypeProgressCls {
                        width: 100%;
                    }
                }
            }
        }
    }
    .question_sec {
        .dashtitle_sec {
            .btn-default {
                margin-bottom: 15px;
            }
        }
        .quesHeader {
            .quesTitle {
                font-size: 18px;
            }
        }
    }
    .dialog {
        padding: 15px !important;
        max-width: 95% !important;
        min-width: 95% !important;
        width: 95% !important;

        .close {
            &.svg-container {
                float: none;
                left: 100%;
                margin-left: -55px;
                max-height: initial;
                padding: 8px;
                position: relative;
                right: initial;
                top: auto;
            }
        }
        &.problem-set-dialog {
            .rightSideOfPopupCls {
                .sortable-admin-topic-list {
                    & > li.sortableModal_listele {
                        .admin-subtopic {
                            .problemsetDataCls {
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {

}

@media screen and (max-width: 480px) {

}

@media screen and (max-width: 380px) {

}

.edit-answer-form {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}


.question-title-div {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    & > .div {
        display: flex;
        vertical-align: top;
    }
    .back-button {
        max-width: 165px !important;
    }

    .report-problem {
        justify-content: center;
        max-width: 230px !important;
        .calc-image {
            width: 55px;
            height: 55px;
            margin-right: 10px;
        }
        button {
            align-self: center;
        }

    }
}

.explanation-row-divider {
    border-top: 1px solid #AFAFAF;
    margin-bottom: 15px;
}

.side-problem-set-border {
    border-left: 1px solid #AFAFAF;
    //margin-bottom: 15px;
}

.bar-divider {
    margin: 0 8px;
    border-left: 1px solid #AFAFAF;
    height: 40px
    //height: 100%;
}

span.checkbox-icon {
    width:  30px;
    align-self: center;
    & > span {
        & > i {
            margin-right: 20px;
        }
    }
}

div.problem-set-title-div {
    margin-top: auto !important;
}

ul.ul-column-4 {
    column-count: 4 !important;
    -webkit-columns: 4 177.5px;
    -moz-columns: 4 177.5px;
     columns: 4 177.5px;
    -moz-column-fill: auto;
    column-fill: auto;
}

ul.ul-column-5 {
    column-count: 5 !important;
    -webkit-columns: 5 177.5px;
    -moz-columns: 5 177.5px;
    columns: 5 177.5px;
    -moz-column-fill: auto;
    column-fill: auto;
}

div.view-uploaded-document {
    margin-left: auto;
    white-space: nowrap;
}

div.assign-select-buttons {
    & > button {
        white-space: nowrap;
    }
}

.div_icon {
    align-items: center;
    background-color: rgba(2, 59, 78, 0.15);
    border-radius: 5px;
    margin-left: 5px;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    text-indent: 200px;
    white-space: nowrap;
    cursor: pointer;
    width: 32px;
}

.margin-right-15 {
    display: inline-flex;
    position: absolute;
    right: 25px !important;
}