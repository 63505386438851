.unlinked-answers {
    display: flex;
    > div {
        display: flex;
        align-items: center;
        &:last-child {
            margin-left: 1em;
        }

        > p {
            margin: 0;
            font-weight: bold;
            margin-right: 0.25em;
            padding-right: 2px;
        }

        .svg-container {
            display: flex;
        }
    }
}
