.bucket {
  .bucket-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    .links {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .active {
        font-weight: bold;
        text-decoration: underline;
      }
      > div {
        &:hover {
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
.linked-problem-list {
	list-style: none;
	font-size: 18px;
	line-height: 30px;
	font-weight: 600;
  .problem-set-lists {
    list-style: none;
    padding-left: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;

    input {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}

.bucket-question-main-div {
  width: 750px;
  .header-div {
    display: flex;
    justify-content: space-between;

    .submit-button-div {
      margin-left: auto;
      margin-right: 10px;
      & > button {
        display: flex;
      }
    }
  }

  & > ul {
    margin-right: 10px;
    & > li.selected {
      background-color: rgba(60,179,113, 0.15) !important;
    }
  }
}
.linked-list {
  line-height: 30px;
  cursor: pointer;
}
.lds-spinner {
  position: absolute;
    margin: auto;
    z-index: 99999;
    left: 50%;
    top: 50%;
}

.bucketAdjustCls{
  width: 750px;
}

.no-record-div {
  justify-content: center;
  align-items: center;
}