@import "../scss/variables";

.answer {
    display: flex;
    align-items: center;
    list-style: none;
    border-bottom: 1px solid #c5c2c2;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    padding: 10px 5px;

    &:hover {
        cursor: pointer;
        border: 1px solid $teal;
    }

    .list-letter {
        margin-right: 10px;
        min-width: 25px;
        min-height: 25px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid $dark-teal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-teal;

        &.selected {
            background-color: $dark-teal;
            color: white;
        }

        &.correct {

        }

        &.incorrect {

        }
    }
}
