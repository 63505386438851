.color-picker {
    display: flex;
    position: relative;
    margin: 0 5px;
    .swatch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #fff;
        border-radius: 1px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        cursor: pointer;

        .svg-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 25px;
            margin-bottom: 2px;
            &:hover {
                background-color: #e0dbdb;
            }
            > svg {
                width: 15px;
                height: 15px;
            }
        }

        .bottom-section {
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            &:hover {
                background-color: #e0dbdb;
            }
            .color {
                width: 15px;
                height: 5px;
                border-radius: 2px;
            }
        }

        .text-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 25px;
            margin-bottom: 2px;

            &:hover {
                background-color: #e0dbdb;
            }

            > p {
                font-size: 16px;
                text-align: center;
                margin: 0;
            }
        }
    }

    .popover {
        position: absolute;
        z-index: 2;
        top: 45px;
        left: -6px;

        .cover {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    }
}
