.dropdown-main-div {
  background: transparent;
  position: relative;
}

.dropdown-header {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
  color: #333333;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-list-container {
  position: absolute;
  width: 150px;
  left: auto;
  right: 0;
  top: 100%;
  z-index: 999;
}

.dropdown-list {
  transition: opacity 1s ease-in;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-sizing: border-box;
}

.dropdown-list-item {
  cursor: pointer;
  width: inherit !important;
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  padding: 8px 15px;
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover {
    background-color: #f7f7f7;
    color: #444444;
  }
}


