.question-editor {
    .question-editor-show {
        background-color: white;
        &.hide {
            max-height: 100px;
            overflow: hidden;
        }
        &:hover {
            cursor: pointer;
            background-color: #d8d8d8;
        }
    }
}
