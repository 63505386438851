@import '../../../../scss/variables';

.admin-questions {
    background-color: #def2f1;
    overflow-y: scroll;
    height: 100%;
    padding: 25px;
    border: 2px solid $dark-teal;
    display: flex;
    position: relative;

    .loading-overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        background-color: grey;
        opacity: 0.5;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        .svg-container {
            z-index: 10000;
            opacity: 1;
            svg {
                width: 300px;
                height: 300px;
            }
        }
    }

    .add-question {
        position:fixed;
        right: 25px;
        z-index: 1000;
    }
}

.loading-questions {
    overflow-y:hidden;
}
