@import "../../../scss/variables";

.tooltip-editor-main-container {
  background: #F2F5FA;
  padding: 20px;
  width: 99%;
  margin-bottom: 10px;
  align-content: center;
}

.tooltip-editor-container {
  flex-direction: column;
  width: 600px;
  position: relative;
  display: flex;
  border: #f0f0f0 solid 1px;
  padding: 10px;
  background: white !important;
  margin: auto;

  .tooltip-editor-buttons {
    justify-content: flex-start;
    display: flex;

    // KatexButton from Plugin
    .katexToolTipInsertButton {
      box-shadow: 0 0 0 1px rgba(0,0,0,.1);
      cursor: pointer;
      padding: 10px;
      background-color: #fff;
      border-style: none;
      border-radius: 1px;

      > button {
        margin-right: 5px;
      }

      &:hover {
        background-color: #e0dbdb;
      }
    }
  }

  .tooltip-editor {
    max-width: 500px;
    width: 100%;
    max-height: 200px;
    min-height: 50px;
    caret-color: black;
    background-color: white;
    border: 1px solid black;
    margin: 15px auto 5px auto;
    overflow-y: scroll;

    // Targets images
    figure {
      margin: 5px 0;
    }

    // Targeted Draft JS classes

    // Toolbar plugin main component
    .draftJsToolbar__toolbar__dNtBH {
      // Current hack until buttons are moved outside of tooltip-editor
      position: absolute;
      top: 0;
      right: 0;
    }

    // Buttons inside  button wrapper
    .draftJsToolbar__button__qi1gf {
      &:hover {
        cursor: pointer;
      }
    }

    textarea {
      resize: auto;
    }

    iframe {
      width: 580px;
      height: 250px;
    }


    blockquote {
      text-align: center;
      padding: 0px 0px 0px 40px;
      margin: 0;
    }
  }
}

.flex-item {
  margin-left: auto;
}

.katexToolTipInsertButton {
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  cursor: pointer;
  padding: 10px;
  background-color: #fff;
  border-style: none;
  border-radius: 1px;

  > button {
    margin-right: 5px;
  }

  &:hover {
    background-color: #e0dbdb;
  }
}

.katexTooltipTex {
  //position: absolute;
  display: block;
  width: 50%;
  min-width: 10%;
  max-width: 100%;
}

.katexTooltipPanel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.katexTooltipSaveButton {
  background-color: #00C789;
  color: white;
  padding: 4px 5px;
  border-radius: 5px;
  &:focus {
    background-color: #00C789;
    opacity: 0.7;
  }
}

.katexTooltipRemoveButton {
  margin-left: 10px;
  background-color: #F06150;
  color: white;
  padding: 4px 5px;
  border-radius: 5px;
  &:focus {
    background-color: #F06150;
    opacity: 0.7;
  }
}

.katexTooltipButtons {
  margin-top: 2px;
  background-color: transparent;
}
