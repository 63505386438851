.admin-users {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.options {
  display: flex;
  margin-left: 10px;

  .svg-container {
    display: flex;

    > svg {
      width: 30px;
      height: 30px;
      cursor: pointer;

      path {
        fill: grey;
      }

      &:hover {
        path {
          fill: black;
        }
      }
    }
  }
}

.admin-user-list {
  padding: 15px;
  overflow-y: scroll;
  width: 100%;
}

.user-list-action-icon{
  cursor: pointer;
  font-size: 20px;
}


table.reported-problem-table {
  thead {
    tr {
      th {
        &:last-child {
          text-align: right;
          left: auto;
          padding-left: 10px;
          right: 0 !important;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:last-child {
          min-width: 80px;
          text-align: right;
          left: auto;
          right: 0 !important;
        }
      }
    }
  }
}