@import "../scss/mixins";

.question-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $teal;
    position: fixed;
    width: 100%;
    top: 0;
    border-bottom: 1px solid black;
    z-index: $header-index;
}
