.sortable-sats {
    .sortable-sat {
        display: flex;
        visibility: visible;
        align-items: flex-start;
    }
}
.sortable-sat {
    margin-bottom: 25px;
    h2 {
        line-height: 1;
        margin: 0;
    }
}
.sortable-handle {
    display: block;
    width: 20px;
    height: 30px;
    margin-right: 10px;
    cursor: row-resize;
    font-size: 24px;
    margin-top: -5px;
}

.admin-problemSet-popup .rightSideOfPopupCls .sortable-admin-topic-list> li {
    padding: 10px 20px;
    background: #d8d8d8;
    border-radius: 10px;
    margin-bottom: 15px;
}