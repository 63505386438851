@import '../scss/variables';

.clear-bucket {
    width: 30px;
    .trash {
        > svg {
            path {
                fill: $grey;
            }
        }
        &:hover {
            cursor:pointer;
            > svg {
                path {
                    fill: $black;
                }
            }
        }
    }
}
