.add-answer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .toggle {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        &:hover {
            background-color: grey;
            cursor: pointer;
        }

        .svg-container {
            width: 15px;
            height: 15px;
            > svg {
                width: 15px;
                height: 15px;
            }
        }

        > p {
            margin: 0;
            margin-left: 25px;
        }
    }

    .add-answer-form {
        align-self: center;
        background-color: #F2F5FA;
        border: 1px solid #D8DBE0;
        display: inline-block;
        padding: 25px;
        width: 100%;

        > div {
            //margin-bottom: 10px;
        }

        .buttons {
            display: flex;
            justify-content: center;
        }
    }
}
