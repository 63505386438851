@import "../scss/mixins";
@import "../scss/variables";

.question-footer {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    align-items: center;
    background-color: $teal;
    width: 100%;
    height: 75px;
    bottom: 0;
    border-top: 1px solid black;
    z-index: $header-index;

    > button {
        height: 25px;
        margin-right: 50px;
    }
}
