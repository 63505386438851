@import "../scss/mixins";
@import "../scss/variables";

.account-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    box-shadow: 0px 9px 19px 6px rgba(0, 0, 0, 0.2);
    top: 60px;
    left: 20px;
    z-index: 650;
    margin: 2px 0 0;
    list-style: none;
    text-align: left;
    background-color: white;
    border: 1px solid #e6ecf0;
    background-clip: padding-box;
    color: black;
    border-radius: 5px;
    padding: 0;

    li {
        display: flex;
        width: 100%;
        .action {
            padding: 10px;
            color: black;
            text-decoration: none;
            width: 100%;
        }

        &:hover {
            background-color: #e6ecf0;
            cursor: pointer;
        }
    }
}
