.admin-problemset-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .problemset-sec{
        background-color: #def2f1;
        overflow-y: scroll;
        height: 100%;
        padding: 25px;
        border: 2px solid #2B7A78;
        display: flex;
        position: relative;
    }
}
.sortable-topics {
    .sortable-topic {
        display: flex;
        visibility: visible;
    }
}

.sortable-admin-topic-list {
    list-style: none;
    li {
        display: flex;
        .admin-topic {
            margin-left: 7px;
            margin-bottom: 10px;
            >.sortable-admin-topic-list {
                >li {
                    display: flex;
                    .admin-subtopic {
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}

.options {
    span.subtopic-form {
        font-size: 14px;
        .svg-container {
            margin-right: 5px;
            > svg { 
                width:12px;
                height:12px;
            }
        }
    }
}

.separatorBarCls {
    border-left: 1px solid black;
}

.problemsetTitleCls {
    cursor: pointer;
    color: #212121;
    font-weight: 600;
}

.problemsetTitleAdminCls {
    cursor: pointer;
}

.problemsetTitleCls:hover{
    text-decoration: underline;
}

.psIconCls {
    border: 1px solid teal;
    display: inline-block;
    height: 18px;
    width: 12px;
    position: relative;
    margin: 5px;
}
.psIconCls.active {
    background-color: teal;
}

i.checkMarkTrue {
    color: teal;
    font-size: 18px;
}

.disabledProblemsetTitleCls {
    cursor: auto;
    text-transform: capitalize;
}

.rightSideOfPopupCls .problemsetDataCls > div {
   flex-grow: 1;
}

.rightSideOfPopupCls .problemsetDataCls .problemsetRightDataCls {
    flex: 0 0 125px;
    max-width: 125px;
    width: 125px;
}

.problemsetTitleCls::first-letter {
    text-transform: capitalize;
}

.notesIconCls{
    font-size: 8px;
}

.student-problem-set-view {
    background-color: transparent !important;
    border: 1px solid #AFAFAF;
}

.student-problem-set-assignment-view {
    background-color: rgba(60, 179, 113, 0.15) !important;
    border: 1px solid #AFAFAF;
}
