.create-question {
    width: 100%;
    background: #F2F5FA;
    margin: 0 30px 0 40px;
}

.create-question-form {
    width: 100%;
    align-self: center;
    background-color: #cfc4c4;
    padding: 15px 15px 15px 15px !important;
    border: 2px solid #3aafa9;

    .attributes {
        display: flex;
        .react-switch-bg {
            > div {
                display: flex;
                align-items: center;
                .calc {
                    width: 35px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
    }
}
