/* @import url(_select.scss); */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

a,
button {
    outline: none;
    border: 0;
}

a:active,
a:focus,
a:hover,
button:active,
button:focus,
button:hover {
    text-decoration: none;
    outline: none !important;
}

img {
    max-width: 100%;
}

li,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #042D50;
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 36px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h2 {
    font-size: 30px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h3 {
    font-size: 24px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h4 {
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h5 {
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

h6 {
    font-size: 12px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

hr {
    background: rgba(0, 0, 0, 0.27);
    border: medium none;
    display: block;
    height: 1px;
    margin: 0;
    padding: 0;
    width: 100%;
}

.noPadd {
    padding: 0 !important;
}

.noMarg {
    margin: 0 !important;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-offset: -2px;
}

input:active,
input:focus {
    outline: 0;
}

.modal-backdrop.in {
    opacity: 0.3;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

bs-dropdown-container {
    z-index: 100;
}

.or_text {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.or_text span::before {
    content: "";
    position: absolute;
    height: 1px;
    background: #ccc;
    left: 0;
    top: 12px;
    width: 45%;
}

.or_text span::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #ccc;
    right: 0;
    top: 12px;
    width: 45%;
}

.modal-terms {
    z-index: 99999;
}

/* Buttons Style 
----------------------------------- */
.btn-default {
    box-shadow: 0 7px 18px rgba(254, 117, 30, 0.25);
    font-size: 22px;
    font-size: 1.375rem;
    transition: all 0.25s ease;
    align-items: center;
    background-color: #FD6600;
    border-radius: 11px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0.5px;
    line-height: 1.3;
    min-height: 60px;
    min-width: 190px;
    padding: 5px 30px;
    position: relative;
    text-transform: capitalize;
}

.btn-default>* {
    position: relative;
    z-index: 2;
}

.btn-default::after {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: all 0.35s ease;
    width: 100%;
    z-index: 1;
}

.btn-default i {
    font-size: 22px;
    margin: 0 0 0 10px;
    line-height: 22px;
    position: relative;
}

.btn-default:focus {
    border-radius: 5px;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 102, 0, 0.25);
}

.btn-default:hover,
.btn-default:focus {
    color: #ffffff;
    outline: none;
}

.btn-default:hover:after,
.btn-default:focus:after {
    opacity: 0.25;
    transform: scale(1);
}

@media screen and (max-width: 1440px) {
    .btn-default {
        font-size: 16px;
        font-size: 1rem;
        border-radius: 11px;
        min-height: 46px;
        min-width: 135px;
        padding: 5px 20px;
    }
}

@media screen and (max-width: 991px) {
    .btn-default {
        font-size: 16px;
        border-radius: 10px;
        min-height: 50px;
        min-width: 150px;
    }
}

@media screen and (max-width: 480px) {
    .btn-default {
        min-height: 46px;
    }
}

.customSelect.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

.customSelect.bootstrap-select .btn.dropdown-toggle {
    background: #ffffff;
    border: 1px solid #eee;
    border-radius: 5px;
    font-size: 20px;
    height: 40px;
    line-height: 1.3;
    padding: 5px 20px;
}

.customSelect.bootstrap-select .btn.dropdown-toggle .filter-option {
    display: inline-flex;
    align-items: center;
}

.customSelect.bootstrap-select .btn.dropdown-toggle::after {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../images/icon-select-arrow.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border: none;
    height: 36px;
    margin: 0;
    width: 36px;
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled).active {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #eee;
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: none !important;
    outline: none !important;
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled):active {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #eee;
}

.customSelect.bootstrap-select .btn.dropdown-toggle.btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
    outline: none !important;
}

.customSelect.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    outline: none !important;
    box-shadow: 0 0 2px rgba(38, 190, 85, 0.5);
}

.customSelect.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: none !important;
    outline: none !important;
    box-shadow: 0 0 2px rgba(38, 190, 85, 0.5);
}

.customSelect.bootstrap-select .dropdown-menu:not(.inner) {
    border-bottom: 1px solid #E7E7E7;
    border-radius: 10px;
    min-width: 150px;
    text-align: right;
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #E7E7E7;
    color: #9A9A9A;
    font-size: 14px;
    line-height: 1.3;
    padding: 7px 25px;
    position: relative;
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item span.text {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item:hover {
    color: #333333;
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item:active {
    color: #FD6600;
    background-color: rgba(253, 102, 0, 0.15);
}

.customSelect.bootstrap-select .dropdown-menu li:last-child .dropdown-item {
    border-bottom: none;
}

.customSelect.bootstrap-select .dropdown-menu .dropdown-item.active {
    color: #FD6600;
    background-color: rgba(253, 102, 0, 0.15);
}

.customSelect.bootstrap-select.show>.btn.dropdown-toggle.btn-light.dropdown-toggle {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #eee;
}

.customSelect.bootstrap-select.show>.btn.dropdown-toggle.btn-light.dropdown-toggle:focus {
    box-shadow: none !important;
    outline: none !important;
}

.datepicker.dropdown-menu table tr th {
    color: #042D50;
    font-weight: 600;
}

.datepicker.dropdown-menu table tr td,
.datepicker.dropdown-menu table tr th {
    border-radius: 10px;
    height: 35px;
    width: 35px;
}

.datepicker.dropdown-menu table tr td.active.active,
.datepicker.dropdown-menu table tr td.active.highlighted.active,
.datepicker.dropdown-menu table tr td.active.highlighted:active,
.datepicker.dropdown-menu table tr td.active:active {
    color: #fff;
    background-color: #FD6600;
    border-color: #FD6600;
}

.custom-control {
    margin: 0;
    padding-left: 45px;
}

.custom-control.custom-radiobox .custom-control-input-radio,
.custom-control.custom-radiobox .custom-control-label-radio::before {
    left: 0;
    height: 30px;
    position: absolute;
    top: 0;
    width: 30px;
}

.custom-control.custom-radiobox .custom-control-label-radio {
    font-size: 20px;
    font-size: 1.25rem;
    letter-spacing: 0.2px;
    font-weight: normal;
    margin: 0 !important;
    max-width: 100%;
    position: static;
    z-index: 1;
}

.custom-control.custom-radiobox .custom-control-label-radio:before {
    background-color: #ffffff;
    border-radius: 100%;
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
}

.custom-control.custom-radiobox .custom-control-label-radio::after {
    background: #ffffff;
    border: 1.5px solid #9AA7BC;
    border-radius: 100%;
    content: "";
    display: block;
    height: 20px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
}

.custom-control.custom-radiobox .custom-control-input-radio {
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio {
    color: #042D50;
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio::before {
    background-color: rgba(253, 102, 0, 0.33);
    color: #FD6600;
}

.custom-control.custom-radiobox .custom-control-input-radio:checked~.custom-control-label-radio::after {
    background-color: #FD6600;
    border-color: #FD6600;
}

.custom-control.custom-radiobox .custom-control-input-radio:not(:disabled):active~.custom-control-label-radio::before {
    color: #fff;
    background-color: #ffdfca;
    border-color: #ff6f0d;
}

.custom-control.custom-radiobox .custom-control-input-radio:disabled {
    cursor: not-allowed;
}

.custom-control.custom-radiobox .custom-control-input-radio:disabled~.custom-control-label-radio::before {
    background-color: #f9f9f9;
    opacity: 0.5;
}

.app_form {
    display: block;
    padding: 0;
    position: relative;
}

.app_form>.row:last-child {
    margin-bottom: -1rem;
}

.app_form .form-group {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 45px;
    padding: 0 15px;
}

.app_form .form-group .input-label {
    color: #9AA7BC;
    line-height: 1.3;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0 0 10px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
}

.app_form .form-group .heading_lbl {
    color: #042D50;
    line-height: 1.3;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
}

.app_form .form-group .heading_lbl span {
    color: #999999;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 500;
}

.app_form .form-group p {
    font-size: 16px;
    font-size: 1rem;
    color: #999999;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 15px;
    width: 100%;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]),
.app_form .form-group textarea {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #cccccc;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    height: 60px;
    line-height: 1.3;
    padding: 10px 15px;
    position: relative;
    transform: all 0.25s ease;
    width: 100%;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file])::-webkit-input-placeholder,
.app_form .form-group textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file])::-moz-placeholder,
.app_form .form-group textarea::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):-ms-input-placeholder,
.app_form .form-group textarea:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):-moz-placeholder,
.app_form .form-group textarea:-moz-placeholder {
    /* Firefox 18- */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):hover,
.app_form .form-group textarea:hover {
    border-color: #999999;
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):focus,
.app_form .form-group textarea:focus {
    border-color: #042D50;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
}

.app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]).num_text,
.app_form .form-group textarea.num_text {
    font-weight: 400;
}

.app_form .form-group textarea {
    min-height: 210px;
}

.app_form .form-group .has-error {
    margin-top: 5px;
    position: relative;
    width: 100%;
}

.app_form .form-group .input-group.date .input-group-addon {
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 66px;
    z-index: 10;
}

.app_form .form-group .input-group.date .input-group-addon .calIcon {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../images/ico-calender.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    display: inline-block;
    height: 30px;
    width: 28px;
}

.app_form .form-group .customSelect.bootstrap-select .btn.dropdown-toggle {
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #cccccc;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    height: 60px;
    line-height: 1.3;
    padding: 10px 15px;
    position: relative;
    transform: all 0.25s ease;
}

.app_form .form-group .customSelect.bootstrap-select .btn.dropdown-toggle:hover {
    border-color: #999999;
}

.app_form .form-group .customSelect.bootstrap-select.show .btn.dropdown-toggle {
    border-color: #042D50;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
}

.app_form .form-group .input-group {
    flex-wrap: nowrap;
    width: 100%;
}

.app_form .form-group .input-group .input-group-text {
    font-size: 16px;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #999999;
    padding: 0.9375rem 1.25rem;
}

.app_form .form-group .radio_group {
    margin-top: 20px;
}

.app_form .form-group .icon_before_input {
    display: inline-block;
    position: relative;
    width: 100%;
}

.app_form .form-group .icon_before_input input:not([type=checkbox]):not([type=radio]):not([type=file]) {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 2;
}

.app_form .form-group .icon_before_input::before {
    background-color: rgba(0, 0, 0, 0);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.app_form .form-group .icon_before_input.icon-loc::before {
    /* background-image: url(/assets//img/icon-loc-current.png); */
    height: 30px;
    width: 30px;
}

@media (-webkit-min-device-pixel-ratio: 1.3),
(min-device-pixel-ratio: 1.3),
(min-resolution: 1.3dppx) {
    .app_form .form-group .icon_before_input.icon-loc::before {
        /* background-image: url("/assets//img/icon-loc-current@2x.png"); */
        background-size: 100% auto;
    }
}

@media screen and (max-width: 991px) {
    .app_form .form-group {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 540px) {
    .app_form .form-group .input-label {
        font-size: 14px;
    }

    .app_form .form-group .heading_lbl {
        font-size: 20px;
    }

    .app_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]),
    .app_form .form-group textarea {
        font-size: 14px;
        height: 50px;
    }
}

* {
    box-sizing: border-box;
}

body {
    background-color: #F2F5FA;
    font-family: "Poppins", sans-serif !important;
}

.btn-default {
    background-color: #F8F9FB;
    border-radius: 12px;
    border-radius: 11px;
    border: 1px solid #E7EAEF;
    box-shadow: none;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    letter-spacing: 0;
    min-height: initial;
    min-width: initial;
    padding: 5px 15px;
}

.btn-default .ico {
    align-items: center;
    background-color: rgba(2, 59, 78, 0.15);
    border-radius: 9px;
    border: none;
    display: flex;
    height: 32px;
    justify-content: center;
    overflow: hidden;
    margin: 0 -11px 0 10px;
    position: relative;
    width: 32px;
}

.btn-default .ico::before {
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: auto;
    content: "";
    display: inline-block;
}

.btn-default .ico.playicon::before {
    background-image: url("../../images/play_icon.png");
    height: 18px;
    width: 18px;
}

.btn-default .ico.ico_delete::before {
    background-image: url("../../images/ico_delete_red.png");
    height: 18px;
    width: 15px;
}

.btn-default .ico.ico_plus::before {
    background-image: url("../../images/ico-add-circle.png");
    height: 20px;
    width: 20px;
}

.btn-default .ico.ico_tick_sml::before {
    background-image: url("../../images/ico-tick-sml.png");
    height: 12px;
    width: 18px;
}

.btn-default.btn-blank {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #7E7E7E;
}

.btn-default:hover,
.btn-default:focus {
    background-color: #F8F9FB;
    box-shadow: none;
    color: #333;
}

.btn-default.btn-delete {
    background-color: rgba(240, 97, 80, 0.1);
    border: none;
    color: #F06150;
}

.btn-default.btn-delete .ico {
    background-color: rgba(240, 97, 80, 0.15);
}

.btn-default.btn-primary {
    background-color: #FD6600;
    border: none;
    color: #ffffff;
}

.btn-default.btn-primary .ico {
    background-color: rgba(255, 255, 255, 0.33);
    border-radius: 11px;
}

.btn-default.btn-warning {
  background-color: #FFBB33;
  border: none;
  color: #333333;        
}

.btn-default.btn-warning .ico {
  background-color: rgba(255, 255, 255, 0.15);      
}

.btn-default.btn-danger {
  background-color: #F06150;
  border: none;
  color: #ffffff;       
}

.btn-default.btn-danger .ico {
  background-color: rgba(255, 255, 255, 0.15);      
}

.w100 {
    width: 100% !important;
}

td,
th {
    padding: 10px 5px;
}

th {
    position: relative;
}

th:first-child::before,
th:last-child::before {
    background-color: #FCFCFC;
    content: "";
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 100px;
    z-index: 1;
}

th:last-child::before {
    left: 100%;
    right: auto;
}

td {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    position: relative;
}

td a {
    color: #333333;
    font-weight: 500;
}

td a:hover {
    text-decoration: underline;
}

.col,
.container {
    padding: 0 15px;
}

.row .col {
    padding: 0 15px;
}

.card .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
    flex-wrap: wrap;
}

.card .card-header:first-child {
    border-radius: none;
}

.app_checkBox [type=checkbox]+span:not(.lever)::before {
    border: 2px solid #BBB;
    border-radius: 4px;
}

.app_checkBox [type=checkbox]+span:not(.lever) {
    padding: 0;
    height: 18px;
    width: 18px;
    display: flex;
}

.app_checkBox [type=checkbox]+span:not(.lever)::before,
.app_checkBox [type=checkbox]+span:not(.lever)::after {
    margin-top: 0;
}

.app_checkBox [type=checkbox]:checked+span:not(.lever)::before {
    border-top: 2px solid transparent;
    border-right: 2px solid #fd6600;
    border-bottom: 2px solid #fd6600;
    border-left: 2px solid transparent;
}

#tableScroll.ps {
    height: 600px;
    position: relative;
    display: flex;
    margin: 0 -15px;
    padding: 0 15px;
}

.content-wrapper-before.gradient-45deg-indigo-purple {
    background: rgba(0, 0, 0, 0) !important;
}

footer.page-footer.gradient-45deg-indigo-purple {
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
    text-align: center;
    color: #000000;
}

footer.page-footer.gradient-45deg-indigo-purple .right {
    display: none;
}

footer.page-footer.gradient-45deg-indigo-purple .footer-copyright {
    color: #000000;
    font-weight: 400;
}

footer.page-footer.gradient-45deg-indigo-purple .footer-copyright a {
    color: #000000;
}

.sidenav-main {
    background: #ffffff;
    border-radius: 37px;
    box-shadow: 5px 5px 20px rgba(159, 159, 159, 0.25);
    display: flex;
    height: calc(100vh - 136px) !important;
    padding: 30px 0;
    top: 105px;
}

.sidenav-main .brand-sidebar {
    display: none;
}

.sidenav-main ul#slide-out.sidenav {
    background: transparent;
    border: none;
    box-shadow: none;
    display: inline-block;
    height: 100% !important;
    left: initial;
    padding: 0;
    position: relative;
    top: initial;
}

.sidenav-main .sidenav li a {
    align-items: center;
    color: #023B4E;
    display: inline-flex;
    font-size: 16px;
    height: auto;
    justify-content: flex-start;
    line-height: 1.3;
    margin: 0;
    padding: 10px 15px !important;
    width: 100%;
}

.sidenav-main .sidenav li a::before {
    transform: translateY(-50%);
    transition: all 0.25s ease;
    background-color: #FD6600;
    content: "";
    display: none;
    height: 32px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 3px;
}

.sidenav-main .sidenav li a i.navico {
    align-items: center;
    background-color: rgba(2, 59, 78, 0.12);
    border-radius: 9px;
    border: 1px solid rgba(2, 59, 78, 0.12);
    display: flex;
    height: 32px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 32px;
}

.sidenav-main .sidenav li a i.navico::before {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../images/icos-leftbar-spirit.png");
    background-repeat: no-repeat;
    background-size: auto;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sidenav-main .sidenav li a i.navico.dash::before {
    background-position: -1px -1px;
}

.sidenav-main .sidenav li a i.navico.user::before {
    background-position: -48px -1px;
}

.sidenav-main .sidenav li a i.navico.student::before {
    background-position: -95px -1px;
}

.sidenav-main .sidenav li a i.navico.course::before {
    background-image: none;
    color: #023B4E;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    font-style: normal;
}

.sidenav-main .sidenav li a i.navico.SAT::before {
    content: "S";
}

.sidenav-main .sidenav li a i.navico.ACT::before {
    content: "A";
}

.sidenav-main .sidenav li a i.navico.math::before {
    content: "M";
}

.sidenav-main .sidenav li a i.navico.grmr::before {
    content: "G";
}

.sidenav-main .sidenav li a i.navico.read::before {
    content: "R";
}

.sidenav-main .sidenav li a i.navico.help::before {
    background-position: -143px -1px;
}

.sidenav-main .sidenav li a i.navico.recyclebeen::before {
    background-position: -189px -1px;
}

.sidenav-main .sidenav li a i.navico.bucket::before {
    background-position: -236px -1px;
}

.sidenav-main .sidenav li a i.navico.settings::before {
    background-position: -283px -1px;
}

.sidenav-main .sidenav li a i.navico.reported-problems::before {
    background-image: url('../../images/ico-reported-problems.png');
    background-repeat: no-repeat;
    background-size: 90% !important;
    background-position: center !important;
}

.sidenav-main .sidenav li a i.navico.reported-problems {
    min-width: 32px !important;
}
.sidenav-main .sidenav li a span.badge.pill {
    margin: 0 0 0 auto !important;
    position: relative;
    right: initial;
}

.sidenav-main .sidenav li a.active {
    color: #FD6600;
    font-weight: 500;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
}

.sidenav-main .sidenav li.active a,
.sidenav-main .sidenav li a.active {
    color: #FD6600;
    font-weight: 500;
}

.sidenav-main .sidenav li.active a::before,
.sidenav-main .sidenav li a.active::before {
    display: inline-block;
}

.sidenav-main .sidenav li.active a i.navico,
.sidenav-main .sidenav li a.active i.navico {
    background-color: #FFECE0;
    border-color: rgba(253, 102, 0, 0.3);
    box-shadow: 0px 4px 8px rgba(253, 102, 0, 0.35);
}

.sidenav-main .sidenav li.active a i.navico.dash,
.sidenav-main .sidenav li a.active i.navico.dash {
    background-position: 0 bottom;
}

.sidenav-main .sidenav li.active a i.navico.dash::before,
.sidenav-main .sidenav li a.active i.navico.dash::before {
    background-position: -1px bottom;
}

.sidenav-main .sidenav li.active a i.navico.user::before,
.sidenav-main .sidenav li a.active i.navico.user::before {
    background-position: -48px bottom;
}

.sidenav-main .sidenav li.active a i.navico.student::before,
.sidenav-main .sidenav li a.active i.navico.student::before {
    background-position: -95px bottom;
}

.sidenav-main .sidenav li.active a i.navico.course::before,
.sidenav-main .sidenav li a.active i.navico.course::before {
    color: #FD6600;
}

.sidenav-main .sidenav li.active a i.navico.SAT::before,
.sidenav-main .sidenav li a.active i.navico.SAT::before {
    content: "S";
}

.sidenav-main .sidenav li.active a i.navico.ACT::before,
.sidenav-main .sidenav li a.active i.navico.ACT::before {
    content: "A";
}

.sidenav-main .sidenav li.active a i.navico.math::before,
.sidenav-main .sidenav li a.active i.navico.math::before {
    content: "M";
}

.sidenav-main .sidenav li.active a i.navico.grmr::before,
.sidenav-main .sidenav li a.active i.navico.grmr::before {
    content: "G";
}

.sidenav-main .sidenav li.active a i.navico.read::before,
.sidenav-main .sidenav li a.active i.navico.read::before {
    content: "R";
}

.sidenav-main .sidenav li.active a i.navico.help::before,
.sidenav-main .sidenav li a.active i.navico.help::before {
    background-position: -143px bottom;
}

.sidenav-main .sidenav li.active a i.navico.recyclebeen::before,
.sidenav-main .sidenav li a.active i.navico.recyclebeen::before {
    background-position: -189px bottom;
}

.sidenav-main .sidenav li.active a i.navico.bucket::before,
.sidenav-main .sidenav li a.active i.navico.bucket::before {
    background-position: -236px bottom;
}

.sidenav-main .sidenav li.active a i.navico.settings::before,
.sidenav-main .sidenav li a.active i.navico.settings::before {
    background-position: -283px bottom;
}

.sidenav-main .sidenav li.active a i.navico.reported-problems::before,
.sidenav-main .sidenav li a.active i.navico.reported-problems::before {
    background-image: url('../../images/ico-reported-problems-active.png');
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: 90% !important;
}


.sidenav-main .sidenav li.active i.navico.reported-problems,
.sidenav-main .sidenav li a.active i.navico.reported-problems {
    min-width: 32px !important;
    background-size: 90% !important;
}


.brand-sidebar {
    box-shadow: none;
    width: 260px;
}

.brand-sidebar .logo-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    line-height: 1 !important;
    padding: 15px 20px;
}

.brand-sidebar .logo-wrapper .brand-logo .hide-on-collapse {
    display: block;
}

.brand-sidebar .logo-wrapper .brand-logo .show-on-collapse {
    display: none;
}

.brand-sidebar .logo-wrapper a.brand-logo {
    display: inline-flex;
    padding: 0;
    position: relative;
    right: initial;
    top: initial;
}

.brand-sidebar .logo-wrapper a.brand-logo img {
    height: 33px;
}

.brand-sidebar .logo-wrapper a.navbar-toggler {
    display: inline-flex;
    margin-right: 20px;
    padding: 0;
    position: relative;
    right: initial;
    top: initial;
}

.brand-sidebar .logo-wrapper a.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.brand-sidebar .logo-wrapper a.navbar-toggler .hamburger_icon {
    background-color: rgba(253, 102, 0, 0.15);
    background-image: url("../../images/menu-hamburger.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 9px;
    display: inline-flex;
    height: 32px;
    overflow: hidden;
    position: relative;
    text-indent: 100px;
    width: 32px;
}

.nav-collapsed .brand-sidebar .logo-wrapper a.navbar-toggler {
    visibility: visible;
    opacity: 1;
}

.nav-collapsed .brand-sidebar .logo-wrapper a.navbar-toggler .hamburger_icon {
    background-image: url("../../images/menu-back.png");
}

.nav-collapsed.sidenav-main .sidenav li a i.navico {
    margin-right: 0;
}

.nav-expanded:not(.nav-lock):not(.sideNav-lock) .brand-sidebar .logo-wrapper a.navbar-toggler .hamburger_icon {
    background-image: url("../../images/menu-back.png");
}

.navbar.navbar-fixed {
    height: 70px;
    padding: 0;
}

.navbar nav.navbar-main {
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    line-height: 70px;
    height: 70px;
    padding: 0 15px;
}

.navbar nav.navbar-main .nav-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar nav.navbar-main .nav-wrapper .brand-sidebar {
    box-shadow: none;
    height: 100%;
    left: initial;
    margin: 0;
    position: relative;
    top: initial;
    width: 260px;
}

.navbar nav.navbar-main .nav-wrapper .brand-sidebar .logo-wrapper {
    padding: 15px;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list {
    height: 100%;
    position: relative;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list>li,
.navbar nav.navbar-main .nav-wrapper .navbar-list>li>a {
    display: inline-flex;
    height: 100%;
    flex-wrap: wrap;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li {
    position: relative;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li.notification_col {
    margin-right: 5px;
    padding-right: 20px;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li.notification_col::after {
    background-color: #DCDEE6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #aeaeae;
    content: "";
    height: 28px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li.notification_col .notification-badge {
    align-items: center;
    background-color: #FD6600;
    box-shadow: 0 3px 6px #FD6600;
    display: inline-flex;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    padding: 0;
    width: 20px;
    position: absolute;
    top: -7px;
    right: -7px;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li a {
    color: #333333;
    align-items: center;
    justify-content: center;
    position: relative;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li #profile-dropdown.dropdown-content {
    width: 199px !important;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li .dropdown-content {
    border-radius: 15px;
    border: 1px solid #ECECEC;
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    left: auto !important;
    right: 0 !important;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader h3,
.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader h4,
.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader h5,
.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown .dropheader .badge {
    align-items: center;
    background: #FD6600;
    border-radius: 100px;
    box-shadow: none;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    height: initial;
    justify-content: center;
    line-height: 1.3;
    padding: 2px 10px;
    position: relative;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list li #notifications-dropdown li>time {
    margin-left: 0;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list i,
.navbar nav.navbar-main .nav-wrapper .navbar-list a.sidenav-trigger,
.navbar nav.navbar-main .nav-wrapper .navbar-list a.sidenav-trigger i {
    line-height: initial;
    height: initial;
    display: inline-flex;
    position: relative;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button {
    flex-wrap: nowrap;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .user-avatar {
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #e3e3e3;
    flex: 0 0 38px;
    height: 38px;
    line-height: initial;
    margin-right: 10px;
    overflow: hidden;
    width: 38px;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .user-avatar img {
    -webkit-object-position: center center;
    object-position: center center;
    -webkit-object-fit: cover;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .avatar-name {
    display: flex;
    line-height: 1.3;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .avatar-name .text-truncate {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 5px;
    white-space: nowrap;
}

.navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .avatar-name .ico_arrow {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../images/ico-chevron-down.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    flex: 0 0 24px;
    height: 24px;
    overflow: hidden;
    width: 24px;
}

.card {
    border: 1px solid #F1F1F1;
    border-radius: 27px;
    box-shadow: 5px 5px 20px rgba(159, 159, 159, 0.25);
    margin: 0 0 30px;
}

.card .card-content {
    padding: 30px;
}

.card .card-content .section_title {
    color: #000000;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 20px;
    text-transform: none;
}

.card .card-content .card-header {
    align-items: center;
    justify-content: space-between;
}

.card .card-content .card-header .section_title {
    margin: 10px 0;
}

.card .card-content .card-header .filtersec {
    flex-wrap: wrap;
    align-items: center;
}

.card .card-content .card-header .filtersec .input-field {
    margin: 5px 0;
    padding: 0 10px;
}

.card .card-content .card-header .filtersec .select-wrapper {
    background-color: #F8F9FB;
    border-radius: 12px;
    border: 1px solid #E7EAEF;
}

.card .card-content .card-header .filtersec .select-wrapper input.select-dropdown {
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    letter-spacing: 0;
    margin: 0;
    min-height: initial;
    min-width: initial;
    padding: 2px 41px 2px 15px;
    width: 130px;
    width: 95px;
}

.card .card-content .card-header .filtersec .select-wrapper .caret {
    background-color: rgba(2, 59, 78, 0.15);
    background-image: url("../../images/ic-chevron-downx32.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 11px;
    display: inline-block;
    fill: transparent;
    height: 32px;
    opacity: 1;
    right: 4px;
    width: 32px;
}

.card .card-content .card-header .filtersec .select-wrapper .dropdown-content {
    border-radius: 15px;
    border: 1px solid #ECECEC;
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    left: auto !important;
    min-width: 150px;
    right: -2px !important;
    top: -2px !important;
}

.card .card-content .card-header .filtersec .select-wrapper .dropdown-content li>a,
.card .card-content .card-header .filtersec .select-wrapper .dropdown-content li>span {
    color: #7a7a7a;
}

.card .card-content .card-header .filtersec .switch_ele>.switch_txt {
    font-size: 14px;
    font-weight: 300;
    color: #333;
    line-height: 32px;
}

.card .card-content .card-header .filtersec .switch_ele label {
    margin-left: 15px !important;
    position: relative;
}

.card .card-content .card-header .filtersec .switch_ele label .lever {
    background-color: #F3F3F3;
    width: 70px;
    width: 64px;
    height: 32px;
    margin: 0;
}

.card .card-content .card-header .filtersec .switch_ele label .lever::after {
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
}

.card .card-content .card-header .filtersec .switch_ele label .lever::before {
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
    background-color: rgba(92, 219, 149, 0.15);
}

.card .card-content .card-header .filtersec .switch_ele label input[type=checkbox]:checked+.lever {
    background-color: #00C789;
}

.card .card-content .card-header .filtersec .switch_ele label input[type=checkbox]:checked+.lever::after {
    background-color: #ffffff;
    left: 42px;
    left: 36px;
}

.card .card-content .card-header .filtersec .switch_ele label input[type=checkbox]:checked+.lever::before {
    left: 42px;
    left: 36px;
}

.card .card-content .card-header .filtersec .switch_ele label input[type=checkbox]:checked+.lever+.stch_lbl.yeslbl {
    color: #fff;
    left: 10px;
    right: auto;
    opacity: 1;
    transform: scale(1);
}

.card .card-content .card-header .filtersec .switch_ele label input[type=checkbox]:checked+.lever+.yeslbl+.stch_lbl.nolbl {
    opacity: 0;
    transform: scale(0);
}

.card .card-content .card-header .filtersec .switch_ele label .stch_lbl {
    color: #F06150;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    line-height: 32px;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 0;
    transition: all 0.25s ease;
}

.card .card-content .card-header .filtersec .switch_ele label .stch_lbl.yeslbl {
    opacity: 0;
}

.card .card-content .card-header .filtersec .switch_ele input[type=checkbox]:checked:not(:disabled).tabbed:focus~.lever::before {
    transform: scale(2.4);
    background-color: rgba(136, 136, 136, 0.15);
}

.card .card-content .card-header .filtersec .switch_ele input[type=checkbox]:checked:not(:disabled)~.lever:active::before {
    transform: scale(2.4);
    background-color: rgba(136, 136, 136, 0.15);
}

.dash_top_section {
    margin-top: 35px;
}

.dash_title {
    color: #023B4E;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
    width: 100%;
}

.assignment_sec table.dataTable thead {
    height: 0;
    visibility: hidden;
    opacity: 0;
}

.assignment_sec table.dataTable thead th {
    padding: 0;
}

.assignment_sec table.dataTable thead th:last-child {
    max-width: 142px;
    width: 142px !important;
}

.assignment_sec table.dataTable tbody td:last-child {
    padding-right: 0 !important;
}

.assignment_sec table.dataTable>tbody>tr.child span.dtr-title {
    display: none;
}

.assignment_sec table.dataTable>tbody>tr.child td.child ul.dtr-details {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 -15px;
    width: auto;
}

.assignment_sec table.dataTable>tbody>tr.child td.child ul.dtr-details>li {
    border: none;
    display: inline-flex;
    padding: 5px 15px !important;
    width: auto;
}

.assignment_sec .dataTables_wrapper {
    overflow: hidden;
    width: 100%;
}

table.dataTable {
    margin-bottom: 30px;
    width: 100% !important;
}

table.dataTable.no-footer {
    border: none;
}

table.dataTable.nowrap td {
    white-space: initial;
}

table.dataTable tr {
    background-color: #f9f9f9;
    background-color: rgba(0, 0, 0, 0) !important;
}

table.dataTable td,
table.dataTable th {
    padding: 18px 5px;
    vertical-align: middle;
}

table.dataTable td:first-child,
table.dataTable th:first-child {
    padding-left: 0;
}

table.dataTable thead th,
table.dataTable tfoot th {
    background-color: #FCFCFC;
    border: none;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    white-space: nowrap;
}

table.dataTable tfoot th:first-child::before,
table.dataTable tfoot th:last-child::before {
    content: none;
}

table.dataTable>tbody>tr.child td.child {
    padding: 20px 0;
}

table.dataTable>tbody>tr.child td.child ul.dtr-details {
    width: 100%;
}

table.dataTable>tbody>tr.child span.dtr-title {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 2px;
    white-space: nowrap;
    width: 100%;
}

table.dataTable td {
    border-bottom: 1px solid #AFAFAF;
    border-top: none !important;
    color: #333333;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
}

table.dataTable td .tblbdg {
    background-color: rgba(27, 184, 98, 0.1);
    border-radius: 100px;
    color: #1BB862;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    padding: 7px 20px;
    position: relative;
    white-space: nowrap;
}

table.dataTable td .tblbdg.active {
    background-color: rgba(27, 184, 98, 0.1);
    color: #1BB862;
}

table.dataTable td .tblbdg.inactive {
    background-color: rgba(126, 126, 126, 0.1);
    color: #7E7E7E;
}

table.dataTable td .actn_btngrp {
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

table.dataTable td .actn_btngrp .btn {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

table.dataTable td .actn_btngrp .btn:first-child {
    margin-left: 0;
}

table.dataTable td .actn_btngrp .btn.btn-ico {
    border-radius: 7px;
    flex: 0 0 32px;
    height: 32px;
    padding: 0;
    position: relative;
    width: 32px;
}

table.dataTable td .actn_btngrp .btn.btn-ico .ico {
    display: inline-block;
    height: 24px;
    width: 24px;
}

table.dataTable td .actn_btngrp .btn.btn-ico .ico.delete {
    background-image: url("../../images/ico_tbl_delete.png");
}

table.dataTable td .actn_btngrp .btn.btn-ico .ico.cross {
    background-image: url("../../images/ico_tbl_cross.png");
}

table.dataTable td .actn_btngrp .btn.btn-ico .ico.tick {
    background-image: url("../../images/ico_tbl_check.png");
}

table.dataTable td .actn_btngrp .btn.btn-ico .ico.link {
    background-image: url("../../images/ico_tbl_link2.png");
}

table.dataTable td .actn_btngrp .btn.btn-txt {
    background-color: #F8F9FB;
    border-color: #E7EAEF;
    border-radius: 14px;
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 15px;
    white-space: nowrap;
}

table.dataTable td .actn_btngrp .btn:hover {
    opacity: 0.7;
}

table.dataTable+.dataTables_info {
    color: #000000;
    font-size: 16px;
}

table.dataTable+.dataTables_info+.dataTables_paginate {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button {
    align-items: center;
    border-radius: 10px;
    border: 1px solid #AFAFAF;
    display: inline-flex;
    height: 42px;
    justify-content: center;
    margin-right: 10px;
    width: 42px;
}

table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button.previous,
table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button.next {
    border: none;
    width: auto;
}

table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button.next {
    margin-right: 10px;
}

table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button:hover:not(.disabled):not(.current) {
    background: rgba(253, 102, 0, 0.3);
    border-color: rgba(0, 0, 0, 0);
    color: #FD6600 !important;
}

table.dataTable+.dataTables_info+.dataTables_paginate .paginate_button.current {
    color: #ffffff !important;
    border: 1px solid #FD6600;
    background: #FD6600;
    font-weight: 500;
    box-shadow: 0 7px 14px rgba(253, 102, 0, 0.3);
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child,
table.dataTable.dtr-inline.collapsed>thead>tr[role=row]>td:first-child,
table.dataTable.dtr-inline.collapsed>thead>tr[role=row]>th:first-child {
    padding-left: 47px;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child::before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child::before {
    align-items: center;
    background-color: rgba(49, 177, 49, 0.15);
    border-radius: 11px;
    border: none;
    box-shadow: none;
    color: #31B131;
    display: flex;
    font-family: "Poppins", sans-serif !important;
    font-size: 24px;
    height: 32px;
    justify-content: center;
    left: 0;
    line-height: 32px;
    top: 50%;
    width: 32px;
    transform: translateY(-50%);
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.dataTables_wrapper {
    margin-bottom: 60px;
    position: relative;
}

.table-responsive.dataTables_wrapper .dataTables_wrapper {
    position: static;
}

.table-responsive.dataTables_wrapper .dataTables_paginate {
    position: absolute;
}

.users_sec {
    border-radius: inherit !important;
    overflow: hidden;
}

.users_sec .usersTabs_wrap .tabs {
    border-bottom: 1px solid #AFAFAF;
    height: initial;
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
}

.users_sec .usersTabs_wrap .tabs .indicator {
    background-color: #FD6600;
    border-radius: 50px;
    height: 5px;
}

.users_sec .usersTabs_wrap .tabs .tab {
    height: 84px;
    line-height: 84px;
    padding: 0 30px;
    width: auto;
    display: inline-flex;
    float: none;
    flex-grow: initial;
    position: static;
}

.users_sec .usersTabs_wrap .tabs .tab a {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}

.users_sec .usersTabs_wrap .tabs .tab a:focus,
.users_sec .usersTabs_wrap .tabs .tab a:focus.active {
    background-color: rgba(253, 102, 0, 0.1);
}

.users_sec .usersTabs_wrap .tabs .tab a.active {
    color: #000000;
    font-weight: 600;
}

.users_sec .usersTabs_wrap .tabs_content {
    padding: 30px;
}

.users_sec .table_header_actions {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 30px;
}

.users_sec .table_header_actions .btn-default {
    margin: 5px 0;
}

.users_sec table.dataTable td.actions,
.users_sec table.dataTable th.actions {
    max-width: 202px !important;
    padding-right: 0;
    width: 100px;
}

.users_sec table.dataTable.students_table td.actions,
.users_sec table.dataTable.students_table th.actions {
    max-width: 75px !important;
}

.users_sec table.dataTable.tutors_table td.actions,
.users_sec table.dataTable.tutors_table th.actions {
    max-width: 165px !important;
}

.modal {
    background: #fff;
    border-radius: 32px;
    max-height: initial;
    max-width: 90%;
    overflow: hidden;
}

.modal.modal-sml {
    width: 530px;
}

.modal.linkModal {
    overflow: visible;
    width: 990px;
}

.modal .modal-content {
    padding: 50px;
}

.modal .modal-content .mdl_ttl {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0 0 5px !important;
    position: relative;
    position: relative;
    width: 100%;
}

.modal .modal-content .mdl_subttl {
    color: #7E7E7E;
    font-size: 16px;
    line-height: 1.3;
}

.modal .modal-content form.addUser_form {
    margin-top: 40px;
}

.modal .modal-content form.addUser_form>.row,
.modal .modal-content form.addUser_form>.form-row {
    margin: 0 -15px;
}

.modal .modal-content form.addUser_form>.row:last-child,
.modal .modal-content form.addUser_form>.form-row:last-child {
    margin-bottom: -30px;
}

.modal .modal-content form.addUser_form .form-group {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 0 15px;
}

.modal .modal-content form.addUser_form .form-group label {
    color: #333333;
    font-size: 16px;
    line-height: 1.3;
    margin: 0 0 5px;
    position: relative;
    width: 100%;
}

.modal .modal-content form.addUser_form .form-group label .rqrd_str {
    color: #FD6600;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input) {
    background-color: #ffffff;
    border: 1px solid #AFAFAF;
    border-radius: 16px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    height: 50px;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
    padding: 5px 30px;
    position: relative;
    transform: all 0.25s ease;
    width: 100%;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input)::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input)::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input):-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input):-moz-placeholder {
    /* Firefox 18- */
    font-weight: 400;
    opacity: 1;
    color: #999999;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input):hover {
    border-color: #999999;
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input):focus {
    border-color: #042D50;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(51, 51, 51, 0.1);
}

.modal .modal-content form.addUser_form .form-group input:not([type=checkbox]):not([type=radio]):not([type=file]):not(.chips .input).num_text {
    font-weight: 400;
}

.modal .modal-content form.addUser_form .form-group .chips {
    flex-wrap: wrap;
}

.modal .modal-content form.addUser_form .form-group.btn-grup {
    justify-content: flex-end;
}

.modal .modal-content form.addUser_form .form-group.btn-grup .btn-default {
    margin-right: 30px;
}

.modal .modal-content form.addUser_form .form-group.btn-grup .btn-default:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1199px) {
    .card .card-content {
        padding: 30px 15px;
    }

    .card .card-content .card-header {
        flex-wrap: wrap;
    }

    .card .card-content .card-header .filtersec {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 991px) {
    .brand-sidebar .logo-wrapper a.navbar-toggler {
        display: none;
    }

    .sidenav-main {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        height: calc(100vh - 70px) !important;
        padding: 0;
        top: 70px;
        width: 0;
    }

    .sidenav-main .btn-sidenav-toggle {
        background-color: rgba(253, 102, 0, 0.15);
        background-image: url("../../images/menu-hamburger.png");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        border-radius: 9px;
        bottom: 100%;
        box-shadow: none;
        display: inline-flex;
        height: 32px;
        left: 15px;
        margin: 0 0 18px;
        overflow: hidden;
        position: absolute;
        text-indent: 100px;
        top: auto;
        width: 32px;
    }

    .sidenav-main ul#slide-out.sidenav {
        background: #ffffff;
        border-radius: 0 37px 37px 0;
        position: fixed;
        top: 0;
        padding: 30px 0;
    }
}

@media screen and (max-width: 767px) {
    .navbar nav.navbar-main .nav-wrapper .brand-sidebar {
        width: auto;
    }

    .navbar nav.navbar-main .nav-wrapper .navbar-list li.notification_col {
        padding-right: 10px;
    }

    .navbar nav.navbar-main .nav-wrapper .navbar-list i,
    .navbar nav.navbar-main .nav-wrapper .navbar-list a.sidenav-trigger,
    .navbar nav.navbar-main .nav-wrapper .navbar-list a.sidenav-trigger i {
        top: initial;
    }

    .navbar nav.navbar-main .nav-wrapper .navbar-list a.profile-button .avatar-name .text-truncate {
        display: none;
    }

    .brand-sidebar .logo-wrapper {
        padding: 0 0 0 47px !important;
    }

    .col,
    .container {
        padding: 0 0;
    }

    table.dataTable tbody th,
    table.dataTable tbody td {
        padding: 20px 10px;
    }

    .card .card-content {
        padding: 30px 15px;
    }
}

@media screen and (max-width: 540px) {
    .brand-sidebar .logo-wrapper .brand-logo .hide-on-collapse {
        display: none;
    }

    .brand-sidebar .logo-wrapper .brand-logo .show-on-collapse {
        display: block;
    }

    .navbar nav.navbar-main .nav-wrapper .brand-sidebar {
        width: auto;
    }

    .navbar nav.navbar-main .nav-wrapper .navbar-list li.notification_col {
        display: none;
        padding-right: 10px;
    }
}