@import '../../scss/mixins';

.admin-sidebar {
    @include sidebar();

    .bucket {
        > svg {
            &:hover {
                cursor: pointer;
                path {
                    fill: white;
                }
            }
        }
    }
}
