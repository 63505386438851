.select-main-div {
  position: relative;
  background-color: #f8f9fb;
  border-radius: 5px;
  border: 1px solid #e7eaef;
  box-shadow: none;
  min-height: 40px;
  height: 40px;
}

.select-header {
  box-sizing: border-box;
  width: auto;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-text {
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    letter-spacing: 0;
    margin: 0;
    min-height: 0;
    min-width: 0;
    padding: 2px 15px;
    width: 95px;
    display: inline-flex;
    align-items: center;
  }
}

.css-indicatorSeparator {
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.css-indicatorSeparator+div {
  background-color: rgba(2,59,78,.15);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAASlJREFUWEftlD1ywkAMhaU1VVLATeIyAw20DCHcIPgIORHODZiYocUNnpTmJjS4wqus1rHHgcR/FDTayj/Svve+lY1w54V31gcxIASEgBAQArUE1Gjum9/1mybyIAr4unq5iwE+pCsuosTxIF4fqxqaGKBiAyJfR4H374bDqYvQWyGCyzU61RP42oQ3GYDhy1Ih2kR2sYnEeb9KxuLY25lEA1sGdKB9YI3cZoC7n2djVGptkvUzDxBToiaFib/ET864Dj/vVXsEhfsMb/jLBJztcZSTm9sPvf9c1iXP3zc3wB08YI9piIBPGWawA5ZjbyvejkBuOZtyHxFfL1K2St6NQEkx/zx/HnUS70agHNvMhb2NtnHTM7+sazcDXVUq+sSAEBACQkAIfAOYIF8hM2l63gAAAABJRU5ErkJggg==);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  display: inline-block;
  fill: transparent;
  height: 32px;
  opacity: 1;
  margin: 0 4px;
  width: 32px;
}

.select-list-container {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  width: auto;
  left: auto;
  right: 0;
  margin-top: 5px;
  top: 100%;
  z-index: 99999;
}

.select-list {
  transition: opacity 1s ease-in;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;

  .select-list-item {
    cursor: pointer;
    width: inherit !important;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif !important;
    padding: 8px 15px;
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      background-color: #cce5ff;
    }
  }

  .active {
    background-color: #007bff !important;
    color: #ffffff;
  }
}

