@import '../../../../scss/variables';

.add-question {
    display: flex;
    justify-content: flex-end;
    width: 750px;

    .toggle {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        &:hover {
            background-color: #cfc4c4;
            cursor: pointer;
        }

        .svg-container {
            width: 15px;
            height: 15px;
            > svg {
                width: 15px;
                height: 15px;
            }
        }

        > p {
            margin: 0;
            margin-left: 25px;
        }
    }

    // Allows editor to scroll without enlarging
    .public-DraftEditor-content {
        height: 300px;
        max-height: 300px;
        overflow: auto;
    }
}
