.dialog {
  position: fixed;
  width: auto;
  top: 50%;
  left: 50%;
  max-height: 90%;
  transform: translateY(-50%) translateX(-50%);
  color: black;
  background-color: white;
  z-index: 10000;
  box-shadow: -1px 15px 16px -7px rgba(0, 0, 0, .9);
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  &.problem-set-dialog {
    width: 100%;
    max-width: 900px;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 25px;
    width: 25px;
    stroke: black;
    &:hover {
      cursor: pointer;
      stroke: #FFF;
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
    text-align: center;
    font-size: 22px;
    padding: 10px;
    // width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .svg-container {
        margin-right: 10px;
        > svg {
            width: 40px;
            height: 40px;
        }
    }
  }

  .body {
    // overflow-y: hidden;
    overflow-y: scroll;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.dialog.login {
    min-height: 450px;
}

.dialog.signup {
    min-height: 450px;
}

.shade {
  background-color: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.leftSideOfPopupCls .progress { 
    height: 36px;
    width: 140px;
    position: relative;
}

.progress-bar {
  background-color: #0bbd84;
  font-size: 16px;
}

.pubUnpubAdminCls {
  padding: 5px 13px
}

.resetProgressAdminCls{
  padding: 5px 20px
}

.leftSideOfPopupCls .progress span {
  position: absolute;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #185a1b;
}

.problemsetRightDataCls {
  border-left: 1px solid #929191;
  padding-left: 5px;
}

.videoSectionCls i{
  color: #f10606;
}

span.videoSectionCls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-top: 20px;
}

.progressAndResetDivCls{
  min-height: 55px;
}

.preview-question--inner.adjustUlInDialogExisting ul {
  margin: 10px;
  padding-left: 0;
}

.preview-question--inner.adjustUlInDialogExisting ul li .questions {
  padding-left: 20px;
  margin-top: 10px;
}

.preview-question--inner.adjustUlInDialogExisting ul li .questions h3 {
  font-size: 20px;
  font-weight: 400;
  color: #2b2b2b;
}

.videoEditorcls{
  padding: 5px;
  border: 1px solid #ccc;
}

.adjustVideoViewCls{
  padding: 8px;
}

.adjustStudentAreaCls{
  min-height: 250px;
}

.adjustSelectCls {
  width: 700px;
}


div.link-problem-set-main-div {
  & > div {
    ul.problem-set-lists {
      list-style: outside;
      & > li {
        display: flex;
        border: 1px solid #c7c7c7;
        border-radius: 10px;
        padding: 15px;
        margin: 0 10px 15px 0;
        cursor: pointer;
        list-style: none !important;
      }
    }
  }
}

div.link-question-main-div {
  min-width: 700px;
}

.hidden {
  display: none;
}