@import '../../../scss/variables';

.testQuestionsCls {
    .read-editor-container {
        width: 100%;
    }
}
.view-sat-test-question--inner {
    padding: 20px 15px;
    width: 100%;
    .view-sat-test-question--ans {
        margin-top: 20px;
        min-height: 200px;
        ul {
            padding: 0;
            list-style: none;
            margin: 0;
            li {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 10px;
                border-width: 2px 0 0;
                border-style: solid;
                border-color: #eaeaea;
                cursor: pointer;
                flex-wrap: wrap;
                & .cell{
                    flex-grow: 1;
                    &:first-child {
                        flex: 0 0 30px;
                        max-width: 30px;
                    }
                    &:last-child {
                        max-width: calc(100% - 30px);
                    }
                }
                .ques_listno {
                    & > span {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        color: #21cec6;
                        border: solid 2px #21cec6;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .ans-content, .ans-explanation {
                    padding-left: 15px;
                }
                .ans-content {
                    color: #333333;
                    font-size: 15px;
                    font-weight: 600;
                    padding-left: 10px;
                    -ms-flex-preferred-size: 0;
                    flex-basis: 0;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    max-width: 100%;
                }
                .ans-explanation {
                    color: #5a5a5a;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.5;
                    display: inline-block;
                    width: 100%;
                    margin-top: 10px;
                }
                &.active {       
                    background-color: #a8fffd8f;
                    border-color: #488e8c; 
                    border-width: 2px 0;         
                    .ques_listno {
                        & > span {
                            background-color: #FD6600;
                            color: #FFF;
                            border: solid 2px #FD6600;
                        }
                    }             
                }
                &.wrongAns {       
                    background-color: #e6c4c499;
                    border-color: #d05050ad; 
                    border-width: 2px 0;         
                    .ques_listno {
                        & > span {   
                            background-color: #bd2424;                                          
                            color: #FFF;
                            border: solid 2px #bd2424;   
                        }
                    }                                                     
                }
                &.rightAns {       
                    background-color: rgba(60,179,113, 0.15);
                    border-color: #3CB371; 
                    border-width: 2px 0;         
                    .ques_listno {
                        & > span {   
                            background-color: #3CB371;                                          
                            color: #FFF;
                            border: solid 2px #3CB371;   
                        }
                    }                                                 
                }
                &.selectedRightAns {
                    background-color: rgba(60,179,113, 0.15);
                    border-color: #3CB371 !important;
                    border-width: 2px 0;
                    .ques_listno {
                        & > span {
                            background-color: #FD6600;
                            color: #FFF;
                            border: solid 2px #FD6600;
                        }
                    }
                }
                &.selectedWrongAns {
                    background-color: #e6c4c499;
                    border-color: #bd2424 !important;
                    border-width: 2px 0;
                    .ques_listno {
                        & > span {
                            background-color: #FD6600;
                            color: #FFF;
                            border: solid 2px #FD6600;
                        }
                    }
                }
            };
        }
    }
    iframe {
        width: 690px;
        height: 388px;
    }
}
.sat-test-question-explanation {
    .explanationContent {
        background-color: #efefef;
        border: 2px solid #a5a2a29e;
        border-radius: 10px;
        padding: 15px;
        margin-top: 20px;
        .cell {
            flex-grow: 1;
            &:first-child {
                flex: 0 0 40px;
                max-width: 40px;
            }
            &:last-child {
                max-width: calc( 100% - 40px);
                padding-left: 10px;
                padding-top: 10px;
            }
        }
        .iconwrap {
            color: #6e6e6e;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            font-size: 35px;
            line-height: 1;
             & > i {
                color: inherit;
                font-size: inherit;
                line-height: inherit;
             }
        }
    }
}

.list-all-sat-test-questions > ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
}

.list-all-sat-test-questions > ul > li.question {
    width: 28px;
    padding: 8px 0;
    margin: 30px 0 0 1px;
    outline: 1px solid #047774;
    margin-left: 1px;
    position: relative;
    cursor: pointer;
    color: #05504e;
}

.list-all-sat-test-questions > ul > li.question.active {
    background-color: #a8fffd8f;
    border-color: #488e8c;
}

.disabledLiCls{
    opacity: 0.4;
    cursor: default !important;
}

.list-all-sat-test-questions > ul > li.question:not(.arrowCls)::after {
   content: "";
   display: block;
   position: absolute;
   bottom: 100%;
   left: 50%;
   height: 8px;
   width: 8px;
   border-radius: 100%;
   margin-left: -5px;
   margin-bottom: 10px;
   background-color: #fff;
   border: 1px solid #05504e
}

.list-all-sat-test-questions > ul > li.question.completeCls:not(.arrowCls)::after {
    background-color: #05504e;
    border: 1px solid #05504e
}

.questionUncertainCls::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: #ffbf00;
    position: absolute;
    right: 0;
    top: 0;
}

.sat-test-center-container {
    margin-left: auto !important;
    width: calc(#{$sat-test-container-width} - 40px) !important;
    margin-right: auto !important;
    max-width: 100%;
}
