.admin-topics {
    width: 100%;
    background-color: #def2f1;
    overflow-y: scroll;

    > ul {
        list-style: none;
        padding: 0 20px;
    }
    .form-container {
        display: flex;

        .topic-form {
            display: flex;
            align-items: center;
            cursor: pointer;
            .svg-container {
                margin-right: 10px;
                > svg {
                    width: 15px;
                }
            }
        }
    }


    // .options {
    //     display: flex;
    //     justify-content: space-around;
    //     width: 100%;
    //
    //     h3 {
    //         margin: 0;
    //     }
    // }
    //
    // .title {
    //     display: flex;
    //     padding: 10px;
    //     justify-content: space-between;
    //     position: relative;
    //
    //     > div {
    //         display: flex;
    //         width: 100%;
    //
    //         .switch {
    //             margin-left: 25px;
    //             display: flex;
    //             align-items: center;
    //
    //             > label {
    //                 margin-right: 10px;
    //             }
    //         }
    //
    //         > button {
    //             margin-left: 50px;
    //         }
    //
    //         .unlinked-container {
    //             flex: 1;
    //             display: flex;
    //             justify-content: flex-end;
    //         }
    //     }
    // }
}
