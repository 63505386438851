.switchButtonDiv {
  //display: flex;
  //margin: 0;
  & > div {
    .react-switch-handle {
      background-color: #f1f1f1 !important;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12) !important;
    }
    .switch_txt {
      align-items: center;
      color: #fff;
      display: inline-flex;
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      height: 100%;
      justify-content: flex-start;
      line-height: 1.5;
      padding: 0 0 2px;
      width: auto;
      &.checked {
        justify-content: flex-end;
      }
    }
  }
  & > .switch_txt {
    &.checked {
      color: rgb(0, 199, 137);
    }
    &.unchecked {
      color: rgb(240, 97, 80);
    }
  }
  & > div {
    .react-switch-bg {
      position: static !important;
      & > div {
        opacity: 1 !important;
        position: absolute !important;
        color: red !important;
        left: 0;
        right: auto !important;
        width: auto !important;

        &:last-child {
          left: auto;
          right: 0 !important;
        }
      }
    }
  }

  label {
    color: #333;
    display: none;
    font-size: 14px;
    font-weight: 300 !important;
    line-height: 32px;
    margin: 0 0 0 10px !important;
  }
}
