@import "../../../scss/variables";

.tooltip-z-index {
  z-index: $tooltip-z-index !important;
}

.tooltip-tag {
  color: #3b5998;
  text-decoration: underline;
}

.tooltipContent {
  max-width: 300px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.tooltip-main-div {
  max-width: 510px;
  max-height: 200px;
  overflow-y: scroll;
  min-width: 150px;
}
