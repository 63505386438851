// Colors
$black: #17252A;
$dark-teal: #2B7A78;
$teal: #3aafa9;
$light-teal: #def2f1;
$grey: #caced0;
$white: #feffff;

// Sidebar
$sidebar-width: 200px;

// Header
$header-index: 1000;

// Editor
$editor-width: 690px;

$sat-test-container-width : 770px;

$tooltip-editor-width: 486px;

//Tooltip

$tooltip-z-index: 1080;