.tip-selection {
  position: relative;
  margin-left: 10px;

  .current-size {
    border: 1px solid #caced0;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 41px;

    > p {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin: 0;
      height: 41px;
      &:hover {
        background-color: #e0dbdb;
      }
    }
  }

}
