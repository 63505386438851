@import './variables';

@mixin base-page() {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

@mixin base-content() {
    width: 100%;
    left: $sidebar-width;
    padding: 10px;

    &::-webkit-scrollbar {
        width: 0 !important;
    }
}

@mixin add-form() {
    padding: 10px;

    > div {
        display: flex;
        align-items: center;
        height: 100%;

        .select {
            height: 25px;
            margin-left: 50px;
        }

        > form {
            > label {
                display: block;
            }
            display: block;
        }
    }
}

@mixin sidebar() {
    height: 100vh;
    overflow-y: hidden;
    border-right: 1px solid $black;
    background-color: $dark-teal;
    display: flex;
    flex-direction: column;
    // min-width: $sidebar-width;
    width: $sidebar-width;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    .content {
        color: $black;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding: 25px;
        .links {
            list-style: none;
            padding: 0;
            > li {
                margin-bottom: 10px;
                > a {
                    text-decoration: none;
                    color: #17252A;
                    &:hover {
                        color: $light-teal;
                    }

                    &.active {
                        color: white;
                    }
                }
            }
        }

        .account {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $white;
        }
    }
}
