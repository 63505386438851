.login-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form {
        display: flex;
        flex-direction: column;
        width: 350px;

        > button {
            margin: 10px 0;
        }
    }
}
