@import '../../../scss/variables';

.sat-item {
    .hide-menu {
        cursor: pointer;
    }

    .options {
        display: flex;
        margin-left: 10px;

        .svg-container {
            display: flex;

            > svg {
                width: 30px;
                height: 30px;
                cursor: pointer;

                path {
                    fill: grey;
                }

                &:hover {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    .questions {
        > div > ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            text-align: center;
            > li.question {
                width: 30px;
                padding: 10px 0;
                margin: 10px 0;
                outline: 1px solid black;
                margin-left: 1px;

                &.active {
                    background-color: $teal;
                    color: $white;
                    font-weight: bold;

                    &:hover {
                        background-color: $teal;
                    }
                }

                &:hover {
                    cursor: pointer;
                    background-color: $light-teal;
                }
            }

        }
    }

}

.scoreConversionCls{
    width: 40%;
}

.cursorCls{
    cursor: pointer;
}

.satTypeTitleCls {
    font-size: 24px;
}

.sequenceProblemSetProgressSwitch {
    //display: flex;
    //margin: 0;
    & > div {
        .react-switch-handle {
            background-color: #f1f1f1 !important;
            box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12) !important;
        }
        .swtch_txt {
            align-items: center;
            color: #fff;
            display: inline-flex;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            height: 100%;
            justify-content: flex-start;
            line-height: 1.5;
            padding: 0 0 2px;
            width: 100%;
            &.checked {
                justify-content: flex-end;
            }
        }
    }
    & > div {
        padding: 0 90px 0 80px;
        // border: 1px solid red;
        .react-switch-bg {
            position: static !important;
            & > div {
                opacity: 1 !important;
                position: absolute !important;
                color: red !important;
                left: 0;
                right: auto !important;
                width: auto !important;

                & > .swtch_txt {
                    &.checked {
                        color: rgb(0, 199, 137);
                    }
                    &.unchecked {
                        color: rgb(240, 97, 80);
                    }
                }
                &:last-child {
                    left: auto;
                    right: 0 !important;
                }
            }
        }
    }

    label {
        color: #333;
        display: none;
        font-size: 14px;
        font-weight: 300 !important;
        line-height: 32px;
        margin: 0 0 0 10px !important;
    }
}
