@import "../../../scss/variables";

.tooltip-read-editor-container {
  //width: auto;
  min-width: 100px !important;
  max-width: $tooltip-editor-width !important;

  .DraftEditor-root {
    pointer-events: none;

    figure {
      margin: 5px 0;
    }
  }

  blockquote {
    text-align: center;
    padding: 0 0 0 40px;
    margin: 0;
  }
}

.tooltip-read-editor-container .DraftEditor-editorContainer > div > div > *:not(figure) {
  pointer-events: none;
}
