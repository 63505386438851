@import '../../../../scss/variables';

.admin-question {
    background-color: white;
    border: 2px solid #3aafa9;
    padding: 5px 10px;

    .top-section {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: space-between;
            // Match height of calc-image for consistency
            height: 35px;

            .left {
                display: flex;
                align-items: center;

                .calc-image {
                    width: 35px;
                }
            }
        }

        > div {
            margin: 5px 0;
        }

        .editor-section {
            display: flex;

            iframe {
                width: 628px;
                height: 353px;
            }

        }
        
    }

}
