.assignment_sec {
  border-radius: inherit !important;
  overflow: hidden;
}

.assignment_sec .assignmentTabs_wrap .tabs {
  border-bottom: 1px solid #AFAFAF;
  height: initial;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
}

.assignment_sec .assignmentTabs_wrap .tabs .indicator {
  background-color: #FD6600;
  border-radius: 50px;
  height: 5px;
}

.assignment_sec .assignmentTabs_wrap .tabs .tab {
  height: 84px;
  line-height: 84px;
  padding: 0 30px;
  width: auto;
  display: inline-flex;
  float: none;
  flex-grow: initial;
  position: static;
}

.assignment_sec .assignmentTabs_wrap .tabs .tab a {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

.assignment_sec .assignmentTabs_wrap .tabs .tab a:focus,
.assignment_sec .assignmentTabs_wrap .tabs .tab a:focus.active {
  background-color: rgba(253, 102, 0, 0.1);
}

.assignment_sec .assignmentTabs_wrap .tabs .tab a.active {
  color: #000000;
  font-weight: 600;
}

.assignment_sec .assignmentTabs_wrap .tabs_content {
  padding: 20px;
}
