@import '../../../scss/variables';

.home-topic {
    background-color: white;
    border: 2px solid #3aafa9;
    padding: 5px 10px;

    > p {
        margin: 0;
        cursor: pointer;
    }

    .options {
        display: flex;
        margin-left: 10px;

        .svg-container {
            display: flex;

            > svg {
                width: 30px;
                height: 30px;
                cursor: pointer;

                path {
                    fill: grey;
                }

                &:hover {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    .subtopic-form {
        display: flex;
        align-items: center;
        cursor: pointer;


        .svg-container {
            margin-right: 10px;
            > svg {
                width: 15px;
            }
        }
    }
}
