.edit-question {
    .attributes {
        display: flex;
        .react-switch-bg {
            > div {
                display: flex;
                align-items: center;
                .calc {
                    width: 35px;
                }
            }
        }
    }
}

.switchIconCls {
    color: #fff;
    margin: 5px 5px 5px 8px;
    font-size: 15px;
}
